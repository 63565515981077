<template>
  <div style="padding: 0px; margin: 0px">
    <div style="width: 100%; z-index: 0">
      <el-carousel :height="windowHeight">
        <el-carousel-item v-for="(item, key) in backImages" :key="key">
          <img :src="item.src" :alt="item.alt" style="height: 100%; width: 100%; object-fit: cover" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box">
      <el-form
        v-loading="loading"
        element-loading-text="正在登录"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        ref="loginForm"
        :model="loginForm"
        :rules="rules"
      >
        <h2 class="loginTitle">系统登录</h2>
        <div class="inputbox">
          <input type="text" v-model="loginForm.username" name="username" required />
          <label>用户名</label>
        </div>
        <div class="inputbox">
          <input type="password" v-model="loginForm.password" name="password" required />
          <label>密码</label>
        </div>
        <div class="inputbox">
          <input
            type="text"
            name="code"
            style="width: 245px; margin-right: 5px"
            v-model="loginForm.code"
            placeholder="点击图片更换验证码"
            required="请输入验证码"
            autocomplete="off"
            @keydown="
              (event) => {
                if (event.keyCode == 13) {
                  this.submitLogin()
                }
              }
            "
          />
          <img :src="captchaUrl" @click="updateCaptcha" style="margin-bottom: -15px" />
        </div>
        <!-- <el-checkbox v-model="checked" class="loginRemember">记住我 （监理用户名jianli 管理员用户名admin）</el-checkbox> -->
        <el-button type="primary" style="width: 100%" class="submit" @click="submitLogin">登 录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      backImages: [
        { src: '/img/login_back1.jpg', alt: '' },
        { src: '/img/login_back2.jpg', alt: '' },
        { src: '/img/login_back3.jpg', alt: '' },
      ],
      loading: false,
      captchaUrl: '/api/captcha?time=' + new Date(),
      loginForm: {
        username: 'beian',
        password: '123',
        code: '',
      },
      checked: true,
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      windowHeight: window.innerHeight + 'px',
    }
  },
  methods: {
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (
          this.loginForm.username.trim().length > 0 &&
          this.loginForm.password.trim().length > 0 &&
          this.loginForm.code.trim().length > 0
        ) {
          this.loading = true
          this.postRequest('/login', this.loginForm).then((resp) => {
            if (resp) {
              this.loading = false
              const tokenStr = resp.obj.tokenHead + resp.obj.token
              window.sessionStorage.setItem('tokenStr', tokenStr)
              //跳转
              let path = this.$route.query.redirect
              this.$router.replace(path == '/' || path == undefined ? '/home' : path)
            }
            this.loading = false
          })
        } else {
          this.$message.error('请输入所有字段')
          return false
        }
      })
    },
    updateCaptcha() {
      this.captchaUrl = '/api/captcha?time=' + new Date()
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.windowHeight = window.innerHeight + 'px'
      }, 100)
    })
  },
  created() {
    //auto home
  },
}
</script>

<style scoped>
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*实现块元素百分比下居中*/
  width: 450px;
  padding: 50px;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  z-index: 100;
}

.box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.box .inputbox {
  position: relative;
}

.box .inputbox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.box .inputbox label {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.box .inputbox input:focus ~ label,
.box .inputbox input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03a9f4;
  font-size: 14px;
}

.box .submit {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 7px;
  margin-top: 15px;
}
</style>
<style>
.box .el-checkbox__label {
  color: #03a9f4 !important;
  font-size: 14px;
}
</style>
