<template>
  <div class="document-icon" @click="$emit('click')">
    <el-image
      class="document-image"
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      style="width: 100%; height: 100%"
      :src="src"
      fit="fill"
    >
      <img :src="icon" slot="placeholder" class="el-image__placeholder" />
      <div slot="error" class="image-slot">
        <el-image src="/img/task.png" />
      </div>
    </el-image>
    <div class="document-label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'ImageItem',
  props: {
    label: {
      type: String,
      default: 'label',
    },
    icon: {
      type: String,
      default: '/img/task.png',
    },
    iconHover: {
      type: String,
      default: '/img/task.png',
    },
  },
  data() {
    return {
      src: this.icon,
    }
  },
  methods: {
    clickDocIcon() {
    },
    mouseenter() {
      setTimeout(() => {
        this.src = this.iconHover
      }, 100)
    },
    mouseleave() {
      setTimeout(() => {
        this.src = this.icon
      }, 100)
    },
  },
}
</script>

<style>
.document-icon {
  /* width: 50px;
  height: 50px; */
  text-align: center;
  font-size: 12px;
  display: inline-block;
  padding-bottom: 15px;
  cursor: pointer;
}
.document-image {
  max-width: 50px;
  max-height: 50px;
  padding: 0px 20px;
}
.document-label {
  white-space: nowrap;
}
</style>
