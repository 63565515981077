<template>
  <div ref="main" id="main"></div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import * as echarts from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

export default {
  name: 'DataContentStatistic',
  components: {},
  props: {
    statisticType: {
      type: String,
      default: 'times',
    },
    searchParam: {
      type: Object,
      default: () => ({
        type: 'times',
        target: 'dataClass',
      }),
    },
  },
  data() {
    return {
      count: 1,
      myChart: null,
      option: {
        title: {
          text: '{black|}{white|}数据分类（次）',
          // subtext: 'Data from Wikipedia',
          textStyle: {
            fontSize: 14,
            rich: {
              black: {
                width: 3,
                borderRadius: 5,
                backgroundColor: '#6D6D6D',
              },
              white: {
                width: 6,
                backgroundColor: '#FFF',
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          //   formatter: '{d}%',
          formatter: '{b} {c}次<br>占比{d}%',
        },
        // legend: {
        //   top: '5%',
        //   left: 'center',
        // },
        series: [
          {
            type: 'pie',
            radius: ['30%', '45%'],
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold',
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            data: [],
            label: {
              position: 'outer',
              alignTo: 'edges',
              overflow: 'break',
              fontSize: 10,
              formatter: '{value|{b} {c}}',
              rich: {
                value: {
                  color: 'inherit',
                },
              },
            },
          },
        ],
      },
    }
  },
  methods: {
    genChart() {
      if (this.myChart) {
        this.myChart.dispose()
      }
      let chartDom = this.$refs['main']
      this.myChart = echarts.init(chartDom)
      this.myChart.showLoading()
      this.searchParam.type = this.statisticType
      this.searchParam.target = 'dataClass'
      let requestData = JSON.parse(JSON.stringify(this.searchParam))
      this.postRequest('/server/data-issue/statistic', requestData)
        .then(async (res) => {
          if (res && res.obj) {
            this.option.series[0].data = []

            let result = await LocalConfig.loadDataClassesFromConfig(true)
            if (this.searchParam.dataClass.length == 0) {
              result.forEach((dataClass) => {
                this.option.series[0].data.push({ name: dataClass.name, value: 0 })
              })
            } else {
              this.searchParam.dataClass.forEach((id) => {
                result.some((dataClass, index) => {
                  if (dataClass.id === id) {
                    this.option.series[0].data.push({ name: dataClass.name, value: 0 })
                    return true
                  }
                })
              })
            }

            let dataSize = 0,
              dataMulti = 1,
              dataUnit = 'KB'
            if (this.statisticType == 'size') {
              res.obj.forEach((row) => {
                if (row.value && dataSize < Number(row.value)) {
                  dataSize = Number(row.value)
                  let result = LocalConfig.parseDataMulti(dataSize)
                  dataMulti = result[0]
                  dataUnit = result[1]
                }
              })
              this.option.title.text = '数据分类(' + dataUnit + ')'
            }

            res.obj.forEach((row) => {
              this.option.series[0].data.some((value, index) => {
                if (value.name === row.name) {
                  if (this.statisticType == 'size') {
                    this.option.series[0].data[index].value = (row.value / dataMulti).toFixed(2)
                  } else {
                    this.option.series[0].data[index].value = row.value
                  }
                  return true
                }
              })

              //   if (this.statisticType == 'size') {
              //     this.option.series[0].data.push({
              //       value: (row.value / dataMulti).toFixed(2),
              //       name: row.name,
              //     })
              //   } else {
              //     this.option.series[0].data.push({
              //       value: row.value,
              //       name: row.name,
              //     })
              //   }
            })
            this.myChart.hideLoading()
            this.option && this.myChart.setOption(this.option)
          }
        })
        .catch((err) => {
          console.log(err)
          this.myChart.hideLoading()
        })
    },
  },
  created() {
    echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout])
  },
  mounted() {
    this.genChart()
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.myChart.resize()
      }, 150)
    })
  },
}
</script>

<style></style>
