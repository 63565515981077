<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div>
        <el-input
          style="width: 300px; margin-right: 10px"
          prefix-icon="el-icon-search"
          v-model="searchValue"
          placeholder="输入字典类"
        >
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button aria-setsize="mini" icon="el-icon-plus" type="success" @click="showDialog">添加</el-button>
      </div>
      <div>
        <el-button type="danger" icon="el-icon-delete" @click="deleteBatch">批量删除</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center">
          <template slot-scope="scope">
            <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="字典类名称" prop="group" min-width="200" header-align="center"></el-table-column>
        <el-table-column label="字典项名称" prop="name" min-width="100" header-align="center"></el-table-column>
        <el-table-column label="字典项值" prop="value" min-width="130" header-align="center"></el-table-column>
        <el-table-column label="排序" prop="seq" min-width="50" align="center"></el-table-column>
        <el-table-column label="备注" prop="memo" header-align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="showUpdate(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-dialog :visible.sync="dialogVisible" :title="title" width="600px">
        <el-form ref="dictionary" :model="dictionary" :rules="rules" label-width="100px">
          <el-form-item label="字典类名称" prop="group">
            <el-select
              v-model="dictionary.group"
              clearable
              allow-create
              filterable
              placeholder="请选择或输入"
              style="width: 100%"
            >
              <el-option v-for="group in groups" :key="group" :label="group" :value="group" />
            </el-select>
            <!-- <el-input v-model="dictionary.group"></el-input> -->
          </el-form-item>
          <el-form-item label="字典项名称" prop="name">
            <el-input v-model="dictionary.name"></el-input>
          </el-form-item>
          <el-form-item label="字典项值" prop="value">
            <el-input v-model="dictionary.value"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="seq">
            <el-input v-model="dictionary.seq"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="memo">
            <el-input v-model="dictionary.memo"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer span-center">
          <el-button icon="el-icon-check" type="primary" @click="handleSubmit()">确定</el-button>
          <el-button icon="el-icon-close" @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import * as LocalConfig from '@/utils/base'
export default {
  name: 'Dictionary',
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      title: '添加',
      dictionary: {},
      dict: {
        id: '',
        name: '',
        value: '',
        group: '',
        memo: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入字典项名称', trigger: 'blur' },
          { max: 64, message: '字典项名称最大长度64个字符', trigger: 'blur' },
        ],
        value: [
          { required: true, message: '请输入字典项值', trigger: 'blur' },
          { max: 64, message: '字典项值最大长度64个字符', trigger: 'blur' },
        ],
        group: [
          { required: true, message: '请输入字典类名称', trigger: 'blur' },
          { max: 64, message: '字典类名最大长度64个字符', trigger: 'blur' },
        ],
      },
      searchValue: '',
      ids: [],
      groups: [],
    }
  },
  methods: {
    search() {
      this.currentPage = 1
      this.loadData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadData()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.loadData()
    },
    showDialog() {
      this.dialogVisible = true
      this.dictionary = JSON.parse(JSON.stringify(this.dict))
      this.title = '添加'
    },
    showUpdate(data) {
      this.dialogVisible = true
      this.dictionary = data
      this.title = '编辑'
    },
    handleDelete(data) {
      this.$confirm('此操作将删除字典项<' + data.name + '>, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/dictionary/' + data.id).then((resp) => {
            if (resp) {
              this.loadData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSubmit() {
      this.$refs.dictionary.validate((valid) => {
        if (valid) {
          if (this.title == '添加') {
            this.postRequest('/server/dictionary/', this.dictionary).then((resp) => {
              if (resp) {
                this.loadData()
                this.dialogVisible = false
              }
            })
          } else {
            this.putRequest('/server/dictionary/', this.dictionary).then((resp) => {
              this.loadData()
              this.dialogVisible = false
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    loadData() {
      this.getRequest(
        '/server/dictionary/?currentPage=' +
          this.currentPage +
          '&pageSize=' +
          this.pageSize +
          '&group=' +
          this.searchValue
      ).then((resp) => {
        if (resp) {
          this.total = resp.total
          this.tableData = resp.data
        }
      })
    },
    deleteBatch() {
      if (this.ids && this.ids.length == 0) {
        this.$message({ type: 'info', message: '首先选中要删除的数据项。' })
        return
      }
      //批量删除数据
      this.$confirm('此操作将删除选中数据行, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/dictionary/batch', this.ids).then((resp) => {
            if (resp) {
              this.loadData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSelectionChange(val) {
      this.ids = []
      val.forEach((item) => {
        this.ids.push(item.id)
      })
    },
  },
  mounted() {
    this.loadData()
  },
  created() {
    LocalConfig.loadDictionaryGroupsFromConfig().then((result) => (this.groups = result))
  },
}
</script>

<style scoped></style>
