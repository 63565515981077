<template>
  <div>
    <div style="display: flex">
      <el-button icon="el-icon-plus" size="mini" type="success" @click="showUserDialog">新增</el-button>
    </div>
    <div :style="{ overflow: 'auto', height: screenHeight, display: 'flex' }">
      <el-table ref="user" :data="tableData" stripe style="width: 100%; margin-top: 10px">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="username" label="登录名" width="120"> </el-table-column>
        <el-table-column prop="realname" label="用户名" width="120"> </el-table-column>
        <el-table-column prop="department.depname" label="隶属单位"> </el-table-column>
        <el-table-column prop="sysRole.roleName" label="角色" width="80"> </el-table-column>
        <el-table-column prop="active" label="启用">
          <template slot-scope="scope">
            <el-tag type="success" size="mini" v-if="scope.row.active">启用</el-tag>
            <el-tag type="info" size="mini" v-else>禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="resetPassword(scope.$index, scope.row)" icon="el-icon-refresh">
              重置密码
            </el-button>
            <el-button type="text" size="mini" @click="showEdit(scope.$index, scope.row)" icon="el-icon-edit">
              编辑
            </el-button>
            <el-button type="text" size="mini" @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--添加/编辑用户信息对话框-->
    <el-dialog title="创建账号" :visible.sync="dialogVisible" width="500px">
      <el-form label-width="80px" :rules="rules" ref="loginForm" :model="user">
        <el-form-item label="登录名" prop="username" size="mini" v-if="user.id == ''">
          <el-input v-model="user.username" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="realname" size="mini">
          <el-input v-model="user.realname" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="password" size="mini" v-if="user.id == ''">
          <el-input type="password" v-model="user.password" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="隶属单位" prop="affiliated" size="mini">
          <el-select v-model="user.depId" placeholder="请选择">
            <el-option v-for="item in options" :key="item.id" :label="item.depname" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="affiliated" size="mini">
          <el-select v-model="user.roleId" placeholder="请选择">
            <el-option v-for="item in roles" :key="item.id" :label="item.roleName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用" prop="active" size="mini">
          <el-switch style="display: block" v-model="user.active"> </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer span-center">
        <el-button type="primary" @click="handleSubmit" size="mini" icon="el-icon-check">确定</el-button>
        <el-button @click="dialogVisible = false" size="mini" icon="el-icon-close">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Users',
  data() {
    return {
      screenWidth: '',
      screenHeight: '',
      rules: {
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在5到20个字符', trigger: 'blur' },
        ],
        realname: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在1到20个字符', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入初始登录密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在6到20个字符', trigger: 'blur' },
        ],
      },
      tableData: [],
      dialogVisible: false,
      user: {},
      options: [],
      roles: [],
      u: {
        id: '',
        realname: '',
        username: '',
        password: '',
        depId: null,
        roleId: null,
        active: false,
      },
    }
  },
  mounted() {
    this.screenHeight = document.body.clientHeight
    this.screenWidth = document.body.clientWidth
    this.loadDepartment()
    this.loadRoles()
    this.loadAllUsers()
  },
  computed: {
    containerStyle() {
      return 'style="overflow-y: scroll;height: ' + this.screenHeight + 'px"'
    },
  },
  methods: {
    showEdit(index, data) {
      this.user = data
      this.dialogVisible = true
    },
    showUserDialog() {
      //重置对象
      this.user = JSON.parse(JSON.stringify(this.u))
      this.dialogVisible = true
    },
    resetPassword(index, data) {
      this.getRequest('/system/settings/users/resetPwd', { id: data.id })
        .then((resp) => {
          if (resp && resp.obj) {
            this.$alert('用户' + data.username + '的密码重置为:' + resp.obj, '重置密码', {
              confirmButtonText: '确定',
              callback: (action) => {
                // this.$message({
                //   type: 'info',
                //   message: `action: ${action}`,
                // })
              },
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSubmit() {
      //提交账户
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          if (this.user.id == '') {
            //添加
            this.postRequest('/system/settings/users/', this.user).then((resp) => {
              if (resp) {
                this.loadAllUsers()
              }
            })
          } else {
            this.putRequest('/system/settings/users/', this.user).then((resp) => {
              if (resp) {
                this.loadAllUsers()
              }
            })
          }
          this.dialogVisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleDelete(index, data) {
      this.$confirm('此操作将删除用户<' + data.username + '>, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/system/settings/users/' + data.id).then((resp) => {
            if (resp) {
            }
            this.loadAllUsers()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    loadDepartment() {
      this.getRequest('/system/basic/department/').then((resp) => {
        if (resp) {
          this.options = resp
        }
      })
    },
    loadRoles() {
      this.getRequest('/system/basic/role/').then((resp) => {
        if (resp) {
          this.roles = resp
        }
      })
    },
    loadAllUsers() {
      this.getRequest('/system/settings/users/').then((resp) => {
        if (resp) {
          this.tableData = resp
        }
      })
    },
  },
}
</script>

<style scoped></style>
