<template>
  <div>
    <div>
      <el-input
        class="addRoleInput"
        size="mini"
        placeholder="添加角色"
        suffix-icon="el-icon-plus"
        v-model="role.roleName"
        @keydown.enter.native="addRole"
      >
      </el-input>
      <el-button size="mini" icon="el-icon-plus" type="success" @click="addRole">添加</el-button>
    </div>
    <div class="dataTable">
      <el-table @selection-change="handleSelectionChange" :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" width="50"> </el-table-column>
        <!--        <el-table-column
            fixed
            prop="id"
            label="编号"
            width="300">
        </el-table-column>-->
        <el-table-column prop="roleName" label="角色"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="showEdit(scope.$index, scope.row)"> 编辑 </el-button>
            <el-button type="text" size="mini" @click="handleDelete(scope.$index, scope.row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      <el-button size="mini" :disabled="this.multipleSelection.length==0" style="margin-top: 8px" type="danger" @click="handleDeleteMany">
        批量删除
      </el-button>-->
    </div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="80px">
        <el-form-item label="角色" size="mini">
          <el-input v-model="roleUpdate.roleName" class="updateRole" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="是否只读" size="mini">
          <el-switch style="display: block" v-model="roleUpdate.readonly"> </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer span-center">
        <el-button type="primary" @click="doUpdate" size="mini">确定</el-button>
        <el-button @click="dialogVisible = false" size="mini">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'roles',
  data() {
    return {
      multipleSelection: [],
      role: {
        roleName: '',
        readonly: false,
      },
      roleUpdate: {
        roleName: '',
        readonly: false,
      },
      tableData: [],
      dialogVisible: false,
    }
  },
  mounted() {
    this.initRoles()
  },
  methods: {
    handleDeleteMany() {
      this.$confirm('此操作将删除<' + this.multipleSelection.length + '>条记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let ids = '?'
          this.multipleSelection.forEach((item) => {
            ids += 'ids=' + item.id + '&'
          })
          this.deleteRequest('/system/basic/role/' + ids).then((resp) => {
            if (resp) {
              this.initRoles()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    doUpdate() {
      this.putRequest('/system/basic/role/', this.roleUpdate).then((resp) => {
        if (resp) {
          //this.$message.success("修改成功");
          this.initRoles()
          this.dialogVisible = false
        }
      })
    },
    addRole() {
      if (this.role.roleName) {
        this.postRequest('/system/basic/role/', this.role).then((resp) => {
          if (resp) {
            this.initRoles()
            this.role.roleName = ''
          }
        })
      } else {
        this.$message.info('请输入角色名称')
      }
    },
    initRoles() {
      this.getRequest('/system/basic/role/').then((resp) => {
        if (resp) {
          this.tableData = resp
        }
      })
    },
    showEdit(index, data) {
      this.roleUpdate = data
      this.dialogVisible = true
    },
    handleDelete(index, data) {
      this.$confirm('此操作将删除角色<' + data.roleName + '>, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/system/basic/role/' + data.id).then((resp) => {
            if (resp) {
              this.initRoles()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>

<style scoped>
.addRoleInput {
  width: 300px;
  margin-right: 10px;
}

.dataTable {
  margin-top: 10px;
}

.updateRole {
  width: 200px;
  margin-left: 8px;
}
</style>
