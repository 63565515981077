import { render, staticRenderFns } from "./Watermarking.vue?vue&type=template&id=7fa5c2fb&scoped=true&"
import script from "./Watermarking.vue?vue&type=script&lang=js&"
export * from "./Watermarking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa5c2fb",
  null
  
)

export default component.exports