<template>
  <div>
    <div id="searchPane">
      <el-form :model="searchForm">
        <el-form-item label="备案编号">
          <el-col :span="3">
            <el-select
              v-model="searchForm.record"
              value-key="id"
              placeholder="请选择"
              style="width: 100%"
              @change="handleChange"
              filterable
            >
              <el-option v-for="record in records" :key="record.id" :label="record.recordNum" :value="record" />
            </el-select>
          </el-col>
          <el-button
            style="float: right"
            type="success"
            icon="el-icon-plus"
            @click="create()"
            :loading="createBtnLoading"
            v-if="!$store.state.readonly"
          >
            新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div id="TabForms">
      <TabForms
        :serviceId="serviceId"
        @mark-reload="loadRecentRecords"
        :recordNum="recordNum"
        ref="tabs"
        :docId="docId"
        :documents="documents"
        :stage="currentNode"
        :readonly="readonly"
      />
      <!-- 
        @notifyRecordNumUpdate="updateRecordNum"
        @notifyServiceIdUpdate="updateServiceId" -->
    </div>
  </div>
</template>

<script>
import TabForms from '@/components/business/TabForms.vue'
export default {
  name: 'SingleService',
  components: { TabForms },
  data() {
    return {
      searchForm: {},
      serviceId: '',
      recordNum: '',
      currentNode: 1,
      records: [],
      createBtnLoading: false,
      readonly: true,
    }
  },
  methods: {
    create() {
      this.createBtnLoading = true
      this.getRequest('/server/record/record-num')
        .then((num) => {
          let result = this.records.filter((item) => {
            return item.recordNum === num
          })

          this.currentNode = 1
          this.recordNum = num
          this.serviceId = null //force taskForm refresh
          this.searchForm.record = { id: null, recordNum: this.recordNum, currentNode: this.currentNode }
          //防止多次点击新增
          if (result.length == 0) {
            this.records.unshift({ id: null, recordNum: this.recordNum, currentNode: this.currentNode })
          }
          this.createBtnLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.createBtnLoading = false
        })
    },
    loadRecentRecords() {
      this.getRequest('/server/record/numbers/100')
        .then((res) => {
          if (res) {
            this.records = res
          }
        })
        .catch((err) => {
          console.log(err)
        })
      return []
    },
    handleChange(item) {
      if (!item.value) {
        this.serviceId = item.id
        this.recordNum = item.recordNum
        this.currentNode = parseInt(item.currentNode)
      }
    },
  },
  created() {
    this.readonly = this.$store.state.readonly
    this.loadRecentRecords()
  },
  computed: {
    documents() {
      return this.$store.state.single.documents
    },
    docId() {
      return this.$store.state.single.current
    },
  },
}
</script>

<style scoped>
#searchPane {
  padding: 0.5em 1em;
  background-color: white;
}
#TabForms {
  margin: 0.5em 0;
}
</style>
<style>
html,
body {
  background-color: #eaedf0;
}
#TabForms .el-tabs {
  padding: 1em;
}
</style>
