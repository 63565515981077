<template>
  <div>
    <el-dialog :visible.sync="editFormVisible" :title="serviceId ? '编辑信息' : '发布信息'" append-to-body width="50%">
      <el-form label-width="80px">
        <el-form-item label="选择模版">
          <el-col :span="6">
            <el-upload class="upload-demo" action="#" :limit="1">
              <el-button type="info"> 选取文件 </el-button>
            </el-upload>
          </el-col>
        </el-form-item>
        <el-form-item label="报告名称">
          <el-input v-model="form.bgmc" />
        </el-form-item>
        <el-form-item label="报告内容">
          <el-input rows="14" v-model="form.bgnr" type="textarea" />
        </el-form-item>
        <el-form-item class="btnline" label-width="0px">
          <el-button type="primary" @click="onSubmit"> 保存 </el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div id="searchPane">
      <el-form :model="searchForm">
        <el-form-item label="报告名称">
          <el-col :span="3">
            <el-input v-model="searchForm.name" />
          </el-col>
          <el-col :span="2">
            <span style="width: 10px; display: inline-block"></span>
            <el-button type="primary" icon="el-icon-search" @click="getData()">检索</el-button>
          </el-col>
          <!-- <el-button style="float: right" type="success" @click="create()"> 发布信息 </el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div id="tableArea">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column type="selection" align="center" />
        <el-table-column prop="xh" type="index" label="序号" align="center">
          <template slot-scope="scope">
            <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bgmc" label="报告名称" header-align="center" />
        <el-table-column prop="fbsj" label="发布时间" align="center" />
        <el-table-column prop="fbr" label="发布人" align="center" />
        <el-table-column label="操作" min-width="130px" align="center">
          <template #default="scope">
            <el-button type="text"> 导出报告 </el-button>
            <el-button type="text" @click="editIt(scope.$index, scope.row)"> 查看 </el-button>
            <!-- <el-button type="text" @click="deleteIt(scope.row.babh)"> 删除 </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Publish',
  components: {},
  data() {
    return {
      serviceId: '',
      tableData: [],
      searchForm: {},
      form: {},
      editFormVisible: false,
      page: 1,
      pageSize: 10,
      nxtPage: 1,
      total: 1,
      multipleSelection: [],
    }
  },
  methods: {
    Allbazts: () => {
      return [
        { value: '未提交', id: 'WTJ' },
        { value: '待审核', id: 'DSH' },
        { value: '未通过', id: 'WTG' },
        { value: '已通过', id: 'YTG' },
      ]
    },
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.postRequest('/login', this.loginForm).then((resp) => {
            if (resp) {
              this.loading = false
              const tokenStr = resp.obj.tokenHead + resp.obj.token
              window.sessionStorage.setItem('tokenStr', tokenStr)
              //跳转
              let path = this.$route.query.redirect
              this.$router.replace(path == '/' || path == undefined ? '/home' : path)
            }
          })
        } else {
          this.$message.error('请输入所有字段')
          return false
        }
      })
    },
    getData() {
      //结合searchForm
      this.tableData = [
        {
          id: '1',
          fbsj: '2022-05-23',
          bgmc: '关于A备案情况的报告',
          fbr: '李振',
        },
        {
          id: '3',
          fbsj: '2022-04-23',
          bgmc: '关于B备案情况的报告',
          fbr: '陈垣',
        },
        {
          id: '5',
          fbsj: '2022-03-23',
          bgmc: '关于C备案情况的报告',
          fbr: '李振',
        },
        {
          id: '7',
          fbsj: '2022-02-23',
          bgmc: '关于D备案情况的报告',
          fbr: '李振',
        },
        {
          id: '8',
          fbsj: '2022-01-23',
          bgmc: '关于E备案情况的报告',
          fbr: '李振',
        },
      ]
    },
    create() {
      this.editIt(0, null)
    },
    editIt(index, row) {
      this.editFormVisible = true
      this.serviceId = row ? row.id : ''
    },
    deleteIt(no) {},
    onSubmit() {
      //axios
    },
  },
  mounted() {
    this.getData()
    this.bazts = this.Allbazts()
  },
}
</script>

<style scoped>
html,
body {
  background-color: #eaedf0;
}
#searchPane {
  padding: 0.5em 1em;
  background-color: white;
}
#tableArea {
  margin: 0.5em 0em;
}
#tableFooter {
  text-align: center;
  background: white;
  margin: 0.5em 0em;
  padding: 0.5em;
}
.btnline {
  text-align: center;
}
</style>
