<template>
  <div>
    <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
      <div v-if="!$store.state.readonly">
        <el-button size="mini" type="success" @click="downloadTemplate"
          ><i class="fa fa-angle-double-down" aria-hidden="true"></i>导出模板
        </el-button>
        <input
          v-show="false"
          ref="fileRef"
          type="file"
          @change="
            (event) => {
              readExcelFile(event)
            }
          "
        />
        &nbsp;
        <el-button size="mini" type="success" @click="fireUpload">
          <i class="fa fa-angle-double-up" aria-hidden="true"></i>导入数据
        </el-button>
      </div>
    </div>
    <div style="display: flex; margin-bottom: 10px" v-show="statusTips">
      <span style="white-space: nowrap; font-size: 12px; margin-right: 20px">{{ statusTips }}</span>
      <el-progress v-if="percentShow" style="width: 100%" color="#2659c4" :percentage="percent"></el-progress>
    </div>
    <div id="tableArea">
      <el-table
        :data="tableData"
        style="width: 100%"
        :cell-style="cellStyle"
        :row-class-name="tableRowClassName"
        height="800"
      >
        <el-table-column fixed key="-1" prop="no" label="序号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column
          v-for="(col, index) in map4table.keys()"
          :key="index"
          :prop="map4table.get(col)"
          :label="col"
          show-overflow-tooltip
          :render-header="renderHeader"
        >
        </el-table-column>
      </el-table>
    </div>
    <div></div>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import * as LocalConfig from '@/utils/base'
import moment from 'moment'

export default {
  name: 'ImportService',
  data() {
    return {
      statusTips: null,
      percentShow: false,
      percent: 0,
      invalid: false,
      tableData: [],
      map: new Map([
        ['序号', 'no'],
        ['备案编号', 'recordNum'],
        ['使用单位名称', 'institutionName'],
        ['使用单位联系人', 'applyJobRp'],
        ['使用单位联系方式', 'applyJobRpTel'],
        ['任务内容', 'taskContent'],
        ['数据用途', 'dataUse'],
        ['协议书编号', 'agreementNum'],
        ['服务负责人', 'serviceManager'],
        ['任务书编号/函名', 'taskNum'],
        ['任务来源单位', 'taskSourceUnit'],
        ['任务接收时间', 'taskFrom'],
        ['收文编号年份', 'fileNumYear'],
        ['收文编号', 'fileNumEnd'],
        ['数据分类', 'dataClass'],
        ['数据年份', 'dataYear'],
        ['数据名称', 'dataName'],
        ['服务类型', 'serverType'],
        ['任务分类', 'taskType'],
        ['数据内容', 'dataContent'],
        ['数据范围', 'dataRange'],
        ['数据类型', 'dataType'],
        ['数据格式', 'dataFormat'],
        ['数据量', 'dataSizeAndUnit'],
        ['挂靠项目', 'projectName'],
        ['使用的原数据', 'originalData'],
        ['版本信息', 'version'],
        ['生产单位', 'produceUnit'],
        ['生产负责人', 'produceManager'],
        ['生产起止时间', 'produceDate'],
        ['使用单位地址', 'address'],
        ['使用项目名称', 'project'],
        ['分发编号', 'issueNum'],
        ['分发时间', 'issueDate'],
        ['服务方式', 'serveType'],
        ['领取人', 'usingReceiver'],
        ['反馈编号', 'num'],
        ['反馈时间', 'feedbackDate'],
        ['使用方式', 'use'],
        ['数据使用说明', 'describes'],
      ]),
      map4table: new Map(),
      organizations: new Map(),
      applyJobRps: new Map(),
      serviceManagers: new Map(),
      taskSourceUnits: new Map(),

      serverTypes: new Map(),
      taskTypes: new Map(),
      dataClasses: new Map(),
      dataTypes: new Map(),
      dataFormats: new Map(),
      originalDataSet: new Map(),
      produceUnits: new Map(),
      //range
      range: new Map(),

      projects: new Map(),
      serveTypes: new Map(),
    }
  },
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (row.invalid && row.invalid.get(column.label)) {
        return 'color:red'
      }
      return ''
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.invalid) {
        return 'warning-row'
      }
      return ''
    },
    fireUpload() {
      this.tableData = []
      this.statusTips = null
      this.$refs['fileRef'].value = ''
      this.prepareMaps()
      setTimeout(() => {
        this.$refs['fileRef'].click()
      }, 500)
    },
    downloadTemplate() {
      window.location.href = '/template/record.xlsx'
    },
    readExcelFile(event) {
      this.invalid = false
      this.checked = false
      this.statusTips = '正在读取Excel文件...'
      let file = event.target.files ? event.target.files[0] : null

      readXlsxFile(file).then((rows) => {
        //申请rows.length个uuid。

        this.statusTips = '正在检查数据合法性...'
        this.percentShow = true
        this.tableData = [] //重置数据
        let rowHead = rows[0] //第一行是头
        try {
          let label = null
          let invalid = null
          let prop = null
          for (let i = 1; i < rows.length; i++) {
            let row = {}
            rowHead.forEach((head, colIndex) => {
              if (this.map.has(head)) {
                label = rows[i][colIndex]
                prop = this.map.get(head)
                row[prop] = label
                invalid = false
                break_here: switch (head) {
                  case '备案编号':
                    if (!label) {
                      invalid = true
                      row[prop] = '必填项目'
                    } else {
                      invalid = this.checkRecordNumInvalid(label)
                    }
                    break break_here
                  case '使用单位名称':
                    if (!label) {
                      invalid = true
                      row[prop] = '必填项目'
                    } else {
                      invalid = this.checkInvalid(row, label, 'institutionId', this.organizations)
                    }
                    break break_here
                  case '任务来源单位':
                    invalid = label ? this.checkInvalid(row, label, 'taskSourceUnitId', this.taskSourceUnits) : false
                    break break_here
                  case '服务负责人':
                    if (!label) {
                      invalid = true
                      row[prop] = '必填项目'
                    } else {
                      invalid = this.checkInvalid(row, label, 'serviceManagerId', this.serviceManagers)
                    }
                    break break_here
                  case '协议书编号':
                    invalid = this.checkAgreement(row, label)
                    break break_here
                  case '任务接收时间':
                  case '分发时间':
                    invalid = this.checkDate(row, prop, label)
                    break break_here
                  case '反馈时间':
                    invalid = this.checkDate(row, prop, label, true)
                    break break_here
                  case '任务内容':
                  case '领取人':
                  case '数据内容':
                  case '数据名称':
                    invalid = label ? false : true
                    if (invalid) {
                      row[prop] = '必填项目'
                    }
                    break break_here
                  case '数据量':
                    invalid = label ? false : true
                    if (invalid) {
                      row[prop] = '必填项目'
                    } else {
                      invalid =
                        label.length < 3 ||
                        ['KB', 'MB', 'GB', 'TB', 'PB', 'EB'].indexOf(label.substring(label.length - 2).toUpperCase()) <
                          0 ||
                        isNaN(Number(label.substring(0, label.length - 2)))
                    }
                    break break_here
                  case '生产起止时间':
                    if (!label) {
                      invalid = false
                    }
                    let [start, end] = label.split('-')
                    if (start && end) {
                      let ps = this.checkDate(row, 'produceStart', start, false)
                      let pd = this.checkDate(row, 'produceEnd', end, false)
                      invalid = ps && pd
                    } else {
                      invalid = true
                    }
                    break break_here
                  case '数据范围':
                    if (!label) {
                      invalid = true
                      row[prop] = '必填项目'
                    } else {
                      let areaStrs = label.trim().split(' ')
                      row['dataRanges'] = []
                      let code = null
                      areaStrs.some((str) => {
                        code = this.range.get(str)
                        if (code) {
                          row['dataRanges'].push(code)
                        } else {
                          invalid = true
                          return true
                        }
                      })
                    }
                    break break_here
                  case '服务类型':
                    if (!label) {
                      invalid = true
                      row[prop] = '必填项目'
                    } else {
                      invalid = this.checkInvalid(row, label, 'serverTypeId', this.serverTypes)
                    }
                    break break_here
                  case '任务分类':
                    if (!label) {
                      invalid = true
                      row[prop] = '必填项目'
                    } else {
                      invalid = this.checkInvalid(row, label, 'taskTypeId', this.taskTypes)
                    }
                    break break_here
                  case '数据分类':
                    if (!label) {
                      invalid = true
                      row[prop] = '必填项目'
                    } else {
                      invalid = this.checkInvalid(row, label, 'dataClassId', this.dataClasses)
                    }
                    break break_here
                  case '数据类型':
                    invalid = label ? this.checkInvalid(row, label, 'dataTypeId', this.dataTypes) : false
                    break break_here
                  case '数据格式':
                    invalid = label ? this.checkInvalid(row, label, 'dataFormatId', this.dataFormats) : false
                    break break_here
                  case '服务方式':
                    invalid = label ? this.checkInvalid(row, label, 'serveTypeId', this.serveTypes) : false
                    break break_here
                  case '数据年份':
                    invalid = label ? ('' + label).length != 4 || isNaN(parseInt(label)) : false
                    break break_here
                  case '使用的原数据':
                    invalid = this.checkInvalid(row, label, 'originalDataId', this.originalDataSet)
                    break break_here
                  case '使用单位联系人':
                    if (label && this.checkInvalid(row, label, 'applyJobRpId', this.applyJobRps)) {
                      //   row['applyJobRp'] = label
                    }
                    break break_here
                  case '使用项目名称':
                    if (label && this.checkInvalid(row, label, 'projectId', this.projects)) {
                      //   row['project'] = label
                    }
                    break break_here
                  case '生产单位':
                    if (label && this.checkInvalid(row, label, 'produceUnitId', this.produceUnits)) {
                      //   row['produceUnit'] = label
                    }
                    break break_here
                }
                console.log(invalid)
                if (invalid) {
                  this.invalid = true
                  if (!row.invalid) {
                    row.invalid = new Map()
                  }
                  row.invalid.set(head, head + '不合法')
                }
              }
            }) //end of forEach
            this.tableData.push(row)
            this.percent = (i * 100) / (rows.length - 1)
          } //end of for

          //TODO::确认所有必填项目存在
          this.checked = true
          if (this.invalid) {
            this.statusTips = '请检查所有红色标识项目！'
          } else {
            this.statusTips = '正在整理数据...'
            //处理
            /*

            根据任务书编号区分2021092701，

            分发信息
            分发编号，分发时间，服务方式、领取人、
            recordId、unitid和feedbackid
            produces[] （produceinstitutionId）、

            feedback 默认一个反馈已包含一个分发
            反馈编号、反馈时间、使用方式、使用说明
            recordId、unitId  单位的distributed

            数据
            生产编号、服务类型、任务分类、数据分类、数据年份、数据名称、数据内容、数据范围、
            数据类型、数据格式、数据量（size+unit）、元数据、版本信息、挂靠项目、
            生产单位、生产负责人、起止时间、使用方单位[]、

            任务
            服务负责人、任务来源单位、接受时间、函名-》是否有函    收文编号 ｜ 协议书编号 -》审批（OA）

            默认按照收文编号排序，同一个任务都在一起。
            根据任务书编号区分同一个任务，如2021092701，
            */
            let last = null
            let record = []
            let n
            this.tableData.forEach((row) => {
              if (last == null || row.taskNum != last.taskNum) {
                //新的任务
                //处理没有收文编号，但是有协议书编号的审批情况
                if (last) {
                  if (
                    last.institutions.filter((unit) => {
                      return unit.agreementNum
                    }).length > 0
                  ) {
                    task.taskApproval = '1'
                  }
                }
                n = 0

                let task = {}
                task.recordNum = row.recordNum
                task.serviceManager = row.serviceManagerId
                task.taskSourceUnit = row.taskSourceUnitId
                task.fileNum = row.fileNumYear ? row.fileNumYear : '' + '' + row.fileNumEnd ? row.fileNumEnd : ''
                task.fileNum = task.fileNum ? task.fileNum : null
                task.taskFrom = row.taskFromDate
                if (task.fileNum) {
                  task.taskApproval = '1'
                }
                task.taskNum = row.taskNum
                if (task.taskNum) {
                  task.taskHas = true
                }

                task.recordInstitutions = []
                // task.recordInstitutions.push({
                //   institutionId: row.institutionId,
                //   dataUse: row.dataUse ? row.dataUse : null,
                //   taskContent: row.taskContent ? row.taskContent : null,
                //   agreementNum: row.agreementNumMiddle
                //     ? row.agreementNumMiddle + '号' + row.agreementNumEnd
                //       ? row.agreementNumEnd
                //       : ''
                //     : null,
                //   applyJobRp: row.applyJobRpId ? row.applyJobRpId : row.applyJobRp ? row.applyJobRp : null,
                //   applyJobRpTel: row.applyJobRpTel ? row.applyJobRpTel : null,
                // })
                task.institutions = []
                // if (row.address) {
                //   task.institutions.push({
                //     id: row.institutionId,
                //     address: row.address,
                //   })
                // }

                last = JSON.parse(JSON.stringify(task))

                task.produceData = []
                task.distributes = []
                task.feedback = []
                record.push(task)
                last = task
              }
              //n++
              if (last) {
                this.proceed(last, row)
              }
            })

            //console.log(record)

            this.statusTips = '正在导入...'
            this.percentShow = false
            this.percent = 0
            //导入
            this.doImport(record)
            //this.statusTips='导入...'
          }
        } catch (err) {
          console.log(err)
          //   this.$message({
          //     type: 'error',
          //     message: '加载的数据模板不正确，模板应该包含：<' + rowHead.toString() + '>字段',
          //   })
        }
      })
    },
    proceed(task, row) {
      task.recordInstitutions.push({
        institutionId: row.institutionId,
        dataUse: row.dataUse ? row.dataUse : null,
        taskContent: row.taskContent ? row.taskContent : null,
        agreementNum: row.agreementNumMiddle
          ? row.agreementNumMiddle + '号' + (row.agreementNumEnd ? row.agreementNumEnd : '')
          : null,
        applyJobRp: row.applyJobRpId ? row.applyJobRpId : row.applyJobRp ? row.applyJobRp : null,
        applyJobRpTel: row.applyJobRpTel ? row.applyJobRpTel : null,
      })
      if (row.address) {
        task.institutions.push({
          id: row.institutionId,
          address: row.address,
        })
      }

      //约定一行一个data
      let d = {}
      d.id = ''
      //目前是一个分类
      if (row.dataClassId) {
        let dataClasses = []
        dataClasses.push(row.dataClassId)
        d.dataClass = JSON.stringify(dataClasses)
      }

      d.dataContent = row.dataContent ? row.dataContent : null
      if (row.dataFormatId) {
        let dataFormats = []
        dataFormats.push(row.dataFormatId)
        d.dataFormat = JSON.stringify(dataFormats)
      }

      d.dataName = row.dataName ? row.dataName : null
      let num = task.produceData.length + 1
      d.dataNum = row.recordNum + (num < 9 ? '0' + num : num)
      d.dataRangeText = row.dataRange ? row.dataRange : null
      d.dataRange = row.dataRanges ? JSON.stringify(row.dataRanges) : null
      //d.dataRange.push(row.dataRangeIds)
      //dataRange: "[[\"de4ad5a7d7e74f2fa1d04b1c51d99250\"]]"
      if (row.dataSizeAndUnit && row.dataSizeAndUnit.length > 2) {
        d.dataSize = row.dataSizeAndUnit.substring(0, row.dataSizeAndUnit.length - 2)
        d.dataSizeUnit = row.dataSizeAndUnit.substring(row.dataSizeAndUnit.length - 2)
      }
      if (row.dataTypeId) {
        let dataTypes = []
        dataTypes.push(row.dataTypeId)
        d.dataType = JSON.stringify(dataTypes)
      }
      d.dataYear = row.dataYear ? row.dataYear : null
      d.originalData = row.originalDataId ? row.originalDataId : null
      //   d.produceDate = []
      //   d.produceDate.push(row.produceStartDate)
      //   d.produceDate.push(row.produceEndDate)
      d.produceFromDate = row.produceStartDate
      d.produceToDate = row.produceEndDate
      d.produceManager = row.produceManager ? row.produceManager : null
      d.produceUnit = row.produceUnitId ? row.produceUnitId : row.produceUnit ? row.produceUnit : null
      d.project = row.projectName ? row.projectName : null
      d.recordId = task.id
      d.serverType = row.serverTypeId ? row.serverTypeId : null
      d.taskType = row.taskTypeId ? row.taskTypeId : null
      d.version = row.version ? row.version : null
      task.produceData.push(d)

      //
      let f = {}
      f.date = row.feedbackDateDate ? row.feedbackDateDate : null
      f.describes = row.describes ? row.describes : null
      f.num = row.num ? row.num : null
      //使用项目名称
      f.projectId = row.projectId ? row.projectId : row.project ? row.project : null
      f.recordId = task.id
      f.unitId = row.institutionId
      f.use = row.use ? row.use : null
      task.feedback.push(f)
      if (f.num) {
        task.isFeedback = true
      }

      ////
      let di = {}
      di.feedbackId = f.id
      di.id = ''
      di.issueDate = row.issueDateDate
      di.issueNum = row.issueNum ? row.issueNum : null
      //   di.produces = []
      //   di.produces.push('ab126876590e4bfa9872faa3e075c879') //?pi.id
      di.recordId = task.id
      // di.isRepeat=null
      // di.repeatReason=null
      di.serveType = row.serveTypeId ? row.serveTypeId : null
      di.usingReceiver = row.usingReceiver ? row.usingReceiver : null
      //di.usingReceiverTel=null
      di.usingUint = row.institutionId
      task.distributes.push(di)

      if (
        !task.lastDistributeDate ||
        task.lastDistributeDate.isBefore(
          moment(di.issueDate, ['YYYY.M.D', 'YYYY.MM.DD', 'YYYY-M-D', 'YYYY-MM-DD'], true)
        )
      ) {
        task.lastDistributeDate = moment(di.issueDate, ['YYYY.M.D', 'YYYY.MM.DD', 'YYYY-M-D', 'YYYY-MM-DD'], true)
        task.serveYear = task.lastDistributeDate.year()
        task.stage = 3
      }
    },
    doImport(record) {
      //TODO：：如数量巨大，可以考虑分批导入，但每次导入前必须重新更新数据防止之前不存在的枚举已经插入的情况。
      this.postRequest('/server/import/', record).then((resp) => {
        console.log(record)
        if (resp && !resp.obj) {
          this.statusTips = '导入完成'
        } else {
          this.statusTips = '导入失败'
          let fails = resp.obj
        }
      })
    },
    checkInvalid(row, label, propertyName, map) {
      let value = map.get(label)
      if (value) {
        row[propertyName] = value
        return false
      } else {
        return true
      }
    },
    checkAgreement(row, agreementNum) {
      if (!agreementNum) {
        return false
      }
      if (agreementNum.length > 4) {
        let [year, right] = agreementNum.split('（密）第')
        if (year.length != 4 || isNaN(parseInt(year))) {
          return true
        }
        if (right) {
          let [middle, end] = agreementNum.split('号')
          if (end != undefined) {
            row.agreementNumYear = year
            row.agreementNumMiddle = middle
            row.agreementNumEnd = end
            return false
          } else {
            return true
          }
        }
      }
      return true
    },
    checkRecordNumInvalid(label) {
      if (label.length != 9) {
        return true
      }
      if (label.indexOf('GL') != 0) {
        return true
      }
      let year = Number(label.substring(2, 6))
      if (isNaN(year) || (year < 2000 && year > 2080)) {
        return true
      }
      return isNaN(Number(label.substring(6, 9), 10))
    },
    checkDate(row, propertyName, value, allowBlank) {
      if (allowBlank && !value) {
        return false
      }
      if (!allowBlank && !value) {
        row[propertyName] = '必填项目'
        return true
      }
      let mt = moment(value, ['YYYY.M.D', 'YYYY.MM.DD', 'YYYY-M-D', 'YYYY-MM-DD'], true)
      if (mt.isValid()) {
        row[propertyName + 'Date'] = mt.format('YYYY-MM-DD')
      } else {
        return true
      }
    },
    loadData() {
      this.getRequest('/import-service').then((resp) => {
        if (resp) {
          this.tableData = resp.data
        }
      })
    },
    renderHeader(h, { column, $index }) {
      let span = document.createElement('span')
      // 设置表头名称
      span.innerText = column.label
      // 临时插入 document
      document.body.appendChild(span)
      // 重点：获取 span 最小宽度，设置当前列，注意这里加了 20，字段较多时还是有挤压，且渲染后的 div 内左右 padding 都是 10，所以 +20 。（可能还有边距/边框等值，需要根据实际情况加上）
      column.minWidth = span.getBoundingClientRect().width + 20
      // 移除 document 中临时的 span
      document.body.removeChild(span)
      return h('span', column.label)
    },
    loadDataRange() {
      this.getRequest('/server/region/codeAndName').then((resp) => {
        if (resp) {
          this.range = new Map()
          resp.forEach((m) => {
            this.range.set(m.name, m.id)
          })
        }
      })
    },
    prepareMaps() {
      LocalConfig.loadOrganizationsFromConfig(true).then((result) => {
        this.organizations = new Map()
        result.forEach((obj) => {
          this.organizations.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadDictionaryFromConfig('服务负责人', true).then((result) => {
        this.serviceManagers = new Map()
        result.forEach((obj) => {
          this.serviceManagers.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadApplyJobRpsFromConfig(true).then((result) => {
        this.applyJobRps = new Map()
        result.forEach((obj) => {
          this.applyJobRps.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadTaskSourceUnitsFromConfig(true).then((result) => {
        this.taskSourceUnits = new Map()
        result.forEach((obj) => {
          this.taskSourceUnits.set(obj.name, obj.id)
        })
      })
      //-- for data
      LocalConfig.loadServiceTypeFromConfig(true).then((result) => {
        this.serverTypes = new Map()
        result.forEach((obj) => {
          this.serverTypes.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadTaskTypeFromConfig(true).then((result) => {
        this.taskTypes = new Map()
        result.forEach((obj) => {
          this.taskTypes.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadDataClassesFromConfig(true).then((result) => {
        this.dataClasses = new Map()
        result.forEach((obj) => {
          this.dataClasses.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadDataTypesFromConfig(true).then((result) => {
        this.dataTypes = new Map()
        result.forEach((obj) => {
          this.dataTypes.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadDataFormatsFromConfig(true).then((result) => {
        this.dataFormats = new Map()
        result.forEach((obj) => {
          this.dataFormats.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadOriginalDataListFromConfig(true).then((result) => {
        this.originalDataSet = new Map()
        result.forEach((obj) => {
          this.originalDataSet.set(obj.dataName, obj.id)
        })
      })
      LocalConfig.loadProduceDepartmentsFromConfig(true).then((result) => {
        this.produceUnits = new Map()
        result.forEach((obj) => {
          this.produceUnits.set(obj.name, obj.id)
        })
      })
      //--for feedback and distribute
      LocalConfig.loadProjectsFromConfig(true).then((result) => {
        this.projects = new Map()
        result.forEach((obj) => {
          this.projects.set(obj.name, obj.id)
        })
      })
      LocalConfig.loadDictionaryFromConfig('服务方式', true).then((result) => {
        this.serveTypes = new Map()
        result.forEach((obj) => {
          this.serveTypes.set(obj.name, obj.id)
        })
      })
      this.loadDataRange()
    },
  },
  created() {
    this.map4table = new Map(this.map)
    this.map4table.delete('序号')
    this.prepareMaps()
  },
}
</script>

<style scoped>
#tableArea td .cell {
  display: block; /* 需要 */
  white-space: nowrap; /* 过长部分不换行 */
  text-overflow: ellipsis; /* 过长部分展示省略号 */
  overflow: hidden; /* 隐藏过长部分 */
}
</style>
<style>
#tableArea .el-table th.el-table__cell > .cell {
  white-space: nowrap !important;
  width: auto !important;
}

.el-table .warning-row {
  background: oldlace;
}
</style>
