<template>
  <div style="min-width: '768'">
    <el-container class="nopadding">
      <el-container class="nopadding">
        <el-main class="nopadding" :style="{ height: cssHeight }"> <Overview></Overview> </el-main>
      </el-container>
      <el-aside
        id="doc-aside"
        :style="{ height: cssHeight, width: cssWidth }"
        style="padding-left: 7px; overflow: scroll"
        ><DocWall
      /></el-aside>
    </el-container>
  </div>
</template>

<script>
import Overview from '@/components/Overview.vue'
import DocWall from '@/components/DocWall.vue'

export default {
  name: 'index',
  components: {
    Overview,
    DocWall,
  },
  data() {
    return {
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,
    }
  },
  watch: {
    windowHeight(val) {
      //document.querySelector('#doc-aside').style.height = this.windowHeight - 65 + 'px'
    },
    windowWidth(val) {},
  },
  created() {},
  mounted() {
    window.addEventListener('resize', () => {
      this.windowHeight = document.documentElement.clientHeight
      this.windowWidth = document.documentElement.clientWidth
    })
  },
  computed: {
    cssHeight() {
      return this.windowHeight - 80 + 'px'
    },
    cssWidth() {
      return (this.windowWidth * 0.25 > 320 ? this.windowWidth * 0.25 : 320) + 'px'
    },
  },
}
</script>

<style scoped>
.nopadding {
  padding: 0px !important;
}
</style>
<style>
html,
body {
  background-color: #eaedf0;
}
</style>
