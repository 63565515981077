<template>
  <div id="allDiv" style="height: 100%">
    <el-card id="topDiv" class="box-card" style="height: 50%">
      <div slot="header">
        <i class="el-icon-arrow-right title">总体情况</i>
        <hr />
      </div>
      <el-carousel
        trigger="click"
        id="zmd"
        :height="carouselHeight"
        :autoplay="true"
        :loop="false"
        :interval="5000"
        arrow="hover"
        indicator-position="outside"
      >
        <el-carousel-item>
          <el-row>
            <el-col :span="12" style="padding-right: 10px">
              <div :style="{ height: carouselHeight, overflow: 'scroll' }">
                <!--        表-->
                <el-table
                  id="table1"
                  :data="staticsTable.month"
                  ref="month"
                  key="month"
                  :height="chartHeight - 12 + 'px'"
                >
                  <el-table-column prop="way" label="月份" align="center" min-width="50" fixed> </el-table-column>
                  <el-table-column label="服务次数" align="center">
                    <el-table-column prop="sum" label="合计" align="center" min-width="50" />
                    <el-table-column
                      align="center"
                      min-width="50"
                      v-for="task in taskTypes"
                      :key="task.id"
                      :prop="task.name"
                      :label="task.name"
                    />
                  </el-table-column>
                  <el-table-column label="数据量" prop="数据量" align="center" min-width="90" />
                </el-table>
              </div>
            </el-col>
            <el-col :span="12" style="padding-left: 10px">
              <!--        图-->
              <div
                ref="chartMonth"
                id="chartMonth"
                :style="{ width: chartWidth + 'px', height: chartHeight + 'px' }"
              ></div>
            </el-col>
          </el-row>
        </el-carousel-item>
        <el-carousel-item>
          <el-row>
            <el-col id="table2" :span="12" style="padding-right: 10px">
              <!--        表2-->
              <div :style="{ height: carouselHeight, overflow: 'scroll' }">
                <el-table :data="staticsTable.unit" ref="unit" key="unit" :height="chartHeight - 12 + 'px'">
                  <el-table-column
                    prop="way"
                    label="单位性质"
                    align="center"
                    min-width="90"
                    show-overflow-tooltip
                    fixed
                  >
                  </el-table-column>
                  <el-table-column label="服务次数" align="center">
                    <el-table-column prop="sum" label="合计" align="center" min-width="50" />
                    <el-table-column
                      align="center"
                      min-width="50"
                      v-for="task in taskTypes"
                      :key="task.id"
                      :prop="task.name"
                      :label="task.name"
                    />
                  </el-table-column>
                  <el-table-column label="数据量" prop="数据量" align="center" min-width="90" />
                </el-table>
              </div>
            </el-col>
            <el-col :span="12" style="padding-left: 10px">
              <!--        图2-->
              <div
                ref="chartUnit"
                id="chartUnit"
                :style="{ width: chartWidth + 'px', height: chartHeight + 'px' }"
              ></div>
            </el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
    </el-card>
    <el-card id="bottomDiv" class="box-card" style="margin-top: 10px">
      <i class="el-icon-arrow-right title">备案记录</i>
      <hr />
      <div id="tableArea" style="width: 100%" v-loading="loading" border>
        <el-table
          :data="recordData"
          border
          stripe
          :header-row-class-name="headerClass"
          :height="chartHeight - 10 + 'px'"
        >
          <el-table-column prop="recordNum" label="备案编号" align="center" width="100" />
          <el-table-column prop="usingUints" label="使用方单位" header-align="center" show-overflow-tooltip />
          <el-table-column prop="dataNames" label="数据名称" header-align="center" show-overflow-tooltip />
          <el-table-column prop="issueNums" label="服务编号" header-align="center" show-overflow-tooltip />
          <el-table-column prop="issueDates" label="数据分发时间" align="center" show-overflow-tooltip width="100" />
          <el-table-column
            prop="dataSizes"
            label="数据量"
            header-align="center"
            align="center"
            show-overflow-tooltip
            width="90"
          />
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts' //npm install echarts@5.3.3
import * as LocalConfig from '@/utils/base'

export default {
  name: 'Overview',
  props: {},
  data() {
    return {
      loading: false,
      headerClass: 'headerClass',
      chartWidth: null,
      chartHeight: null,
      tableHeight: null,
      carouselHeight: null,
      classValue: '',
      staticsTable: {
        month: [],
        unit: [],
        monthLoading: true,
        unitLoading: true,
      },
      recordData: [],
      taskTypes: [],
      chartMonth: null,
      chartUnit: null,
      chartOption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          left: 15,
          right: 15,
          padding: [5, 0],
          itemGap: 5,
          data: [], //
        },
        grid: {
          top: 80,
          left: 25,
          right: 25,
          bottom: 0,
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: [], //
            axisLabel: {
              interval: 0,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '服务次数',
            minInterval: 1,
            axisLabel: {
              formatter: '{value} ',
            },
          },
          {
            type: 'value',
            name: '数据量',
            axisLabel: {
              formatter: '{value} TB',
            },
          },
        ],
        series: [],
      },
      barSeries: {
        name: '',
        type: 'bar',
        stack: 'Ad',
        emphasis: {
          focus: 'series',
        },
        tooltip: {
          valueFormatter: function (value) {
            return value
          },
        },
        barWidth: 25,
        data: [],
      },
      lineSeries: {
        name: '数据量',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function (value) {
            return value
          },
        },
        data: [],
      },
    }
  },
  methods: {
    loadTable() {
      this.loading = true
      let requestData = JSON.parse(JSON.stringify({}))
      requestData.currentPage = 1
      requestData.pageSize = 15
      this.getRequest('/server/record/group', requestData)
        .then((res) => {
          if (res) {
            this.recordData = res.data
            this.recordData.forEach((row) => {
              row.dataSizes = LocalConfig.parseDataSize(Number(row.dataSizes))
            })
            this.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    parseMonth(month) {
      if (month.length < 2) {
        throw new Error('wrong month format')
      }
      if (month.substring(0, 1) == '0') {
        return month.substring(1) + '月'
      } else {
        return month + '月'
      }
    },
    loadStaticsTable(target, callback, chartName) {
      this.staticsTable[target + 'Loading'] = true
      this.postRequest('/server/data-issue/timesAndDatasizes', { target: target })
        .then(async (res) => {
          if (res.obj) {
            let xAxis = []
            let table = []
            let last = null

            let rows = res.obj
            let r = null
            if (target === 'month') {
              for (let i = 5; i >= 0; i--) {
                xAxis.push(moment(new Date()).subtract(i, 'months').format('M月'))
              }
            } else {
              let result = await LocalConfig.loadOrgClassesFromConfig(true)
              result.forEach((orgClass) => {
                xAxis.push(orgClass.name)
              })
            }
            xAxis.forEach((item) => {
              table.push({ way: item, sum: 0, datasizes: 0 })
            })

            rows.forEach((row) => {
              if (last == null || row.way != last) {
                if (r) {
                  //table.push(JSON.parse(JSON.stringify(r)))
                  table.some((value, index) => {
                    if (value.way === r.way) {
                      table[index] = JSON.parse(JSON.stringify(r))
                      return true
                    }
                  })
                }
                r = {}
                r.way = target == 'month' ? this.parseMonth(row.way.substring(2)) : row.way
                r[row.name] = row.times
                r.sum = row.times
                r.datasizes = row.datasizes //LocalConfig.parseDataSize(row.datasizes)
                r.数据量 = LocalConfig.parseDataSize(row.datasizes)
              } else {
                if (!r[row.name]) {
                  r[row.name] = row.times
                  r.datasizes = row.datasizes //LocalConfig.parseDataSize(row.datasizes)
                  r.数据量 = LocalConfig.parseDataSize(row.datasizes)
                } else {
                  r[row.name] += row.times
                  r.datasizes += row.datasizes //LocalConfig.parseDataSize(row.datasizes)
                  r.数据量 += LocalConfig.parseDataSize(row.datasizes)
                }
                r.sum += row.times
              }
              last = row.way
            })
            if (last != null && r) {
              //table.push(JSON.parse(JSON.stringify(r)))
              table.some((value, index) => {
                if (value.way === r.way) {
                  table[index] = JSON.parse(JSON.stringify(r))
                  return true
                }
              })
            }
            this.staticsTable[target] = table
            this.staticsTable[target + 'Loading'] = false

            let dataSize = 0,
              dataMulti = 1,
              dataUnit = 'KB'
            table.forEach((row) => {
              if (row.datasizes && dataSize < row.datasizes) {
                dataSize = row.datasizes
                let result = LocalConfig.parseDataMulti(dataSize)
                dataMulti = result[0]
                dataUnit = result[1]
              }
            })
            //this.tableHeight = document.querySelector('#table1').offsetHeight
            //当实际tableheight小于chart时候，处理 还是应该用tableheight对象内涵两个属性
            if (this.$refs[target]) {
              this.$refs[target].doLayout()
            }
            callback(table, chartName, dataMulti, dataUnit)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadCharts(table, chartName, dataMulti, dataUnit) {
      let option = JSON.parse(JSON.stringify(this.chartOption))
      if (chartName == 'chartUnit') {
        option.xAxis[0].axisLabel.rotate = -20
      }
      option.yAxis[1].axisLabel.formatter = '{value} ' + dataUnit
      this.taskTypes.forEach((task) => {
        option.legend.data.push(task.name)
        let series = JSON.parse(JSON.stringify(this.barSeries))
        series.name = task.name
        option.series.push(series)
      })
      option.legend.data.push('数据量')
      let series = JSON.parse(JSON.stringify(this.lineSeries))
      option.series.push(series)

      table.forEach((row) => {
        option.xAxis[0].data.push(row.way)
        let i = 0
        this.taskTypes.forEach((task) => {
          if (row[task.name]) {
            option.series[i].data.push(row[task.name])
          } else {
            option.series[i].data.push(0)
          }
          i++
        })
        option.series[i].data.push((row.datasizes / dataMulti).toFixed(2))
      })
      this[chartName] = echarts.init(this.$refs[chartName], 'macarons')
      option && this[chartName].setOption(option)
    },
    resize() {
      if (document.querySelector('#topDiv')) {
        this.chartHeight = document.querySelector('#topDiv').offsetHeight - 80
        this.chartWidth = document.querySelector('#topDiv').offsetWidth / 2 - 10
        this.carouselHeight = this.chartHeight + 'px'

        document.querySelector('.el-carousel__container').style.maxHeight = this.chartHeight
        document.querySelector('.el-carousel__container').style.height = this.chartHeight

        document.querySelector('#bottomDiv').style.height =
          document.querySelector('#allDiv').offsetHeight - document.querySelector('#topDiv').offsetHeight - 10 + 'px'

        if (this.chartMonth) {
          this.chartMonth.resize({ height: this.chartHeight, width: this.chartWidth })
        }
        if (this.chartUnit) {
          this.chartUnit.resize({ height: this.chartHeight, width: this.chartWidth })
        }
      }
    },
  },
  created() {
    this.loadTable()
  },
  mounted() {
    this.resize()
    let that = this
    window.addEventListener('resize', () => {
      setTimeout(() => {
        that.resize()
      }, 150)
    })
    LocalConfig.loadTaskTypeFromConfig(true).then((result) => {
      this.taskTypes = result
      this.loadStaticsTable('month', this.loadCharts, 'chartMonth')
      this.loadStaticsTable('unit', this.loadCharts, 'chartUnit')
    })
  },
}
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  /*  width: 480px;*/
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
hr {
  border: 0;
  border-bottom: 1px solid #ebeef5;
  padding: 0px 10px;
}
.title {
  font-size: 14px;
  font-weight: 700;
}
</style>
<style>
#topDiv .el-card__body {
  margin-top: -15px;
  padding-top: 0px;
}
#tableArea td .cell {
  display: block; /* 需要 */
  white-space: nowrap; /* 过长部分不换行 */
  text-overflow: ellipsis; /* 过长部分展示省略号 */
  overflow: hidden; /* 隐藏过长部分 */
}
#topDiv .el-table--mini .el-table__cell {
  padding: 4px 0;
}
#topDiv .el-card__header {
  border-bottom: 0px;
}
#zmd {
  width: 100%;
}

#tableArea .el-table__header,
.el-table__body,
.el-table__footer {
  width: 100%;
  table-layout: fixed !important;
}
#tableArea .headerClass th .cell,
#tableArea .headerClass th {
  background: #f5f7fa;
}
#zmd ul.el-carousel__indicators--outside {
  margin-top: -5px;
}
#zmd ul.el-carousel__indicators--outside button {
  background-color: blue;
}
::-webkit-scrollbar {
  display: none;
}
</style>
