<template>
  <div>
    <div
      style="display: flex; justify-content: space-between; margin: 0px 10px; padding: 10px; background-color: white"
    >
      <div>
        <el-input
          class="queryCommon"
          size="mini"
          placeholder="数据分类/名称/内容/年份/来源/类型"
          suffix-icon="el-icon-plus"
          v-model="searchKey"
          @keydown.enter.native="search"
        >
        </el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="mini" icon="el-icon-plus" type="success" @click="showDialog" v-if="!$store.state.readonly"
          >添加</el-button
        >
      </div>
      <div v-if="!$store.state.readonly">
        <el-button size="mini" type="success" @click="downloadTemplate"
          ><i class="fa fa-angle-double-down" aria-hidden="true"></i>导出模板
        </el-button>
        <el-button size="mini" type="success" @click="dialogReadFileVisible = true">
          <i class="fa fa-angle-double-up" aria-hidden="true"></i>导入数据
        </el-button>
        <el-button type="danger" icon="el-icon-delete" @click="deleteBatch">批量删除</el-button>
      </div>
    </div>
    <div id="tableArea">
      <el-table
        :data="tableData"
        stripe
        @selection-change="handleSelectionChange"
        style="width: 100%; margin-top: 10px"
      >
        <el-table-column type="selection" width="50" align="center"> </el-table-column>
        <el-table-column label="序号" type="index" width="50">
          <template slot-scope="scope">
            <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="一级分类" prop="firstLevel" width="100"></el-table-column>
        <el-table-column label="二级分类" prop="secondLevel" width="100"></el-table-column>
        <el-table-column label="三级分类" prop="thirdLevel" width="100"></el-table-column>
        <el-table-column label="数据分类" prop="classification" width="180"></el-table-column>
        <el-table-column label="数据名称" prop="dataName" width="260"></el-table-column>
        <el-table-column label="数据内容" prop="dataContent" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="数据年份" prop="dataYear" width="80"></el-table-column>
        <el-table-column label="数据来源" prop="dataOrigin" width="100"></el-table-column>
        <el-table-column label="数据类型" prop="dataFormat" width="80"></el-table-column>
        <el-table-column label="数据量" prop="dataVolume" width="80"></el-table-column>
        <el-table-column label="存放位置" prop="storageLocation" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" v-if="!$store.state.readonly">
          <template slot-scope="scope">
            <el-button type="text" @click="showUpdate(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="justify-content: space-between; width: 100%">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div>
      <!-- 添加数据资源目录数据-->
      <el-dialog :visible.sync="dialogVisible" title="添加" width="600px" @close="handleClose">
        <el-form ref="dataDirectory" :model="dataDirectory" :rules="rules" label-width="80px">
          <el-form-item label="一级分类" prop="firstLevel">
            <el-input v-model="dataDirectory.firstLevel"></el-input>
          </el-form-item>
          <el-form-item label="二级分类" prop="secondLevel">
            <el-input v-model="dataDirectory.secondLevel"></el-input>
          </el-form-item>
          <el-form-item label="三级分类" prop="thirdLevel">
            <el-input v-model="dataDirectory.thirdLevel"></el-input>
          </el-form-item>
          <el-form-item label="数据分类" prop="classification">
            <el-input v-model="dataDirectory.classification"></el-input>
          </el-form-item>
          <el-form-item label="数据名称" prop="dataName">
            <el-input v-model="dataDirectory.dataName"></el-input>
          </el-form-item>
          <el-form-item label="数据年份" prop="dataYear">
            <el-input v-model="dataDirectory.dataYear"></el-input>
          </el-form-item>
          <el-form-item label="数据来源" prop="dataOrigin">
            <el-input v-model="dataDirectory.dataOrigin"></el-input>
          </el-form-item>
          <el-form-item label="数据类型" prop="dataFormat">
            <el-input v-model="dataDirectory.dataFormat"></el-input>
          </el-form-item>
          <el-form-item label="数据量">
            <el-input v-model="dataDirectory.dataVolume"></el-input>
          </el-form-item>
          <el-form-item label="数据内容">
            <el-input v-model="dataDirectory.dataContent"></el-input>
          </el-form-item>
          <el-form-item label="存放位置">
            <el-input v-model="dataDirectory.storageLocation"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer span-center">
          <el-button icon="el-icon-check" type="primary" @click="handleSubmit('dataDirectory')">确定</el-button>
          <el-button icon="el-icon-close" @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
      <!--    数据导入对话框-->
      <el-dialog title="导入数据" :visible.sync="dialogReadFileVisible" width="45%">
        <input type="file" @change="readExcleFile" />
        <div style="width: 100%; height: 380px; overflow-y: auto">
          <el-table :data="readFileData" style="width: 100%">
            <el-table-column label="序号" type="index" width="50">
              <template slot-scope="scope">
                <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="一级分类" prop="firstLevel" width="200"></el-table-column>
            <el-table-column label="二级分类" prop="secondLevel" width="100"></el-table-column>
            <el-table-column label="三级分类" prop="thirdLevel" width="130"></el-table-column>
            <el-table-column label="数据分类" prop="classification" width="200"></el-table-column>
            <el-table-column label="数据名称" prop="dataName" width="230"></el-table-column>
            <el-table-column label="数据内容" prop="dataContent"></el-table-column>
            <el-table-column label="数据年份" prop="dataYear"></el-table-column>
            <el-table-column label="数据来源" prop="dataOrigin"></el-table-column>
            <el-table-column label="数据类型" prop="dataFormat"></el-table-column>
            <el-table-column label="数据量" prop="dataVolume"></el-table-column>
            <el-table-column label="存放位置" prop="storageLocation"></el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer span-center">
          <el-button type="primary" @click="saveDataBatch">确定</el-button>
          <el-button @click="dialogReadFileVisible = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'

export default {
  name: 'DataDirectory',
  data() {
    return {
      rules: {
        firstLevel: [{ required: true, message: '请输入一级分类', trigger: 'blur' }],
        secondLevel: [{ required: true, message: '请输入二级分类', trigger: 'blur' }],
        thirdLevel: [{ required: true, message: '请输入三级分类', trigger: 'blur' }],
        classification: [{ required: true, message: '请输入数据分类', trigger: 'blur' }],
        dataName: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
        dataYear: [
          { required: true, message: '请输入数据年份', trigger: 'blur' },
          { min: 4, max: 4, message: '长度在为 4 的数字', trigger: 'blur' },
        ],
        dataOrigin: [{ required: true, message: '请输入数据源', trigger: 'blur' }],
        dataFormat: [{ required: true, message: '请输入数据格式', trigger: 'blur' }],
      },
      ids: [], //用户选中的行
      dialogVisible: false,
      dialogReadFileVisible: false,
      searchKey: '',
      readFileData: [],
      tableData: [],
      page: 1,
      pageSize: 10,
      total: 100,
      dataDirectory: {
        id: '',
        firstLevel: '',
        secondLevel: '',
        thirdLevel: '',
        classification: '',
        dataName: '',
        dataContent: '',
        dataYear: '',
        dataOrigin: '',
        dataFormat: '',
        dataVolume: '',
        storageLocation: '',
        memo: '',
      },
    }
  },
  methods: {
    search() {
      //查找
      this.getRequest('/server/data-directory/?page=' + this.page + '&size=' + this.pageSize, {
        firstLevel: this.searchKey,
        secondLevel: this.searchKey,
        thirdLevel: this.searchKey,
        classification: this.searchKey,
        dataName: this.searchKey,
        dataContent: this.searchKey,
        dataYear: this.searchKey,
        dataOrigin: this.searchKey,
        dataFormat: this.searchKey,
      }).then((resp) => {
        if (resp) {
          this.tableData = resp.data
          this.total = resp.total
        }
      })
    },
    deleteBatch() {
      if (this.ids && this.ids.length == 0) {
        this.$message({ type: 'info', message: '首先选中要删除的数据项。' })
        return
      }
      //批量删除数据
      this.$confirm('此操作将删除选中数据行, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/data-directory/batch', this.ids).then((resp) => {
            if (resp) {
              this.loadDataDirectory()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSelectionChange(val) {
      this.ids = []
      val.forEach((item) => {
        this.ids.push(item.id)
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.loadDataDirectory()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.loadDataDirectory()
    },
    downloadTemplate() {
      window.location.href = '/template/dataDirectory.xlsx'
    },
    readExcleFile(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null
      readXlsxFile(xlsxfile).then((rows) => {
        this.readFileData = [] //重置数据
        let rowHead = rows[0] //第一行是头
        try {
          for (let i = 1; i < rows.length; i++) {
            let row = {}
            rowHead.forEach((head) => {
              let colIndex = rowHead.findIndex((colName) => {
                return head === colName
              })
              switch (head) {
                case '一级分类':
                  row.firstLevel = rows[i][colIndex]
                  break
                case '二级分类':
                  row.secondLevel = rows[i][colIndex]
                  break
                case '三级分类':
                  row.thirdLevel = rows[i][colIndex]
                  break
                case '数据分类':
                  row.classification = rows[i][colIndex]
                  break
                case '数据名称':
                  row.dataName = rows[i][colIndex]
                  break
                case '数据内容':
                  row.dataContent = rows[i][colIndex]
                  break
                case '数据年份':
                  row.dataYear = rows[i][colIndex]
                  break
                case '数据来源':
                  row.dataOrigin = rows[i][colIndex]
                  break
                case '数据类型（格式）':
                  row.dataFormat = rows[i][colIndex]
                  break
                case '数据量':
                  row.dataVolume = rows[i][colIndex]
                  break
                case '存放位置':
                  row.storageLocation = rows[i][colIndex]
                  break
              }
            })
            this.readFileData.push(row)
          }
        } catch (err) {
          this.$message({
            type: 'error',
            message: '加载的数据模板不正确，模板应该包含：<' + rowHead.toString() + '>字段',
          })
        }
      })
    },
    saveDataBatch() {
      //向数据库推送数据
      this.postRequest('/server/data-directory/batch/', this.readFileData).then((resp) => {
        if (resp) {
          this.loadDataDirectory()
          this.dialogReadFileVisible = false
        }
      })
    }, //批量保存数据
    handleDelete(data) {
      this.$confirm('此操作将删除目录<' + data.dataName + '>, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/data-directory/' + data.id).then((resp) => {
            if (resp) {
              this.loadDataDirectory()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleClose() {
      //重置对象
      Object.assign(this.$data.dataDirectory, this.$options.data().dataDirectory)
    },
    showUpdate(data) {
      this.dataDirectory = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
    },
    loadDataDirectory() {
      this.getRequest('/server/data-directory/?page=' + this.page + '&size=' + this.pageSize, this.dataDirectory).then(
        (resp) => {
          if (resp) {
            this.tableData = resp.data
            this.total = resp.total
          }
        }
      )
    },
    showDialog() {
      this.dialogVisible = true
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dataDirectory.id == '') {
            this.postRequest('/server/data-directory/', this.dataDirectory).then((resp) => {
              if (resp) {
                this.loadDataDirectory()
              }
            })
          } else {
            this.putRequest('/server/data-directory/', this.dataDirectory).then((resp) => {
              if (resp) {
                this.loadDataDirectory()
              }
            })
          }
          this.dialogVisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  mounted() {
    this.loadDataDirectory()
  },
}
</script>

<style scoped>
.queryCommon {
  width: 300px;
  margin-right: 10px;
}
#tableArea td .cell {
  display: block; /* 需要 */
  white-space: nowrap; /* 过长部分不换行 */
  text-overflow: ellipsis; /* 过长部分展示省略号 */
  overflow: hidden; /* 隐藏过长部分 */
}
</style>
