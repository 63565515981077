<template>
  <div :style="containerStyle">
    <div id="editDiv" v-if="editFormVisible">
      <el-dialog :visible.sync="editFormVisible" append-to-body width="70%" :title="editForm.id ? '编辑' : '新建'">
        <el-form :model="editForm" size="mini" :label-width="formLabelWidth">
          <el-row>
            <el-col :span="6">
              <el-form-item label="数据名称">
                <el-input v-model="editForm.name" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="数据量">
                <el-input placeholder="请输入内容" v-model="editForm.capacity" class="input-with-select">
                  <el-select style="width: 80px" v-model="editForm.capacityUnit" slot="append" placeholder="请选择">
                    <el-option v-for="item in volumeUnit" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="数据年份">
                <el-date-picker
                  v-model="editForm.year"
                  type="year"
                  placeholder="请选择年份"
                  style="width: 100%"
                  format="yyyy"
                  value-format="yyyy"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="数据来源">
                <el-select v-model="editForm.source" placeholder="请选择">
                  <el-option v-for="item in dataSource" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="接收人">
                <el-input v-model="editForm.receivedPerson" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="接收时间">
                <el-date-picker
                  v-model="editForm.receivedDate"
                  type="date"
                  placeholder="请选择时间"
                  style="width: 100%"
                  format="yyyy年M月d日"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="接收方式">
                <el-select v-model="editForm.receivedType" placeholder="请选择">
                  <el-option v-for="item in receiveType" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="数据内容">
                <el-input v-model="editForm.content" autocomplete="off" type="textarea" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="数据用途">
                <el-select v-model="editForm.application" placeholder="请选择">
                  <el-option v-for="item in dataUsing" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6"></el-col>
          </el-row>
        </el-form>
        <template #footer>
          <!-- <span class="dialog-footer span-center"> -->
          <span class="span-center">
            <el-button type="primary" @click="save()">保存</el-button>
            <el-button @click="editFormVisible = false">返回</el-button>
          </span>
          <!-- </span> -->
        </template>
      </el-dialog>
    </div>
    <div id="listDiv">
      <div
        style="display: flex; justify-content: space-between; margin: 0px 10px; padding: 10px; background-color: white"
      >
        <div>
          <el-input
            class="queryCommon"
            size="mini"
            placeholder="数据名称/内容/年份"
            suffix-icon="el-icon-search"
            style="width: 280px"
            v-model="searchKey"
            @keydown.enter.native="search"
          >
          </el-input>
          <el-button size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          <el-button size="mini" icon="el-icon-plus" type="success" @click="create" v-if="!$store.state.readonly"
            >添加</el-button
          >
        </div>
        <div>
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteBatch"
            style="margin-right: 10px"
            v-if="!$store.state.readonly"
            >批量删除</el-button
          >
        </div>
      </div>
      <div id="tableArea">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
          v-loading="loading"
          border
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="xh" type="index" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column prop="type" label="数据分类" show-overflow-tooltip />-->
          <el-table-column prop="name" label="数据名称" show-overflow-tooltip />
          <el-table-column prop="content" label="数据内容" show-overflow-tooltip />
          <el-table-column prop="capacity" label="数据量" show-overflow-tooltip />
          <el-table-column prop="year" label="数据年份" show-overflow-tooltip />
          <!--          <el-table-column prop="path" label="数据存储位置" show-overflow-tooltip />-->
          <el-table-column prop="source" label="数据来源" show-overflow-tooltip />
          <el-table-column prop="application" label="数据用途" show-overflow-tooltip />
          <el-table-column prop="receivedPerson" label="接收人" show-overflow-tooltip />
          <el-table-column prop="receivedDate" label="接收时间" show-overflow-tooltip />
          <el-table-column prop="receivedType" label="接收方式" show-overflow-tooltip />
          <el-table-column label="操作" min-width="60px" align="center" v-if="!$store.state.readonly">
            <template #default="scope">
              <el-button type="text" @click="deleteIt(scope.row)"> 删除</el-button>
              <el-button type="text" @click="editIt(scope.row)"> 编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'

export default {
  name: 'ReceivedData',
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      searchKey: '', //关键词全文搜索
      ids: [], //用于批量删除
      formLabelWidth: '80px',
      volumeUnit: ['PB', 'TB', 'GB', 'MB', 'KB'],
      dataSource: ['司局提供', '地方汇交', '购买'],
      receiveType: ['硬盘拷贝', '机要邮寄', '在线下载'],
      dataUsing: ['汇总统计', '日常工作'],
      tableData: [],
      abc: '',
      searchForm: {},
      editForm: {},
      editFormVisible: false,
      page: 1,
      pageSize: 10,
      nxtPage: 1,
      total: 1,
      multipleSelection: [],
      screenHeight: '',
    }
  },
  methods: {
    search() {
      //全文搜索方法
      this.loadTable()
    },
    deleteBatch() {
      //批量删除
      if (this.ids && this.ids.length == 0) {
        this.$message({ type: 'info', message: '首先选中要删除的数据项。' })
        return
      }
      //批量删除数据
      this.$confirm('此操作将删除选中数据行, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/received-data/batch', this.ids).then((resp) => {
            if (resp) {
              this.loadTable()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSelectionChange(val) {
      //处理批量选择删除
      this.ids = []
      val.forEach((item) => {
        this.ids.push(item.id)
      })
      //删除
    },
    create() {
      this.editIt(null)
    },
    editIt(row) {
      this.editForm = row ? row : {}
      this.editFormVisible = true
    },
    deleteIt(data) {
      this.$confirm('此操作将删除存储介质, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/received-data/' + data.id).then((res) => {
            if (res) {
              this.loadTable()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    save() {
      let requestData = JSON.parse(JSON.stringify(this.editForm))
      let callback = () => {
        this.loadTable()
        this.editFormVisible = false
      }
      let request = this.editForm.id ? this.putRequest : this.postRequest
      request('/server/received-data/', requestData)
        .then((res) => {
          if (res && res.code === 200) {
            callback()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleCurrentChange(val) {
      this.nxtPage = val
      this.loadTable()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.loadTable()
    },
    loadTable() {
      this.loading = true
      //let requestData = JSON.parse(JSON.stringify(this.searchForm))  //modify by ww
      this.getRequest('/server/received-data/page2', {
        name: this.searchKey,
        content: this.searchKey,
        year: this.searchKey,
      })
        .then((res) => {
          if (res) {
            this.tableData = res.data
            this.loading = false
            this.page = this.nxtPage
            this.total = res.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.loadTable()
  },
  mounted() {
    this.loadTable()
  },
  watch: {},
  updated() {},
  computed: {
    containerStyle() {
      return { height: document.body.clientHeight + 'px' }
    },
  },
}
</script>
<style scoped>
#tableArea td .cell {
  display: block; /* 需要 */
  white-space: nowrap; /* 过长部分不换行 */
  text-overflow: ellipsis; /* 过长部分展示省略号 */
  overflow: hidden; /* 隐藏过长部分 */
}

.el-select .el-input {
  width: 50px;
}

.input-with-select .el-input-group__append {
  background-color: #fff;
}
.queryCommon {
  width: 300px;
  margin-right: 10px;
}
</style>
