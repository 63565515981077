<template>
  <div :style="containerStyle" id="sysData">
    <el-tabs v-model="activeName" style="background-color: white">
      <el-tab-pane label="接收数据" name="receivedData" :lazy="true">
        <ReceivedData></ReceivedData>
      </el-tab-pane>
      <el-tab-pane label="数据资源目录" name="resource" :lazy="true">
        <data-directory></data-directory>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ReceivedData from '@/components/business/ReceivedData.vue'
import DataDirectory from '@/components/business/DataDirectory'
export default {
  name: 'Data',
  data() {
    return {
      activeName: 'receivedData',
    }
  },
  computed: {
    containerStyle() {
      return { height: document.body.clientHeight + 'px' }
    },
  },
  components: {
    DataDirectory,
    ReceivedData,
  },
}
</script>

<style scoped></style>
<style>
#sysData .el-form-item__label {
  font-size: 12px;
}
#sysData .el-tabs__header {
  margin-left: 15px;
}
</style>
