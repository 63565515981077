<template>
  <div id="summaryRoot" style="background-color: #fff; padding: 15px 35px" ref="summaryRoot">
    <el-row :class="'liner-row-' + color">
      <el-col :span="9" class="scopeTitle">
        <span :class="'logo-' + color">
          <span class="innerSpan">{{ staticsScopeLogo }}</span>
        </span>
        <span class="staticsScope">{{ staticsScope }}</span>
      </el-col>
      <el-col :span="5">
        <div style="margin: 10px auto">
          <el-statistic group-separator="," :value="serviceTimes" title="服务次数">
            <template slot="suffix">
              <span>次</span>
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="5">
        <div style="margin: 10px auto">
          <el-statistic group-separator="," :precision="2" decimal-separator="." :value="dataSize" title="数据总量">
            <template slot="suffix">
              <span> {{ dataSizeUnit }}</span>
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="5">
        <div style="margin: 10px auto">
          <el-statistic :value="useUnits" title="服务单位">
            <template slot="suffix">
              <span>个</span>
            </template>
          </el-statistic>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
export default {
  name: 'Summary',
  components: {},
  props: {
    period: {
      type: String,
      default: 'year',
    },
    color: {
      type: String,
      default: 'orange',
    },
  },
  data() {
    return {
      staticsScope: this.period == 'year' ? '本年度' : this.period == 'season' ? '本季度' : '本月度',
      staticsScopeLogo: this.period == 'year' ? '年' : this.period == 'season' ? '季' : '月',
      serviceTimes: 0,
      dataSize: 0,
      dataSizeUnit: 'GB',
      useUnits: 0,
      rules: {},
    }
  },
  methods: {
    getServiceTimes() {
      this.postRequest('/server/data-issue/getServiceSummary', { period: this.period, target: 'times' })
        .then((res) => {
          if (res && res.obj && res.obj.value) {
            this.serviceTimes = res.obj.value
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getServiceDataSize() {
      this.postRequest('/server/data-issue/getServiceSummary', { period: this.period, target: 'size' })
        .then((res) => {
          if (res && res.obj && res.obj.value) {
            let result = LocalConfig.parseDataSize(Number(res.obj.value))
            if (result && result.length > 2) {
              let len = result.length
              this.dataSize = Number(result.substring(0, len - 2))
              this.dataSizeUnit = result.substring(len - 2)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getServiceUseUnitsCount() {
      this.postRequest('/server/data-issue/getServiceSummary', { period: this.period, target: 'useUnit' })
        .then((res) => {
          if (res && res.obj && res.obj.value) {
            this.useUnits = res.obj.value
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getServiceTimes()
    this.getServiceUseUnitsCount()
    this.getServiceDataSize()
  },
  mounted() {
    this.$refs.summaryRoot.querySelectorAll('.con').forEach((item) => {
      item.classList.add(this.color)
    })
  },
  watch: {},
}
</script>

<style scoped>
.staticsScope {
  margin: 20px auto;
  margin-left: 15px;
  font-weight: 600;
}
.liner-row-orange {
  background: linear-gradient(to right, #feebdc, #fffefc);
  border-radius: 10px;
  color: #ff8c2c;
}
.liner-row-blue {
  background: linear-gradient(to right, #e3e3ff, #fdfdff);
  border-radius: 10px;
  color: #696ef6;
}
.liner-row-green {
  background: linear-gradient(to right, #d6f7e9, #fbfffc);
  border-radius: 10px;
  color: #1fab6e;
}
.logo-orange {
  margin: 15px auto 15px 20px;
  display: inline-block;
  background-color: #ff8c2c;
  padding: 7px 6px;
  border-radius: 5px;
  color: #fff;
}
.logo-blue {
  margin: 15px auto 15px 20px;
  display: inline-block;
  background-color: #696ef6;
  padding: 7px 6px;
  border-radius: 5px;
  color: #fff;
}
.logo-green {
  margin: 15px auto 15px 20px;
  display: inline-block;
  background-color: #1fab6e;
  padding: 7px 6px;
  border-radius: 5px;
  color: #fff;
}
.innerSpan {
  border: 1px solid #fff;
  padding: 1px 4px;
  border-radius: 3px;
}
.scopeTitle {
  background: url('@/assets/brace.png') no-repeat right 13px center;
}
</style>
<style>
.orange {
  color: #ff8c2c;
}
.blue {
  color: #696ef6;
}
.green {
  color: #1fab6e;
}
#summaryRoot .el-statistic {
  text-align: left;
  padding-right: 10px;
  font-size: 13px;
}
#summaryRoot .el-statistic .con {
  display: block;
}
#summaryRoot .el-statistic .con .number {
  font-size: 16px;
  padding-left: 0px;
  padding-right: 4px;
}
#summaryRoot .el-statistic .con .suffix {
  font-size: 12px;
}
</style>
