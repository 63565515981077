<template>
  <div v-loading="loading" id="RWSDiv">
    <!-- 任务书表单 -->
    <el-form size="mini" :model="form" label-width="120px" :disabled="disabled" :rules="rules" ref="taskForm">
      <el-divider content-position="left">
        使用方单位 <el-button type="success" @click="addUseUnits" icon="el-icon-circle-plus" circle> </el-button
      ></el-divider>
      <div
        v-for="(useUnit, index) in form.useUnits"
        :key="useUnit.id"
        style="border: 1px solid #e5e5e5; background-color: #f4f4f4; padding: 10px 10px 0px 0px; margin-bottom: 10px"
      >
        <el-row class="unitRow">
          <el-col :span="8">
            <el-form-item
              label="单位名称"
              :prop="'useUnits.' + index + '.institutionId'"
              :rules="[
                {
                  required: true,
                  message: '请选择单位名称',
                  trigger: 'blur',
                },
                {
                  validator: unitValidator,
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="useUnit.institutionId"
                filterable
                placeholder="请选择"
                style="width: 100%"
                ref="useUnitsSelect"
              >
                <el-option v-for="org in organizations" :key="org.id" :label="org.value" :value="org.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人">
              <el-select
                allow-create
                v-model="useUnit.applyJobRp"
                clearable
                filterable
                placeholder="请选择或输入"
                style="width: 100%"
                @change="(val) => handleApplyJobRpChange(val, useUnit)"
              >
                <el-option v-for="item in applyJobRps" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              label="联系方式"
              :prop="'useUnits.' + index + '.applyJobRpTel'"
              :rules="[
                ,/*{
                   validator: phoneOrTelValidator,
                  trigger: 'blur',
                }*/
              ]"
            >
              <el-input v-model="useUnit.applyJobRpTel" @change="useUnit.applyJobRpChanged = true" />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-button
              type="danger"
              :disabled="disabled"
              @click="deleteUseUnits(index)"
              icon="el-icon-delete"
              circle
              style="float: right"
            >
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item
            label="任务内容"
            :prop="'useUnits.' + index + '.taskContent'"
            :rules="{
              required: true,
              message: '请填写任务内容',
              trigger: 'blur',
            }"
          >
            <el-input v-model="useUnit.taskContent" type="textarea" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="数据用途">
            <el-input v-model="useUnit.dataUse" type="textarea" />
          </el-form-item>
        </el-row>
      </div>

      <el-divider content-position="left"> 流程信息 </el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="服务负责人" prop="serviceManager">
            <allow-create-dictionary-select v-model="form.serviceManager" style="width: 100%" ref="serviceManager">
            </allow-create-dictionary-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="任务来源单位">
            <el-select v-model="form.taskSourceUnit" filterable placeholder="请选择" style="width: 100%" clearable>
              <el-option v-for="org in taskSourceUnits" :key="org.id" :label="org.value" :value="org.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="任务接收时间" prop="taskFrom">
            <el-date-picker
              v-model="form.taskFrom"
              type="date"
              placeholder="请选择时间"
              format="yyyy年M月d日"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="是否有函件" prop="taskHas">
            <el-radio v-model="form.taskHas" :label="true">是</el-radio>
            <el-radio v-model="form.taskHas" :label="false">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="任务书编号/函名" prop="taskNum">
            <el-input v-model="form.taskNum" :disabled="!form.taskHas" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否加水印" prop="watermarkHas">
            <el-radio v-model="form.watermarkHas" :label="true">是</el-radio>
            <el-radio v-model="form.watermarkHas" :label="false">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="form.taskApproval == 3 ? 16 : 8" style="display: flex">
          <el-form-item label="院内审批情况" class="taskApproval">
            <el-radio-group v-model="form.taskApproval" style="width: 260px">
              <el-radio label="1">OA流程</el-radio>
              <el-radio label="2">线下流程</el-radio>
              <el-radio label="3">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-input v-show="form.taskApproval == 3" v-model="form.taskOther" class="taskOther" />
        </el-col>
        <el-col :span="8" v-if="form.taskApproval == 1">
          <el-form-item label="收文编号" prop="fileNumEnd">
            <el-input v-model="form.fileNumEnd" :disabled="!(form.taskApproval == 1)">
              <el-input-number
                slot="prepend"
                v-model="form.fileNumYear"
                controls-position="right"
                :min="2002"
                :max="2052"
                class="fileNumYear"
                :disabled="!(form.taskApproval == 1)"
              ></el-input-number>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.taskApproval == 1">
        <div v-for="useUnit in form.useUnits" :key="'2' + useUnit.id">
          <!-- <el-col :span="8">
            <el-form-item label="协议书编号">
              <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ useUnit.name }}</div>
            </el-form-item>
          </el-col> -->
          <el-col :span="16">
            <el-form-item label="协议书编号" prop="agreementNum">
              <el-col :span="12">
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                  {{ useUnit.name ? useUnit.name : '...' }}
                </div>
              </el-col>
              <el-col :span="12" class="agreement">
                <el-input-number
                  v-model="useUnit.agreementNumYear"
                  controls-position="right"
                  :min="2002"
                  :max="2052"
                  class="fileNumYear"
                  :disabled="!(form.taskApproval == 1)"
                >
                </el-input-number>
                <el-form-item style="margin-bottom: 0px" label="（密）第" label-width="65px" prop="agreementNumMiddle">
                  <el-input v-model="useUnit.agreementNumMiddle" :disabled="!(form.taskApproval == 1)" />
                </el-form-item>
                <el-form-item
                  label="号"
                  label-width="35px"
                  style="flex: 1 1 auto; margin-bottom: 0px"
                  prop="agreementNumEnd"
                >
                  <el-input v-model="useUnit.agreementNumEnd" :disabled="!(form.taskApproval == 1)" />
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-col>
        </div>
      </el-row>

      <!-- 文件材料     -->
      <div v-if="serviceId">
        <el-divider content-position="left"> 文件材料 </el-divider>
        <el-row>
          <el-form-item label="">
            <el-table :data="documents" stripe style="width: 100%" border>
              <el-table-column prop="number" label="序号" align="center" width="60px">
                <template slot-scope="scope">
                  <span>{{ '100' + (scope.$index + 1) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="分类" header-align="center" show-overflow-tooltip width="150px" />
              <el-table-column label="文件名称" header-align="center" show-overflow-tooltip>
                <template #default="scope">
                  <upload-item
                    :referenceId="serviceId"
                    tab="TaskForm"
                    :type="scope.row.type"
                    :disabled="disabled"
                    :ref="'upload' + serviceId + scope.$index"
                  />
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150px" v-if="!disabled" align="center">
                <template #default="scope">
                  <el-button type="text" v-if="scope.row.type == 'OA系统材料'"> OA系统对接 </el-button>
                  <el-button
                    type="text"
                    v-if="scope.row.type != 'OA系统材料'"
                    @click="$refs['upload' + serviceId + scope.$index].save()"
                  >
                    上传
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-row>
      </div>

      <el-divider content-position="left" v-if="serviceId"> 任务状态 </el-divider>
      <el-form-item label-width="0px">
        <status-pane
          :disabled="disabled"
          :stage="stage"
          :recordId="serviceId"
          @notifyStageUpdate="notifyStageUpdate"
        ></status-pane>
      </el-form-item>
      <el-form-item class="buttonsItem" label-width="0px">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
          :disabled="loading || disabled || saving || !recordNum || recordNum.trim().length == 0"
        >
          {{ serviceId ? '更新' : '保存' }}
        </el-button>
        <el-button @click="notifyCloseDialog"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment'
import * as LocalConfig from '@/utils/base'
import UploadItem from '@/components/business/UploadItem.vue'
import StatusPane from '@/components/business/StatusPane.vue'
import AllowCreateDictionarySelect from '@/components/business/AllowCreateDictionarySelect.vue'
export default {
  name: 'Task',
  components: { UploadItem, StatusPane, AllowCreateDictionarySelect },
  props: {
    // 备案编号
    recordNum: {
      type: String,
      default: '',
    },
    // 是否只读
    disabled: {
      type: Boolean,
      default: false,
    },
    // 备案Id
    serviceId: String,
    //备案状态
    stage: Number,
  },
  data() {
    return {
      saving: false,
      form: { useUnits: [] },
      loading: true,
      organizations: [],
      taskSourceUnits: [],
      applyJobRps: [],
      pickerOptions: {
        disabledDate(time) {
          //选择今天以及今天之前的日期
          return time.getTime() > new Date(moment().format('YYYY-MM-DD')).getTime()
        },
      },
      rules: {
        serviceManager: [{ required: true, message: '请选择服务负责人', trigger: 'blur' }],
        taskFrom: [{ required: true, message: '请选择任务接收时间', trigger: 'blur' }],
        taskHas: [{ required: true, message: '请选择是否有函件', trigger: 'blur' }],
        watermarkHas: [{ required: true, message: '请选择是否加水印', trigger: 'change' }],
        agreementNum: [{ max: 64, message: '协议书编号最大长度64个字符', trigger: 'blur' }],
        taskNum: [{ max: 64, message: '任务书编号/函名最大长度64个字符', trigger: 'blur' }],
        fileNumEnd: [
          { max: 64, message: '收文编号最大长度64个字符', trigger: 'blur' },
          {
            validator: this.fileNumValidator,
            trigger: 'blur',
          },
        ],
        agreementNumMiddle: [
          {
            validator: this.numberValidator,
            trigger: 'blur',
          },
        ],
      },
      documents: [{ type: '任务书/函件' }, { type: 'OA系统材料' }, { type: '保密协议' }, { type: '介绍信' }],
    }
  },
  methods: {
    fileNumValidator(rule, value, callback) {
      if (!value) {
        callback()
      }
      this.getRequest('/server/record/task/fileNumExists', {
        id: this.serviceId,
        fileNum: this.form.fileNumYear + this.form.fileNumEnd,
      }).then((res) => {
        if (res) {
          callback(new Error('已经有相同的收文编号存在'))
        } else {
          callback()
        }
      })
    },
    numberValidator(rule, value, callback) {
      if (!value) {
        callback()
      }
      if (!Number(value)) {
        callback(new Error('编号必须是整数'))
      } else {
        const re = /^[0-9]*[1-9][0-9]*$/
        const rsCheck = re.test(value)
        if (!rsCheck) {
          callback(new Error('编号必须是整数'))
        } else {
          callback()
        }
      }
    },
    /**
     * 避免同一个单位出现多次
     */
    unitValidator(rule, value, callback) {
      let array = this.form.useUnits.filter((unit) => {
        return unit.institutionId == value
      })
      if (array.length > 1) {
        callback(new Error('同一个单位只能出现一次'))
      } else {
        callback()
      }
    },
    phoneOrTelValidator(rule, value, callback) {
      const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/
      if (!value) {
        callback()
      } else {
        if (!reg.test(value) && value != '') {
          callback(new Error('请输入正确的手机或固话号码'))
        } else {
          callback()
        }
      }
    },
    notifyStageUpdate(stage) {
      this.$emit('notifyStageUpdate', stage)
    },
    loadData() {
      this.loading = true
      if (!this.serviceId || this.serviceId === '') {
        this.$set(this, 'form', {
          id: null,
          loaded: true,
          useUnits: [{ agreementNumYear: new Date().getFullYear(), agreementNumMiddle: '', agreementNumEnd: '' }],
          recordNum: this.recordNum,
          fileNumYear: new Date().getFullYear(),
          fileNumEnd: '',
          //   agreementNumYear: new Date().getFullYear(),
          //   agreementNumMiddle: '',
          //   agreementNumEnd: '',
        })
        this.loading = false
        return
      }

      this.getRequest('/server/record/' + this.serviceId)
        .then((res) => {
          if (res && res.task) {
            if (res.task.fileNum && res.task.fileNum.length > 4) {
              res.task.fileNumYear = res.task.fileNum.substring(0, 4)
              res.task.fileNumEnd = res.task.fileNum.substring(4)
            } else {
              res.task.fileNumYear = new Date().getFullYear()
              res.task.fileNumEnd = ''
            }

            res.task.useUnits.forEach((useUnit) => {
              useUnit.agreementNumYear = new Date().getFullYear()
              useUnit.agreementNumMiddle = ''
              useUnit.agreementNumEnd = ''
              if (useUnit.agreementNum && useUnit.agreementNum.length > 4) {
                let [year, right] = useUnit.agreementNum.split('（密）第')
                if (right) {
                  useUnit.agreementNumYear = year
                  let [middle, end] = right.split('号')
                  useUnit.agreementNumMiddle = middle ? middle : ''
                  useUnit.agreementNumEnd = end ? end : ''
                } else {
                  useUnit.agreementNumYear = new Date().getFullYear()
                }
              }
            })
            // res.task.agreementNumYear = new Date().getFullYear()
            // res.task.agreementNumMiddle = ''
            // res.task.agreementNumEnd = ''
            // if (res.task.agreementNum && res.task.agreementNum.length > 4) {
            //   let [year, right] = res.task.agreementNum.split('（密）第')
            //   if (right) {
            //     res.task.agreementNumYear = year
            //     let [middle, end] = right.split('号')
            //     res.task.agreementNumMiddle = middle ? middle : ''
            //     res.task.agreementNumEnd = end ? end : ''
            //   } else {
            //     res.task.agreementNumYear = new Date().getFullYear()
            //   }
            // }

            this.form = res.task
            this.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    notifySaved() {
      this.saving = false
      this.$emit('notifyReload')
    },
    notifyServiceIdCreated(id) {
      this.$emit('notifyServiceIdUpdate', id)
    },
    notifyCloseDialog() {
      this.$emit('notifyCloseDialog')
    },
    async save() {
      let beforeSave = []
      this.form.useUnits.forEach((unit) => {
        if (unit.applyJobRpChanged) {
          beforeSave.push(this.saveOrUpdateApplyJobRp(unit))
        }
      })

      if (this.$refs.serviceManager) {
        beforeSave.push(this.$refs['serviceManager'].saveOrUpdate())
      }
      Promise.all(beforeSave)
        .then((responses) => {
          this.saveItself()
        })
        .catch((error) => {
          console.error(`存储失败：${error}`)
          this.saving = false
        })
    },
    async saveItself() {
      if (this.form.taskApproval == 1) {
        this.form.fileNum = this.form.fileNumYear + this.form.fileNumEnd
        this.form.useUnits.forEach((useUnit) => {
          useUnit.agreementNum =
            useUnit.agreementNumYear + '（密）第' + useUnit.agreementNumMiddle + '号' + useUnit.agreementNumEnd
        })
      } else {
        this.form.fileNum = ''
        this.form.useUnits.forEach((useUnit) => {
          useUnit.agreementNum = ''
        })
      }

      if (!this.form.id) {
        let catches = null
        await this.getRequest('/server/record/check-record-num/' + this.recordNum)
          .then((data) => {
            if (data && data != this.recordNum) {
              this.$emit('notifyRecordNumUpdate', data)
              this.form.recordNum = data
            }
          })
          .catch((err) => {
            catches = err
            console.log(err)
          })
        if (catches) {
          this.saving = false
          //alert(catches)
          return
        }
      }

      let requestData = JSON.parse(JSON.stringify(this.form))
      let callback = this.notifySaved
      let request = this.form.id ? this.putRequest : this.postRequest

      request('/server/record/task', requestData)
        .then((res) => {
          if (res && res.code === 200) {
            if (!this.form.id || this.form.id === '') {
              this.form.id = res.obj
              this.notifyServiceIdCreated(this.form.id)
            }
            callback()
          }
          this.saving = false
        })
        .catch((err) => {
          console.log(err)
          this.saving = false
        })
    },
    onSubmit() {
      this.saving = true
      setTimeout(() => {
        if (this.saving == true) {
          this.saving = false
        }
      }, 1000 * 60)
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          this.save()
        } else {
          this.saving = false
          return false
        }
      })
    },
    handleApplyJobRpChange(id, unit) {
      if (id === '') {
        //clear
        this.$set(unit, 'applyJobRpTel', '')
        unit.applyJobRpChanged = false
      } else {
        let applyJobRp = this.applyJobRps.filter((item) => {
          return item.id === id
        })[0]
        if (!applyJobRp) {
          //create
          unit.applyJobRpChanged = true
        } else {
          //select
          if (applyJobRp.value) {
            let arr = applyJobRp.value.split('/')
            this.$set(unit, 'applyJobRpTel', arr[0])
          }
          unit.applyJobRpChanged = false
        }
      }
    },
    async saveOrUpdateApplyJobRp(unit) {
      let request = this.postRequest
      let applyJobRp = this.applyJobRps.filter((item) => {
        return item.id === unit.applyJobRp
      })[0]
      if (applyJobRp) {
        request = this.putRequest
        applyJobRp.value = unit.applyJobRpTel
      } else {
        let name = unit.applyJobRp
        applyJobRp = {
          name: name,
          value: unit.applyJobRpTel,
          group: '联系人',
        }
      }
      let result = null
      await request('/server/dictionary/', applyJobRp)
        .then((res) => {
          if (res.code == 200) {
            if (!applyJobRp.id) {
              unit.applyJobRp = res.obj
              result = res.obj
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
      if (result) {
        unit.applyJobRpChanged = false
        unit.applyJobRpCreated = false
        LocalConfig.loadApplyJobRpsFromConfig(true).then((result) => (this.applyJobRps = result))
      }
      return result
    },

    addUseUnits() {
      this.form.useUnits.unshift({
        recordId: this.form.id,
        agreementNumYear: new Date().getFullYear(),
        agreementNumMiddle: '',
        agreementNumEnd: '',
      })
    },

    deleteUseUnits(index) {
      let useUnit = this.form.useUnits[index]
      if (useUnit.id) {
        this.$confirm('此操作将删除使用单位, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.deleteRequest('/server/record-institution/' + useUnit.id).then((res) => {
              if (res) {
                this.form.useUnits.splice(index, 1)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      } else {
        this.form.useUnits.splice(index, 1)
      }
    },
  },
  created() {
    this.loadData()
    LocalConfig.loadApplyJobRpsFromConfig(true).then((result) => (this.applyJobRps = result))
    LocalConfig.loadOrganizationsFromConfig().then((result) => {
      this.organizations = result
    })
    LocalConfig.loadTaskSourceUnitsFromConfig(true).then((result) => (this.taskSourceUnits = result))
  },
  mounted() {
    //this.loadData()
  },
  watch: {
    'form.useUnits': function (val, oldVal) {
      this.form.useUnits.forEach((useUnit, index) => {
        this.organizations.some((org) => {
          if (org.id === useUnit.institutionId) {
            useUnit.name = org.name
            return true
          }
        })
      })
    },
    serviceId: function (val, oldVal) {
      this.loadData()
      this.$refs['taskForm'].resetFields()
    },
    recordNum: function (val, oldVal) {
      this.form.recordNum = val
    },
    'form.taskApproval': function (val, oldVal) {
      if (val && val > 1) {
        this.form.fileNumEnd = ''
        this.form.useUnits.forEach((useUnit) => {
          useUnit.agreementNumYear = new Date().getFullYear()
          useUnit.agreementNumMiddle = ''
          useUnit.agreementNumEnd = ''
        })
      }
    },
    'form.taskHas': function (val, oldVal) {
      if (val == false) {
        this.form.taskNum = ''
      }
    },
  },
  computed: {
    // loading() {
    //   if (this.form.id === undefined) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
  },
}
</script>

<style scoped>
.buttonsItem {
  margin-bottom: 10px;
  text-align: center;
}
.unitRow .el-button {
  margin-left: 10px;
}
.taskApproval {
  display: inline-block;
}
.taskOther {
  margin-left: 10px;
  width: 100%;
}
.fileNumYear {
  width: 80px;
  display: inline-block;
  height: 28px;
}
#fileNumInput {
  display: inline-block;
  margin: 0px;
}
</style>
<style>
#RWSDiv .el-input.is-disabled .el-input__inner,
#RWSDiv .el-textarea.is-disabled .el-textarea__inner {
  color: #606266;
}
#RWSDiv .fileNumYear.el-input-number.is-controls-right .el-input__inner {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  min-width: 80px;
}
#RWSDiv .el-input-group__prepend {
  padding: 0px;
}
#RWSDiv .agreement .el-form-item__content {
  display: flex;
}
#RWSDiv .agreement {
  display: flex;
}
</style>
