<template>
  <div id="roleMenuDiv">
    <div class="roleTitle">
      <el-input v-model="role.roleName" placeholder="请输入角色"></el-input>
      <el-button type="success" icon="el-icon-plus">添加</el-button>
    </div>
    <div style="margin-top: 6px">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item v-for="(r, index) in roles" :name="r.roleName" :key="index">
          <template slot="title">
            <div>{{ r.roleName }}</div>
          </template>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>可访问资源</span>
              <el-button style="float: right; padding: 3px 0; color: red" icon="el-icon-delete"></el-button>
            </div>
            <div>
              <el-tree
                :data="menus"
                show-checkbox
                node-key="id"
                @check-change="checkChanged(arguments, r.id)"
                :default-checked-keys="defaultCheckedKeys[r.id]"
                :props="defaultProps"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <el-tag v-if="data.url == null">菜单</el-tag>
                  <el-tag type="success" v-if="data.url != null">API</el-tag>
                </span>
              </el-tree>
            </div>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleMenu',
  data() {
    return {
      role: {
        roleName: '',
      },
      defaultCheckedKeys: {},
      roles: [],
      activeName: '1',
      menus: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    }
  },
  mounted() {
    this.initRoles()
    this.loadAllMenus()
  },
  methods: {
    loadSettings() {
      this.getRequest('/server/sys-menu-role/').then((resp) => {
        if (resp) {
          this.roles.forEach((role) => {
            this.defaultCheckedKeys[role.id] = resp
              .filter((item) => {
                return item.roleId == role.id
              })
              .map((item) => {
                return item.menuId
              })
          })
        }
      })
    },
    initRoles() {
      this.getRequest('/system/basic/role/').then((resp) => {
        if (resp) {
          this.roles = resp
          this.loadSettings() //加载角色对应的菜单项设置
        }
      })
    },
    loadAllMenus() {
      this.getRequest('/system/basic/menu/all').then((resp) => {
        if (resp) {
          this.menus = resp
        }
      })
    },
    checkChanged(args, roleId) {
      //当前节点是否被选中，args[2],是否有下级被选中
      this.postRequest('/server/sys-menu-role/' + (args[1] ? 'add' : 'delete'), {
        id: '',
        menuId: args[0].id,
        roleId: roleId,
      }).then((resp) => {})
    },
  },
}
</script>

<style scoped>
.roleTitle {
  display: flex;
}

.roleTitle .el-input {
  width: 300px;
  margin-right: 6px;
}
</style>
<style>
#roleMenuDiv .el-collapse-item__header {
  font-size: 12px;
  font-weight: normal;
  height: 36px;
  line-height: 36px;
  margin-left: 10px;
}
#roleMenuDiv .el-collapse-item__content {
  padding-bottom: 0px;
  font-size: 12px;
}
#roleMenuDiv .el-card__header {
  display: none;
}
#roleMenuDiv .el-tree-node__label {
  font-size: 12px;
}
#roleMenuDiv .el-card__body {
  padding: 5px;
}
</style>
