<template>
  <div id="DataDiv" v-loading="loading">
    <div style="display: flex">
      <el-divider content-position="left">
        <span style="margin-right: 10px">数据信息</span>
        <el-button type="success" @click="create()" icon="el-icon-circle-plus" circle :disabled="disabled"> </el-button>
      </el-divider>
      <div class="el-divider__text is-right" style="margin-top: 24px; padding-right: 0px">
        <el-button @click="refresh()" icon="el-icon-refresh-right" circle :disabled="disabled"> </el-button>
      </div>
    </div>
    <!-- 水印下载对话框 -->
    <el-dialog title="水印文件信息" :visible.sync="watermarkInfoVisible" width="75%" append-to-body id="watermarkDiv">
      <el-form :model="watermarkForm" size="mini" label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="任务书/函：">
              {{ watermarkForm.taskNum }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备案编号：">
              {{ watermarkForm.recordNum }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务类型：">
              {{ watermarkForm.serverTypeText }}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="数据名称/内容：">
              {{ watermarkForm.dataNameAndContent }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本信息：">
              {{ watermarkForm.version }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版权单位：">
              {{ watermarkForm.copyRight }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数据生产时间：">{{ watermarkForm.produceDate }}</el-form-item>
          </el-col>
        </el-row>
        <el-row v-for="useUnit in watermarkForm.useUnits" :key="useUnit.id">
          <el-divider></el-divider>
          <el-col :span="8">
            <el-form-item label="使用单位：">
              {{ useUnit.name }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位性质：">
              {{ watermarkForm.useUnitType }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="">
              <el-button type="primary" icon="el-icon-download" @click="download(useUnit.file, useUnit.fileName)">
                下载水印任务文件
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="数据用途：">
              {{ useUnit.dataUse }}
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <span class="dialog-footer span-center">
          <el-button @click="watermarkForm = false">返回</el-button>
          <el-button type="primary" @click="downloadBatch()">下载</el-button>
        </span> -->
      </el-form>
    </el-dialog>
    <!-- 生产数据列表 -->
    <el-collapse v-model="activeNames" @change="handleCollapseChange">
      <el-collapse-item :name="editForm.id" v-for="(editForm, index) in tableData" :key="editForm.id">
        <template slot="title">
          <span style="margin-left: 40px; font-weight: 400; width: 100%" v-if="activeNames.indexOf(editForm.id) == -1">
            <el-col :span="6" class="fixedWidth">生产编号：{{ editForm.dataNum }}</el-col>
            <el-col :span="6" class="fixedWidth">数据名称：{{ editForm.dataName }}</el-col>
            <el-col :span="6" class="fixedWidth">数据量：{{ editForm.dataSize }}{{ editForm.dataSizeUnit }}</el-col>
            <el-col :span="6" class="fixedWidth">使用单位：{{ array2text(editForm.useUnitNames) }}</el-col>
          </span>
        </template>
        <div class="recordDiv">
          <produce
            :ref="'editForm' + editForm.id"
            :serviceId="serviceId"
            :model.sync="tableData[index]"
            :disabled="disabled"
            :dataTypes="dataTypes"
            :taskTypes="taskTypes"
            :dataFormats="dataFormats"
            :dataClasses="dataClasses"
            :originalDataList="originalDataList"
            :serviceTypes="serviceTypes"
            :organizations="organizations"
            :companies="companies"
            :commonCityOptions="cityOptions"
            :showWatermartButtons="showWatermartButtons"
            @notifyCreated="proceedCreated"
            @notifyDeleted="proceedDeleted"
            @notifyCloseDialog="notifyCloseDialog"
            @viewWatermark="viewWatermark"
            @notifyUpdateCompanies="updateCompanies"
            @getTaskFrom="getTaskFrom"
          ></produce>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div id="editDiv" ref="editDiv" v-if="editFormVisible">
      <produce
        ref="editForm"
        :serviceId="serviceId"
        :model.sync="editForm"
        :disabled="disabled"
        :dataTypes="dataTypes"
        :taskTypes="taskTypes"
        :dataFormats="dataFormats"
        :dataClasses="dataClasses"
        :originalDataList="originalDataList"
        :serviceTypes="serviceTypes"
        :organizations="organizations"
        :companies="companies"
        :commonCityOptions="cityOptions"
        :showWatermartButtons="showWatermartButtons"
        @notifyCreated="proceedCreated"
        @notifyDeleted="proceedDeleted"
        @notifyCloseDialog="notifyCloseDialog"
        @notifyUpdateCompanies="updateCompanies"
        @viewWatermark="viewWatermark"
        @getTaskFrom="getTaskFrom"
      ></produce>
    </div>
    <el-divider content-position="left" v-if="serviceId"> 任务状态 </el-divider>
    <status-pane
      :disabled="disabled"
      :stage="stage"
      :recordId="serviceId"
      @notifyStageUpdate="notifyStageUpdate"
    ></status-pane>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import { Loading } from 'element-ui'
import UploadItem from '@/components/business/UploadItem.vue'
import Produce from '@/components/business/ProduceData.vue'
import StatusPane from '@/components/business/StatusPane.vue'
import rsa from '@/utils/RSA'
import moment from 'moment'
import { saveAs } from 'file-saver'

export default {
  name: 'DataForm',
  components: { UploadItem, StatusPane, Produce },
  props: {
    serviceId: String,
    recordNum: {
      type: String,
      default: '',
    },
    stage: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      dataTypes: [],
      taskTypes: [],
      dataFormats: [],
      dataClasses: [],
      originalDataList: [],
      serviceTypes: [],
      tableData: [],
      editForm: {},
      editFormVisible: false,
      watermarkForm: {},
      watermarkInfoVisible: false,
      organizations: [],
      companies: [],
      taskFrom: null,
      activeNames: [],
      datePickerLoadingInstance: null,
      showWatermartButtons: false,
    }
  },
  methods: {
    array2text(array) {
      let text = ''
      array.forEach((item) => {
        text += '、' + item
      })
      return text.length > 1 ? text.substring(1) : ''
    },
    async getTaskFrom() {
      if (!this.serviceId) {
        return
      }
      let result = null
      await this.getRequest('/server/record/task/from/' + this.serviceId)
        .then((res) => {
          result = res.obj ? res.obj : null
          this.taskFrom = result
        })
        .catch((err) => {
          console.log(err)
        })
      return result
    },
    async getTaskNum() {
      if (!this.serviceId) {
        return
      }
      let result = null
      await this.getRequest('/server/record/task/num/' + this.serviceId)
        .then((res) => {
          result = res.obj ? res.obj : null
        })
        .catch((err) => {
          console.log(err)
        })
      return result
    },
    async getTaskWatermarkHas() {
      if (!this.serviceId) {
        return
      }
      let result = null
      await this.getRequest('/server/record/task/watermarkHas/' + this.serviceId)
        .then((res) => {
          result = res.obj ? res.obj : false
          this.showWatermartButtons = result
        })
        .catch((err) => {
          console.log(err)
        })
      return result
    },

    handleCollapseChange(activeNames) {
      activeNames.forEach((id) => {
        setTimeout(() => {
          this.$refs['editForm' + id][0].updateDataUnitItem()
        }, 200)
      })
    },
    async updateCompanies() {
      let result = await LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId)
      if (result) {
        this.companies = result
        return result
      }
      return null
    },
    async viewWatermark(editForm) {
      if (editForm.useUnits.length < 1) {
        this.$message({
          type: 'warning',
          message: '请先选择使用单位',
        })
        return
      }

      this.watermarkForm = {}
      this.watermarkForm.useUnitType = '部系统单位'
      this.watermarkForm.recordNum = editForm.dataNum ? editForm.dataNum : '无'
      this.watermarkForm.copyRight = '中国国土勘测规划院'
      this.watermarkForm.dataNameAndContent = editForm.dataName ? editForm.dataName : '无'
      // + '/' + (editForm.dataContent ? editForm.dataContent : '无')
      this.watermarkForm.version = editForm.version ? editForm.version : '无'
      let serviceType = this.serviceTypes.filter((item) => {
        return item.id === editForm.serverType
      })
      this.watermarkForm.serverTypeText = serviceType.length > 0 && serviceType[0].name ? serviceType[0].name : '无'

      let taskNum, taskFrom, taskUseUnits
      let promises = []
      promises.push(this.getTaskNum())
      promises.push(this.getTaskFrom())
      promises.push(this.updateCompanies())
      try {
        const allPromise = Promise.all(promises)
        const values = await allPromise
        taskNum = values[0]
        taskFrom = values[1]
        taskUseUnits = values[2]
          ? values[2].map((unit) => {
              return unit.name
            })
          : null
        //
        this.watermarkForm.taskNum = taskNum ? taskNum : '无'
        this.watermarkForm.produceDate = taskFrom ? taskFrom : '无'
        let that = this
        this.watermarkForm.useUnits = []
        editForm.useUnits.forEach((useUnit, index) => {
          let found = this.companies.filter((item) => {
            return item.id === useUnit
          })
          let useUnitText = found.length > 0 ? found[0].name : '无'
          that.watermarkForm.useUnits[index] = { name: useUnitText }
          let dataUse = found.length > 0 ? (found[0].dataUse ? found[0].dataUse : '无') : '无'
          that.watermarkForm.useUnits[index].dataUse = dataUse
          let watermark = {
            服务类型: that.watermarkForm.serverTypeText,
            服务对象性质: '部系统单位',
            备案编号: editForm.dataNum,
            版权单位: '中国国土勘测规划院',
            使用单位: useUnitText,
            申请部门: '',
            数据用途: dataUse ? dataUse : '',
            '数据名称/内容': editForm.dataName,
            //  + '/' + editForm.dataContent,
            '任务书/函': taskNum ? taskNum : '',
            版本信息: editForm.version ? editForm.version : '',
            数据生产时间: taskFrom,
            表单时间: moment().format('YYYY-MM-DD HH:mm:ss'),
            水印嵌入时间: '',
            水印信息单号: '',
            备注: '',
          }
          let fileName = '数字水印任务文件' + editForm.dataNum + '(' + useUnitText + ')' + '.json'
          let file = new Blob([rsa.encryptData(JSON.stringify(watermark))], { type: 'text/plain;charset=utf-8' })
          that.watermarkForm.useUnits[index].file = file
          that.watermarkForm.useUnits[index].fileName = fileName

          this.watermarkInfoVisible = true
          //saveAs(file, fileName)
        })
        //
      } catch (error) {
        console.error(`获取任务信息失败：${error}`)
        return
      }
    },
    download(file, fileName) {
      saveAs(file, fileName)
    },
    loadTable() {
      if (!this.serviceId || this.serviceId === '') {
        return
      }
      this.loading = true
      this.getRequest('/server/produce/' + this.serviceId)
        .then((res) => {
          if (res) {
            this.tableData = res
            this.tableData.forEach((row) => {
              row.dataType = row.dataType ? JSON.parse(row.dataType) : []
              row.dataFormat = row.dataFormat ? JSON.parse(row.dataFormat) : []
              row.dataClass = row.dataClass ? JSON.parse(row.dataClass) : []
              row.dataRangeWithFiles = row.dataRange ? JSON.parse(row.dataRange) : []
              if (row.produceFromDate && row.produceToDate) {
                this.$set(row, 'produceDate', [row.produceFromDate, row.produceToDate])
              }
            })
            this.loading = false
            this.page = this.nxtPage
            this.total = res.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getNewProduceNum(callback) {
      this.getRequest('/server/produce/new/' + this.serviceId)
        .then((res) => {
          if (res) {
            callback(res.obj)
          }
        })
        .catch((err) => {})
    },
    create() {
      if (!this.serviceId) {
        this.$message({
          type: 'info',
          message: '请先创建任务',
        })
        return
      }
      this.editForm = {
        recordId: this.serviceId,
        vectorDataRangeFileHas: null,
        dataRangeWithFiles: [],
        cityOptions: this.cityOptions,
        // serverType: '',
        // dataSizeUnit: '',
      }
      this.getNewProduceNum((num) => {
        this.$set(this.editForm, 'dataNum', num)
        this.$refs['editDiv'].scrollIntoView({ behavior: 'auto', block: 'center' })
      })
      this.editFormVisible = true
    },
    proceedDeleted(id) {
      if (!id) {
        this.editFormVisible = false
      } else {
        this.tableData.some((row, index) => {
          if (row.id === id) {
            this.tableData.splice(index, 1)
            return true
          }
        })
      }
    },
    proceedCreated(editForm) {
      this.editFormVisible = false
      this.tableData.push(editForm)
    },
    notifyStageUpdate(stage) {
      this.$emit('notifyStageUpdate', stage)
    },
    refresh() {
      LocalConfig.loadDistrictsFromConfig(true).then((result) => {
        this.cityOptions = result
        this.loadTable()
      })
      this.getTaskWatermarkHas()
      LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId).then((result) => (this.companies = result))
      LocalConfig.loadServiceTypeFromConfig(true).then((result) => (this.serviceTypes = result))
      LocalConfig.loadOriginalDataListFromConfig(true).then((result) => (this.originalDataList = result))

      LocalConfig.loadProduceDepartmentsFromConfig(true).then((result) => (this.organizations = result))
      LocalConfig.loadDataTypesFromConfig(true).then((result) => (this.dataTypes = result))
      LocalConfig.loadDataFormatsFromConfig(true).then((result) => (this.dataFormats = result))
      LocalConfig.loadDataClassesFromConfig(true).then((result) => (this.dataClasses = result))
      LocalConfig.loadTaskTypeFromConfig(true).then((result) => (this.taskTypes = result))
    },
    notifyCloseDialog() {
      this.$emit('notifyCloseDialog')
    },
  },
  created() {
    this.refresh()
  },
  watch: {
    serviceId: function (val, oldVal) {
      if (val && val != '') {
        this.loadTable()
        this.getTaskWatermarkHas()
        LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId).then((result) => (this.companies = result))
      } else {
        this.tableData = []
        this.taskFrom = null
        this.city = ''
      }
      this.activeNames = []
      this.editForm = {}
      this.editFormVisible = false
    },
    editFormVisible: function (val, oldVal) {
      if (val) {
        setTimeout(() => {
          this.$refs['editForm'].updateDataUnitItem()
        }, 200)
      }
    },
  },
  computed: {},
}
</script>

<style scoped>
/* .demo-table-expand {
  font-size: 0;
  margin-left: 155px;
} */
.span-center {
  width: 100%;
  text-align: center;
  display: block;
}
#editDiv,
.recordDiv {
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding: 15px 15px 10px 10px;
}
#DataDiv #editDiv {
  background-color: #fadfc7;
  /* #EFCB68 #f4f4f4 #decccc */
}
#DataDiv .recordDiv {
  background-color: #f4f4f4;
}
.fixedWidth {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style>
/* .demo-table-expand label {
  width: 100px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.datasizeitem .el-form-item__content {
  display: flex;
}
.el-checkbox__label {
  font-size: 12px;
} */
#DataDiv #editDiv .el-divider__text {
  background-color: #fadfc7;
}
#DataDiv .recordDiv .el-divider__text {
  background-color: #f4f4f4;
}
/* .importedDataRage .el-upload-list {
  display: inline-flex;
}
.importedDataRage .el-upload {
  display: inline-flex;
} */

.importedDataRage {
  /* width: 94px;
  padding-left: 20px; */
}
.datasizeunit {
  width: 129px;
}
.dataRangeCol .el-input-group__prepend {
  width: 100%;
  padding: 0px;
}
.dataRangeCol .el-input__inner {
  /* display: none; */
  padding: 0px;
}
#watermarkDiv .el-form-item {
  margin-bottom: 0px;
}
</style>
<style>
.el-form-item__content {
  font-size: 12px;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 12px;
}
#DataDiv .el-collapse-item__header.is-active {
  height: 25px;
}
.span-center {
  width: 100%;
  text-align: center;
  display: block;
}
#watermarkDiv .el-row .el-divider--horizontal {
  margin: 10px 0;
}
</style>
