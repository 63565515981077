<template>
  <div :style="containerStyle">
    <div id="editDiv" v-if="editFormVisible">
      <el-dialog :visible.sync="editFormVisible" width="60%" :title="editForm.id ? '编辑' : '新建'">
        <el-form :model="editForm" size="mini" :label-width="formLabelWidth" :rules="rules" ref="editForm">
          <el-row>
            <el-col :span="8">
              <el-form-item label="编号：" prop="number">
                <el-input placeholder="点击生成编号" v-model="editForm.number" :disabled="true">
                  <el-button slot="append" icon="el-icon-bottom-left" @click="generateNumber()"></el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="硬盘S/N码：">
                <el-input v-model="editForm.sn" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="品牌：">
                <el-select v-model="editForm.brand" filterable placeholder="请选择" style="width: 100%">
                  <!-- <el-option v-for="item in brands" :key="item.id" :label="item.name" :value="item.id" /> -->
                  <el-option v-for="item in brands" :key="item.id" :label="item.name" :value="item.name" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="容量：" prop="capacity">
                <el-input placeholder="请输入容量" v-model="editForm.capacity" class="input-with-select">
                  <el-select style="width: 80px" v-model="editForm.capacityUnit" slot="append" placeholder="请选择">
                    <el-option v-for="item in volumeUnit" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="硬盘类型：">
                <el-select v-model="editForm.type" style="width: 100%" placeholder="请选择">
                  <el-option v-for="item in diskType" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="使用项目：">
                <el-input v-model="editForm.project" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发出时间：" prop="deliverDate">
                <el-date-picker
                  v-model="editForm.deliverDate"
                  type="date"
                  placeholder="请选择时间"
                  style="width: 100%"
                  format="yyyy年M月d日"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收回时间：" prop="receiveDate">
                <el-date-picker
                  v-model="editForm.receiveDate"
                  type="date"
                  placeholder="请选择时间"
                  style="width: 100%"
                  format="yyyy年M月d日"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="使用单位：" prop="department">
                <el-select v-model="editForm.department" clearable filterable placeholder="请选择" style="width: 100%">
                  <el-option v-for="org in organizations" :key="org.id" :label="org.value" :value="org.value" />
                </el-select>
                <!-- <el-input
                  placeholder="请输入内容"
                  v-model="editForm.department"
                  :disabled="true"
                  class="input-with-select"
                >
                  <el-button slot="append" icon="el-icon-search" @click="showInstitutionPicker = true"></el-button>
                </el-input> -->
              </el-form-item>
            </el-col>

            <!--            <el-col :span="24">

              <el-form-item label="说明">
                <el-input v-model="editForm.describes" type="textarea"/>
              </el-form-item>
            </el-col>-->
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="硬盘当前状态：" prop="status">
                <el-radio v-model="editForm.status" :label="true">使用中</el-radio>
                <el-radio v-model="editForm.status" :label="false">已报废</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <!-- <span class="dialog-footer span-center"> -->
          <span class="span-center">
            <el-button type="primary" @click="save()">保存</el-button>
            <el-button @click="editFormVisible = false">返回</el-button>
          </span>
          <!-- </span> -->
        </template>
      </el-dialog>
    </div>
    <div id="listDiv">
      <div style="display: flex; justify-content: space-between; background-color: white; padding: 10px 0px">
        <div>
          <el-input
            class="queryCommon"
            size="mini"
            placeholder="使用单位/项目/编号/sn码"
            suffix-icon="el-icon-search"
            style="width: 280px; margin-right: 10px; margin-left: 10px"
            v-model="searchKey"
            @keydown.enter.native="search"
          >
          </el-input>
          <el-button size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          <el-button size="mini" icon="el-icon-plus" type="success" @click="create" v-if="!$store.state.readonly"
            >添加</el-button
          >
        </div>
        <div v-if="!$store.state.readonly">
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteBatch" style="margin-right: 10px"
            >批量删除</el-button
          >
        </div>
      </div>

      <div id="tableArea">
        <el-table
          :data="tableData"
          stripe
          @selection-change="handleSelectionChange"
          style="width: 100%"
          v-loading="loading"
          border
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="xh" type="index" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="编号" show-overflow-tooltip />
          <el-table-column prop="sn" label="S/N码" show-overflow-tooltip />
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip />
          <el-table-column prop="capacity" label="容量" width="50" show-overflow-tooltip />
          <el-table-column prop="capacityUnit" label="容量单位" width="100" show-overflow-tooltip />
          <el-table-column prop="type" label="硬盘类型" show-overflow-tooltip />
          <el-table-column prop="deliverDate" label="发出时间" show-overflow-tooltip />
          <el-table-column prop="receiveDate" label="收回时间" show-overflow-tooltip />
          <el-table-column prop="department" label="使用单位" show-overflow-tooltip />
          <el-table-column prop="project" label="使用项目" show-overflow-tooltip />
          <el-table-column label="操作" min-width="60px" align="center" v-if="!$store.state.readonly">
            <template #default="scope">
              <el-button type="text" @click="deleteIt(scope.row)"> 删除</el-button>
              <el-button type="text" @click="editIt(scope.row)"> 编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="showInstitutionPicker" title="选择使用单位">
      <institution-picker @pickedName="getInstitutionName"></institution-picker>
      <span slot="footer" class="dialog-footer span-center">
        <el-button type="primary" @click="showInstitutionPicker = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import InstitutionPicker from '@/components/basic/InstitutionPicker'
import moment from 'moment'

export default {
  name: 'Disk',
  components: {
    InstitutionPicker,
  },
  props: {},
  data() {
    return {
      loading: false,
      formLabelWidth: '115px',
      tableData: [],
      searchForm: {},
      editForm: {
        number: '',
      },
      editFormVisible: false,
      showInstitutionPicker: false,
      page: 1,
      ids: [], //用于批量删除  by wangwei
      pageSize: 20,
      nxtPage: 1,
      total: 1,
      multipleSelection: [],
      screenHeight: '',
      volumeUnit: ['GB', 'TB'],
      brands: [
        { id: '1', name: '三星' },
        { id: '2', name: '东芝' },
        { id: '3', name: '西部数据' },
        { id: '4', name: '希捷' },
        { id: '5', name: '闪迪' },
      ],
      diskType: ['移动硬盘', '机械硬盘'],
      searchKey: '',
      rules: {
        number: [{ required: true, message: '系统自动分配的硬盘序列号', trigger: 'blur' }],
        capacity: [{ required: true, message: '输入硬盘容量', trigger: 'blur' }],
        status: [{ required: true, message: '选择硬盘当前状态', trigger: 'change' }],
        receiveDate: [{ required: true, message: '硬盘被交会时间', trigger: 'change' }],
        department: [{ required: true, message: '选择硬盘使用单位', trigger: 'blur' }],
      },
      organizations: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > new Date(moment().format('YYYY-MM-DD')).getTime()
        },
      },
    }
  },
  methods: {
    //生成硬盘序列号
    generateNumber() {
      this.getRequest('/server/disk/sn').then((resp) => {
        if (resp) {
          this.editForm.number = resp
          this.$forceUpdate()
        }
      })
    },
    getInstitutionName(args) {
      this.editForm.department = args.name
    },
    search() {
      this.loadTable()
    },
    deleteBatch() {
      if (this.ids && this.ids.length == 0) {
        this.$message({ type: 'info', message: '首先选中要删除的数据项。' })
        return
      }
      //批量删除数据
      this.$confirm('此操作将删除选中数据行, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/disk/batch', this.ids).then((resp) => {
            if (resp) {
              this.loadTable()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    create() {
      this.editIt(null)
    },
    editIt(row) {
      this.editForm = row ? row : {}
      this.editFormVisible = true
    },
    deleteIt(data) {
      this.$confirm('此操作将删除存储介质（' + data.number + '）, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/disk/' + data.id).then((res) => {
            if (res) {
              this.loadTable()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    save() {
      let requestData = JSON.parse(JSON.stringify(this.editForm))
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          let callback = () => {
            this.loadTable()
            this.editFormVisible = false
          }
          let request = this.editForm.id ? this.putRequest : this.postRequest
          request('/server/disk/', requestData)
            .then((res) => {
              if (res && res.code === 200) {
                callback()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCurrentChange(val) {
      this.nxtPage = val
      this.loadTable()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.loadTable()
    },
    loadTable() {
      this.loading = true
      //let requestData = JSON.parse(JSON.stringify(this.searchForm))  //modify by ww
      this.getRequest('/server/disk/page2?page=' + this.nxtPage + '&pageSize=' + this.pageSize, {
        department: this.searchKey,
        sn: this.searchKey,
        project: this.searchKey,
        number: this.searchKey,
      }) //modify by ww
        .then((res) => {
          if (res) {
            this.tableData = res.data
            this.loading = false
            this.page = this.nxtPage
            this.total = res.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSelectionChange(val) {
      this.ids = []
      val.forEach((item) => {
        this.ids.push(item.id)
      })
    },
  },
  created() {
    this.loadTable()
    LocalConfig.loadDiskUseUnitsFromConfig(true).then((result) => (this.organizations = result))
  },
  watch: {},
  updated() {},
  computed: {
    containerStyle() {
      return { height: document.body.clientHeight + 'px' }
    },
  },
}
</script>
<style scoped>
#tableArea td .cell {
  display: block; /* 需要 */
  white-space: nowrap; /* 过长部分不换行 */
  text-overflow: ellipsis; /* 过长部分展示省略号 */
  overflow: hidden; /* 隐藏过长部分 */
}
.el-select .el-input {
  width: 50px;
}
.input-with-select .el-input-group__append {
  background-color: #fff;
}
</style>
