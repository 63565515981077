import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //more like menus
    routers: [],
    project: {
      id: '',
      proname: '',
      manager: '',
      managerTel: '',
      mapPath: '',
      fromDate: '',
      toDate: '',
      memo: '',
      monitorUnit: '',
      ownerUnit: '',
      workUnit: '',
      x: '',
      y: '',
      overview: '', //工程概况
      address: '', //项目地址
      surveyUnit: '', //勘察单位
      designUnit: '', //设计单位
      devices: '', //仪器名称
    },
    readonly: null,
    single: {
      documents: null,
      current: null,
    },
  },
  //改变state里面对应值的方法
  mutations: {
    initRoutes(state, data) {
      state.routers = data
    },
    setOperate(state, data) {
      state.readonly = data
    },
    setSingle(state, data) {
      state.single = data
    },
  },
  actions: {
    //加入业务逻辑
    saveProject({ commit, state }, data) {
      //{commit,state}:contex
      commit('saveProject', { data })
    },
  },
})

//$store.dispatch('action名称',data)
//$store.commit('mutations方法名',data)
