<template>
  <div style="width: 600px;height: 500px;overflow-y: scroll">
    <el-tree
        :data="treeData.children"
        node-key="id"
        :props="defaultProps"
        @node-click="nodeClicked"
        :expand-on-click-node="true">
    </el-tree>
  </div>
</template>
<script>
export default {
  name: 'Institution',
  data() {
    return {
      treeData: {}, //申请或来源单位的树形结构数据
      rootId: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  methods: {
    nodeClicked(data,node){
      this.$emit("pickedName",{name:data.name})
    },
    loadData() {
      this.getRequest('/server/institution/').then((resp) => {
        if (resp) {
          this.treeData = resp
          this.rootId = resp.id
        }
      })
    },
    loadDataLazy(node,resolve) {  //懒加载数据
      let requestUrl ="";
      if(node.level ===0){
        requestUrl ="/server/region/000000";
      }
      if(requestUrl !=""){
        this.getRequest('/server/institution/').then((resp) => {
          if (resp) {
            this.treeData = resp
            this.rootId = resp.id
          }
        })
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.addInstitutionInput {
  width: 300px;
  margin-right: 10px;
}
.custom-tree-node{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
