<template>
  <el-form
    :model="editForm"
    size="mini"
    :label-width="formLabelWidth"
    :disabled="disabled"
    :rules="rules"
    ref="editForm"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item label="分发编号" prop="issueNum">
          <el-input v-model="editForm.issueNum" style="width: 100%" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="生产编号" prop="produces">
          <el-select
            v-model="editForm.produces"
            multiple
            placeholder="请选择"
            style="width: 100%"
            @change="handleJobIdsChange"
            @visible-change="updateProduceData"
          >
            <el-option
              v-for="item in produceInstitutions"
              :key="item.id"
              :label="item.dataNum"
              :value="item.id"
              style="width: 100%"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="重复分发">
          <el-checkbox v-model="editForm.isRepeat" v-if="!editForm.isRepeat"></el-checkbox>
          <el-input
            placeholder="请填写重复分发原因"
            v-model="editForm.repeatReason"
            class="inputWithCheckbox"
            v-if="editForm.isRepeat"
            prop="repeatReason"
          >
            <el-checkbox slot="prepend" v-model="editForm.isRepeat"></el-checkbox>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="1">
        <div style="float: right">
          <el-button type="danger" @click="deleteIt()" :disabled="disabled" icon="el-icon-delete" circle></el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label="分发时间" prop="issueDate">
          <el-date-picker
            v-model="editForm.issueDate"
            type="date"
            placeholder="请选择时间"
            format="yyyy年M月d日"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            :picker-options="pickerOptions"
            @focus="
              (event) => {
                prepareDate(event, editForm)
              }
            "
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="服务方式">
          <el-select v-model="editForm.serveType" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in serveTypes" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="领取人" prop="usingReceiver">
          <el-input v-model="editForm.usingReceiver" autocomplete="off" style="width: 100%" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <span class="dialog-footer span-center" v-if="showUpdateButtons && !disabled">
        <el-button type="primary" @click="submitForm()">{{ editForm.id ? '更新' : '保存' }}</el-button>
        <el-button>返回</el-button>
      </span>
    </el-row>
  </el-form>
</template>

<script>
import moment from 'moment'
import { Loading } from 'element-ui'
export default {
  name: 'Distribute',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showUpdateButtons: {
      type: Boolean,
      default: true,
    },
    produceInstitutions: [],
    serveTypes: [],
    model: {},
  },
  data() {
    return {
      editForm: {},
      formLabelWidth: '100px',
      rules: {
        repeatReason: [{ max: 100, message: '重复分发原因最大长度100个字符', trigger: 'blur' }],
        issueNum: [{ required: true, message: '请填写分发编号', trigger: 'blur' }],
        issueDate: [{ required: true, message: '请填写分发日期', trigger: 'blur' }],
        usingReceiver: [
          { required: true, message: '请填写领取人', trigger: 'blur' },
          { max: 30, message: '领取人最大长度30个字符', trigger: 'blur' },
        ],
        produces: [
          {
            validator: this.jobIdsValidator,
            trigger: 'blur',
          },
        ],
      },
      startTime: null,
    }
  },
  methods: {
    jobIdsValidator(rule, value, callback) {
      let invalid = false
      this.editForm.produces.some((id, index) => {
        let result = this.produceInstitutions.filter((pi) => {
          return pi.id == id
        })
        if (result.length == 0) {
          callback(new Error('包含过期的生产数据'))
          return true
        }
      })
      callback()
    },
    resetFields() {
      this.$refs['editForm'].resetFields()
    },
    validate() {
      let result = false
      this.$refs['editForm'].validate((valid) => {
        result = valid
      })
      return result
    },
    submitForm() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          //error submit!!')
          return false
        }
      })
    },
    updateProduceData() {
      this.$emit('notifyUpdateProduceData')
    },
    deleteIt() {
      let data = this.editForm
      this.$confirm('此操作将删除分发记录（' + data.issueNum + '）, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (data.id) {
            this.deleteRequest('/server/data-issue/' + data.id).then((res) => {
              if (res) {
                this.$emit('notifyDeleted', data.id)
              }
            })
          } else {
            this.$emit('notifyDeleted')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    save() {
      let editForm = this.editForm
      let requestData = JSON.parse(JSON.stringify(editForm))
      let request = editForm.id ? this.putRequest : this.postRequest
      request('/server/data-issue/', requestData)
        .then((res) => {
          if (res && res.code === 200) {
            if (!editForm.id) {
              editForm.id = res.obj
              this.$emit('notifyCreated', editForm)
            }
          }
        })
        .catch((err) => {})
    },
    handleJobIdsChange(value) {},
    prepareDate(event) {
      let editForm = this.editForm
      setTimeout(() => {
        if (this.datePickerLoadingInstance) {
          this.datePickerLoadingInstance.close()
        }
        //        console.log(document.querySelectorAll('.el-date-picker[style*="position"]').length)
        this.datePickerLoadingInstance = Loading.service({ target: '.el-date-picker[style*="position"]' })
        this.getProduceEndTime(editForm.produces).then((result) => this.datePickerLoadingInstance.close())
      }, 200)
    },
    async getProduceEndTime(ids) {
      if (ids && ids.length == 0) {
        return null
      }
      let result = null
      await this.getRequest('/server/produce/maxProduceEndDate4DataIssue', { idsString: ids.join() })
        .then((res) => {
          result = res ? res : null
        })
        .catch((err) => {
          console.log(err)
        })
      this.startTime = result
      return result
    },
  },
  created() {
    if (this.model) {
      this.editForm = this.model
    }
  },
  mounted() {},
  updated() {},
  watch: {
    model: function (val, oldVal) {
      if (val) {
        this.editForm = this.model
      }
    },
  },
  computed: {
    pickerOptions() {
      let that = this
      return {
        disabledDate(time) {
          if (that.startTime) {
            // console.log(
            //   moment(time).format('YYYY-MM-DD'),
            //   moment(time).isBefore(moment(that.startTime)),
            //   moment(time).isSameOrAfter(moment(new Date()))
            // )
            return moment(time).isBefore(moment(that.startTime)) || moment(time).isSameOrAfter(moment(new Date()))
          } else {
            moment(time).isSameOrAfter(moment(new Date()))
          }
        },
      }
    },
  },
}
</script>

<style scoped>
/* .demo-table-expand {
  font-size: 0;
  margin-left: 155px;
} */
.span-center {
  width: 100%;
  text-align: center;
  display: block;
}
/* #editDiv .width100 .el-form-item {
  width: 100%;
}
.width100 .el-button {
  margin-left: 10px;
  margin-top: 10px;
} */
/* #editDiv,
.recordDiv {
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding: 10px 10px 0px 10px;
  margin-left: 5px;
}
#editDiv {
  background-color: #fcbdb0;
  padding-bottom: 10px;
}
.recordDiv {
  background-color: #e5e5e5;
} */
</style>
<style>
.inputWithCheckbox .el-input-group__prepend {
  background-color: transparent;
  width: auto;
  padding: 0px;
}
/* .demo-table-expand label {
  width: 100px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.demo-table-expand .width100 .el-form-item {
  width: 25%;
} */
/* .f1f2f6 .el-divider__text {
  background-color: #f4f4f4;
}
.fadfc7 .el-divider__text {
  background-color: #fadfc7;
} */
</style>
