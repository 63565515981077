<template>
  <div>
    <el-dialog
      :visible.sync="editFormVisible"
      v-if="!$store.state.readonly"
      @close="onClose"
      @opened="onOpened"
      :title="(serviceId ? (readonly ? '查看备案' : '编辑备案') : '新建备案') + recordNum"
      :append-to-body="true"
      width="90%"
    >
      <TabForms
        :serviceId="serviceId"
        :stage="currentNodeValue"
        :recordNum="recordNum"
        ref="tabs"
        :readonly="readonly"
        @mark-reload="markReload = true"
        @notifyRecordNumUpdate="updateRecordNum"
        @notifyStageUpdate="updateStage"
        @notifyServiceIdUpdate="updateServiceId"
      />
    </el-dialog>
    <el-dialog
      :visible.sync="readonlyFormVisible"
      :title="'查看备案' + recordNum"
      append-to-body
      width="90%"
      @close="resetServiceId"
    >
      <RecordDetail :serviceId="serviceId" :recordNum="recordNum" @close="onClose" />
      <template #footer>
        <span class="dialog-footer span-center">
          <el-button type="primary" @click="readonlyFormVisible = false">返回</el-button>
        </span>
      </template>
    </el-dialog>
    <div id="searchPane">
      <el-form :model="searchForm">
        <el-form-item label="备案编号">
          <el-col :span="3">
            <el-input v-model="searchForm.recordNum" :clearable="true" />
          </el-col>
          <el-col :span="2" class="el-form-item__label">
            <span class="text-gray-500">使用单位</span>
          </el-col>
          <el-col :span="3">
            <el-select
              v-model="searchForm.useUnit"
              clearable
              allow-create
              filterable
              multiple
              :collapse-tags="true"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option v-for="org in organizations" :key="org.id" :label="org.value" :value="org.id" />
            </el-select>
          </el-col>
          <el-col :span="2" class="el-form-item__label">
            <span class="text-gray-500">数据名称</span>
          </el-col>
          <el-col :span="3">
            <el-input v-model="searchForm.dataName" autocomplete="off" clearable />
          </el-col>
          <el-col :span="2" class="el-form-item__label">
            <span class="text-gray-500">当前备案状态</span>
          </el-col>
          <el-col :span="3">
            <el-select v-model="searchForm.currentNode" placeholder="请选择">
              <el-option v-for="bazt in recStatus" :key="bazt.id" :label="bazt.name" :value="bazt.id" />
            </el-select>
          </el-col>
          <el-col :span="2">
            <span style="width: 10px; display: inline-block"></span>
            <el-button type="primary" icon="el-icon-search" @click="reloadTable()">查询</el-button>
          </el-col>
          <span style="float: right">
            <span class="witch5px"></span>
            <el-button type="success" icon="el-icon-plus" @click="create()" v-if="!$store.state.readonly">
              新增
            </el-button>
            <!-- <el-button type="danger" v-if="multipleSelection.length > 0" @click="patchDelete()"> 批量删除</el-button> -->
          </span>
        </el-form-item>
      </el-form>
    </div>
    <div id="tableArea">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :cell-style="cellStyle"
        @cell-click="cellClick"
        :span-method="spanMethod"
        ref="table"
      >
        <el-table-column type="selection" align="center" />
        <el-table-column prop="xh" type="index" label="序号" width="45" align="center">
          <template slot-scope="scope">
            <span>{{ (page - 1) * pageSize + serverIds.indexOf(scope.row.id) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="recordNum" label="备案编号" width="95" align="center" />
        <el-table-column prop="serveYear" label="服务年份" width="50" align="center" />
        <el-table-column prop="fileNum" label="收文编号" width="80" align="center" />
        <el-table-column prop="usingUnitText" label="使用单位" header-align="center" show-overflow-tooltip />
        <el-table-column prop="dataUse" label="申请用途" header-align="center" show-overflow-tooltip />
        <el-table-column prop="dataName" label="数据名称" header-align="center" show-overflow-tooltip />
        <el-table-column prop="distributeTimes" label="服务次数" width="50" align="center" />
        <el-table-column prop="dataSize" label="数据量" width="95" align="center" show-overflow-tooltip />
        <el-table-column prop="distributed" label="是否分发" width="50" :formatter="formatBoolean" align="center" />
        <el-table-column prop="nodeName" label="服务状态" width="85" align="center" />
        <el-table-column prop="isFeedback" label="是否反馈" width="50" :formatter="formatBoolean" align="center" />
        <el-table-column label="操作" width="160px" align="center" v-if="!$store.state.readonly">
          <template #default="scope">
            <el-button
              type="text"
              v-if="scope.row.nodeMemo && scope.row.nodeMemo.includes('delete')"
              @click="promptIt([scope.row.id])"
            >
              删除
            </el-button>
            <el-button
              type="text"
              v-if="scope.row.nodeMemo && scope.row.nodeMemo.includes('edit')"
              @click="editIt(scope.row)"
            >
              编辑
            </el-button>
            <!-- <el-button type="text" v-if="scope.row.nodeMemo.includes('check')"> 提交审核 </el-button> -->
            <el-button
              type="text"
              v-if="scope.row.nodeMemo && scope.row.nodeMemo.includes('end') && !scope.row.nodeMemo.includes('append')"
              @click="endIt(scope.row)"
            >
              终止</el-button
            >
            <!-- <el-button type="text" v-if="scope.row.nodeMemo.includes('revoke')"> 撤回审核 </el-button> -->
            <el-button
              type="text"
              v-if="scope.row.nodeMemo && scope.row.nodeMemo.includes('done')"
              @click="doneIt(scope.row)"
            >
              完成
            </el-button>
            <el-button
              type="text"
              v-if="scope.row.nodeMemo && scope.row.nodeMemo.includes('append')"
              @click="editIt(scope.row)"
            >
              补充
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div id="tableFooter">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import TabForms from '@/components/business/TabForms.vue'
import RecordDetail from '@/components/business/RecordDetail.vue'

export default {
  name: 'Service',
  components: {
    TabForms,
    RecordDetail,
  },
  data() {
    return {
      loading: false,
      readonly: true,
      markReload: false,
      tableData: [],
      serverIds: [],
      searchForm: {
        currentNode: null,
      },
      organizations: [],
      recStatus: [],
      //   currentTab: 'rws',
      editFormVisible: false,
      readonlyFormVisible: false,
      serviceId: '',
      recordNum: '',
      currentNodeValue: 1,
      page: 1,
      pageSize: 10,
      nxtPage: 1,
      total: 1,
      multipleSelection: [],
    }
  },
  methods: {
    updateRecordNum(val) {
      this.recordNum = val
    },
    updateStage(val) {
      this.currentNodeValue = val
    },
    updateServiceId(val) {
      this.serviceId = val
    },
    reloadTable() {
      this.nxtPage = 1
      this.loadTable()
    },
    loadTable() {
      this.loading = true
      let requestData = JSON.parse(JSON.stringify(this.searchForm))
      requestData.useUnits = requestData.useUnit ? requestData.useUnit.join(',') : null
      requestData.useUnit = null
      requestData.currentPage = this.nxtPage
      requestData.pageSize = this.pageSize
      this.getRequest('/server/record/ledger', requestData)
        .then((res) => {
          if (res && res.data) {
            this.tableData = res.data
            this.loading = false
            this.page = this.nxtPage
            this.total = res.total
            this.serverIds = []
            if (this.tableData.length == 0) {
              return
            }
            let number = this.tableData[0].recordNum
            let unit = this.tableData[0].usingUnitText
            let numberLen = 1
            let unitLen = 1
            let i
            this.prepareDataSize(this.tableData)

            this.tableData.forEach((row, index) => {
              if (index == 0) {
                number = row.recordNum
                unit = row.usingUnitText
                row.unitspan = 1
              } else {
                if (row.recordNum == number) {
                  //同一大行记录
                  numberLen++
                  if (row.usingUnitText == unit) {
                    //同一大行单位
                    unitLen++
                  } else {
                    //另一个单位开始
                    for (i = 1; i <= unitLen; i++) {
                      this.tableData[index - i].unitspan = 0
                    }
                    this.tableData[index - unitLen].unitspan = unitLen
                    unit = row.usingUnitText
                    unitLen = 1
                  }
                } else {
                  //新备案开始，统计上一个备案最后一个使用单位出现的次数
                  for (i = 1; i <= unitLen; i++) {
                    this.tableData[index - i].unitspan = 0
                  }
                  this.tableData[index - unitLen].unitspan = unitLen
                  unit = row.usingUnitText
                  unitLen = 1

                  number = row.recordNum
                }
              }
              if (!this.serverIds.includes(row.id)) {
                this.serverIds.push(row.id)
              }
            })

            //本页最后一个单位
            let index = this.tableData.length
            if (index >= unitLen) {
              for (i = 1; i < unitLen; i++) {
                this.tableData[index - i].unitspan = 0
              }
              this.tableData[index - unitLen].unitspan = unitLen
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    prepareDataSize(tableData) {
      tableData.forEach((row) => {
        if (row.dataSize) {
          row.dataSize = LocalConfig.parseDataSize(Number(row.dataSize))
        }
      })
    },
    create() {
      this.editIt(null)
    },
    editIt(row) {
      //this.readonly = false
      this.serviceId = row ? row.id : ''
      this.recordNum = row ? row.recordNum : ''
      this.currentNodeValue = row ? row.currentNodeValue : 1
      if (this.recordNum === '' || this.recordNum === null) {
        this.getRequest('/server/record/record-num')
          .then((data) => {
            if (data) {
              this.recordNum = data
              this.editFormVisible = true
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.editFormVisible = true
      }
    },
    patchDelete() {
      alert('server not yet')
      //this.deleteIt(this.multipleSelection)
    },

    promptIt(ids) {
      this.prompt('是否真的删除记录?', () => {
        this.deleteIt(ids)
      })
    },
    prompt(message, callback) {
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          callback()
        })
        .catch((ex) => {
          console.log(ex)
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    deleteIt(ids) {
      let callback = this.loadTable
      this.deleteRequest('/server/record/physically/' + ids[0], ids).then((res) => {
        if (res && res.code === 200) {
          callback()
        }
      })
    },
    checkIt(row) {},
    revokeIt(row) {},
    endIt(row) {
      this.prompt('是否真的终止记录?', () => {
        LocalConfig.changeStatus(row.id, -1).then((result) => {
          if (result) {
            //this.row.stage = 5
            this.loadTable()
          }
        })
      })
    },
    doneIt(row) {
      this.prompt('是否真的完成备案?', () => {
        LocalConfig.changeStatus(row.id, 4).then((result) => {
          if (result) {
            this.loadTable()
          }
        })
      })
    },
    appendIt(row) {},
    handleCurrentChange(val) {
      this.nxtPage = val
      this.loadTable()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => item.id)
    },
    cellStyle({ row, columnIndex }) {
      if (columnIndex === 2) {
        return 'color : #2659c4;cursor:pointer;'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '备案编号') {
        this.serviceId = row.id
        this.recordNum = row.recordNum
        this.readonlyFormVisible = true
        //this.readonly = true
      }
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      let same = this.$refs.table.data.filter((r) => {
        return r.id === row.id
      })
      //不止一行
      if (same.length > 1) {
        if (columnIndex === 7) {
          return {
            rowspan: 1,
            colspan: 1,
          }
        } else if (
          columnIndex === 5 ||
          columnIndex === 6 ||
          columnIndex === 8 ||
          columnIndex === 9 ||
          columnIndex === 10
        ) {
          return {
            rowspan: row.unitspan,
            colspan: 1,
          }
        }
        if (
          rowIndex == 0 ||
          (this.$refs.table.data[rowIndex - 1] && row.id != this.$refs.table.data[rowIndex - 1].id)
        ) {
          return {
            rowspan: same.length,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    onClose() {
      if (true) {
        //if (this.markReload) { //为保证及时更新，忽略效率
        this.loadTable()
        this.markReload = false
      }
      this.resetServiceId()
    },
    resetServiceId() {
      this.serviceId = undefined
      this.recordNum = undefined
    },
    onOpened() {},
    formatBoolean(row, column, cellValue) {
      return cellValue ? '是' : '否'
    },
  },
  disableAllTab() {
    this.readonly = true
  },
  created() {
    this.readonly = this.$store.state.readonly
    this.loadTable()
    LocalConfig.loadOrganizationsFromConfig(true).then((result) => (this.organizations = result))
    LocalConfig.loadRecordStatusFromConfig(true).then((result) => {
      this.recStatus = result
    })

    LocalConfig.loadDistrictsFromConfig(true)
    LocalConfig.loadProduceDepartmentsFromConfig(true)
  },
  mounted() {},
}

/*
区别
没有ts 不支持接口
没有ref
element ui 而非element plus
加包装
加this
*/
</script>

<style scoped>
#searchPane {
  padding: 0.5em 1em;
  background-color: white;
}
#tableArea {
  margin: 0.5em 0em;
}
#tableFooter {
  text-align: center;
  background: white;
  margin: 0.5em 0em;
  padding: 0.5em;
}
</style>
<style>
html,
body {
  background-color: #eaedf0;
}
.el-table th.el-table__cell {
  background-color: #f6faff;
}
</style>
