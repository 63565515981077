<template>
  <div>
    <el-button size="mini" type="primary" @click="inputDialogVisible = true" v-show="false">点击上传</el-button>
    <el-upload
      action="#"
      :http-request="upload"
      :file-list="fileList"
      :headers="headers"
      :data="document"
      :disabled="disabled"
      :before-upload="beforeUpload"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :show-file-list="showFileList"
      ref="uploadFile"
    >
      <el-button size="mini" type="primary" v-show="showButton" ref="uploadBtn">{{ text }}</el-button>
      <div slot="tip" class="el-upload__tip" v-show="false">文件大小不超过10MB</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="inputDialogVisible" append-to-body width="400px" title="编辑附件">
      <el-form :model="document" size="mini" label-width="65px">
        <el-form-item label="材料编号">
          <el-input v-model="document.number" autocomplete="off" />
        </el-form-item>
        <el-form-item label="材料名称">
          <el-input v-model="document.name" autocomplete="off" placeholder="留空使用文件名" />
        </el-form-item>
      </el-form>
      <span class="dialog-footer span-center">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
export default {
  name: 'UploadItem',
  components: {},
  props: {
    referenceId: {
      type: String,
      default: undefined,
    },
    tab: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '上传',
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    successCallback: {
      type: Function,
      default: () => {},
    },
    reloadCallBack: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem('tokenStr'),
      },
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      inputDialogVisible: false,
      document: {},
    }
  },
  methods: {
    upload(param) {
      const formData = new FormData()
      formData.append('file', param.file)
      const document = new Blob([JSON.stringify(this.document)], {
        type: 'application/json',
      })
      formData.append('document', document)
      this.postRequest('/server/document/upload', formData)
        .then((data) => {
          if (data && data.code === 200) {
            this.fileList.push(data.obj)
            if (this.successCallback) {
              this.successCallback(data.obj)
              this.reloadCallBack(this.fileList)
            }
          } else {
            throw 'uploadFail'
          }
        })
        .catch((err) => {
          //this.$message.error('上传失败')
          console.log(err)
          //remove err file at ui
          let uid = param.file.uid
          let idx = this.$refs.uploadFile.uploadFiles.findIndex((item) => item.uid === uid)
          this.$refs.uploadFile.uploadFiles.splice(idx, 1)
        })
    },
    close() {
      this.document.number = ''
      this.document.name = ''
      this.inputDialogVisible = false
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过10MB!')
      }
      return isLt10M
    },
    save() {
      this.inputDialogVisible = false
      this.$refs.uploadBtn.$el.click()
    },
    handleRemove(file) {
      //console.log(file)
    },
    handlePreview(file) {
      //document.location.href = '/documents/' + file.path
      window.open('/documents/' + file.path)
    },
    handleDownload(file) {
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.download = file.originalName
      a.href = '/documents/' + file.path
      a.dispatchEvent(event)
    },
    loadFiles(oldFileList) {
      if (!this.referenceId || !this.tab) {
        return
      }
      //不显示列表则不去加载
      //   if (!this.showoldfileList) {
      //     return
      //   }

      //被动刷新
      if (oldFileList && JSON.stringify(oldFileList) == JSON.stringify(this.fileList)) {
        return
      }

      this.document.pageSize = 10000
      this.document.type = this.type
      this.getRequest('/server/document/page', this.document).then((res) => {
        this.fileList = res.data
        if (!oldFileList) {
          this.reloadCallBack(this.fileList)
        }
      })
    },
    beforeRemove(file, fileList) {
      //don't promote when remove failed file at ui
      if (file && file.status == 'success') {
        return this.$confirm(`确定移除 ${file.name}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
      }
    },
    handleRemove(file, fileList) {
      //判断已经上传的还是上传失败的或者未上传的
      if (file && file.status != 'success') {
        return
      }

      this.deleteRequest('/server/document/' + file.id).then((res) => {
        if (res) {
          this.loadFiles()
        }
      })
    },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url
    //   this.dialogVisible = true
    // },
  },
  created() {
    this.$set(this.document, 'referenceId', this.referenceId)
    this.$set(this.document, 'tab', this.tab)
    this.$set(this.document, 'number', null)
    this.$set(this.document, 'name', null)
    this.loadFiles()
  },
  watch: {
    referenceId: function (val, oldVal) {
      this.$set(this.document, 'recordId', val)
      this.$set(this.document, 'number', null)
      this.$set(this.document, 'name', null)
      this.fileList = []
      if (val) {
        //wait tab
        setTimeout(() => {
          this.loadFiles()
        }, 150)
      }
    },
    tab: function (val, oldVal) {
      this.$set(this.document, 'tab', val)
    },
  },
}
</script>

<style lang="scss">
.el-upload {
  display: flex;
}
.el-upload-list {
  display: flex;
  flex-wrap: wrap;
}
.el-upload-list li {
  width: auto;
  margin-right: 35px;
  font-size: 12px;
}
.el-upload-list__item:first-child {
  margin-top: 5px;
}
.el-upload-list__item-name {
  margin-right: 30px;
}
.el-upload-list__item.is-success:focus:not(:hover) i.el-icon-close-tip {
  display: none;
}
.el-upload-list__item.is-ready,
.el-upload-list__item.is-uploading {
  position: relative;
}
.el-list-enter-active,
.el-list-leave-active {
  transition: none;
}
.el-list-enter,
.el-list-leave-active {
  opacity: 0;
}
</style>
