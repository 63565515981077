<template>
  <el-tabs v-model="currentTab" @tab-click="handleTab" style="background-color: #fff" id="rootTab">
    <el-tab-pane v-for="item in tabItems" :key="item.value" :label="item.label" :name="item.value" :lazy="true">
      <component
        :is="item.vue"
        :serviceId="serviceId"
        :recordNum="recordNum"
        :stage="stage"
        @notifyReload="notifyReload"
        @notifyStageUpdate="updateStage"
        @notifyRecordNumUpdate="updateRecordNum"
        @notifyServiceIdUpdate="updateServiceId"
        @notifyCloseDialog="closeDialog"
        :disabled="readonly ? true : false"
      ></component>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { defaultTabForm } from '@/utils/base'
import TaskForm from '@/components/business/TaskForm.vue'
import DataForm from '@/components/business/DataForm.vue'
import DistributeForm from '@/components/business/DistributeForm.vue'
import FeedbackForm from '@/components/business/FeedbackForm.vue'

export default {
  name: 'TabForms',
  components: {
    TaskForm,
    DataForm,
    DistributeForm,
    FeedbackForm,
  },
  props: {
    serviceId: {
      type: String,
      default: undefined,
    },
    recordNum: {
      type: String,
      default: '',
    },
    documents: {
      type: Array,
      default: function () {
        return JSON.parse(JSON.stringify(defaultTabForm))
      },
    },
    docId: {
      type: String,
      default: 'RWS',
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    stage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tabItems: [],
      currentTab: this.docId ? this.docId : 'RWS',
      documentIds: this.documents ? this.documents.map((item) => item.value) : [],
    }
  },
  methods: {
    handleTab(tab, event) {
      //数据生产单和数据分发单的更新问题，自动更新可能会影响编辑情况；不更新的话可能删除后会不同步，可能性不大。
    },
    notifyReload() {
      this.$emit('mark-reload')
    },
    updateStage(val) {
      this.$emit('notifyStageUpdate', val)
    },
    updateRecordNum(val) {
      this.$emit('notifyRecordNumUpdate', val)
    },
    updateServiceId(val) {
      this.$emit('notifyServiceIdUpdate', val)
    },
    loadBlankData() {},
    clearForms() {
      //for next
      this.currentTab = 'RWS'
    },
    findComponentUpward(context, componentName) {
      let parent = context.$parent
      let name = parent.$options.name
      while (parent && (!name || [componentName].indexOf(name) < 0)) {
        parent = parent.$parent
        if (parent) name = parent.$options.name
      }
      return parent
    },
    closeDialog() {
      const dialog = this.findComponentUpward(this, 'ElDialog')
      if (dialog) {
        dialog.$emit('update:visible', false)
      } else {
        this.$router.replace('/home/default')
      }
    },
    loadData() {},
    refresh() {
      if (this.serviceId === undefined) {
        //('1::clear')
        this.clearForms()
      } else if (this.serviceId && this.serviceId != '') {
        //log('2::loadData')
        this.loadData()
      } else {
        //log('3::loadBlankData::' + this.serviceId)
        this.loadBlankData()
      }
    },
  },
  created() {
    //for tabForm use alone
    //this.loadBlankData()
  },
  mounted() {
    if (this.documents) {
      this.documents.forEach((doc) => {
        this.tabItems.push(doc)
      })
    }
  },
  computed: {},
  watch: {
    serviceId: {
      handler(val, old) {
        this.refresh()
      },
      immediate: true,
    },
    docId: function (val, oldVal) {
      if (this.docId) {
        this.currentTab = this.docId
      }
    },
    '$store.state.single.current': function (val, oldVal) {
    },
  },
}
</script>

<style scoped></style>

<style></style>
