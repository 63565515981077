<template>
  <div ref="main" id="main"></div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import * as echarts from 'echarts/core'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

export default {
  name: 'DataTypeStatistic',
  components: {},
  props: {
    statisticType: {
      type: String,
      default: 'times',
    },
    searchParam: {
      type: Object,
      default: () => ({
        type: 'times',
        target: 'dataType',
      }),
    },
  },
  data() {
    return {
      myChart: null,
      option: {
        title: {
          text: '{black|}{white|}数据类型（次）',
          // subtext: 'Data from Wikipedia',
          textStyle: {
            fontSize: 14,
            rich: {
              black: {
                width: 3,
                borderRadius: 5,
                backgroundColor: '#6D6D6D',
              },
              white: {
                width: 6,
                backgroundColor: '#FFF',
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} {c}次<br>占比{d}%',
        },
        //   legend: {
        //     orient: 'vertical',
        //     left: 'left',
        //   },
        series: [
          {
            type: 'pie',
            radius: ['30%', '45%'],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            label: {
              formatter: '{value|{b} {c}}',
              minMargin: 5,
              rich: {
                value: {
                  color: 'inherit',
                },
              },
            },
          },
        ],
      },
    }
  },
  methods: {
    genChart() {
      if (this.myChart) {
        this.myChart.dispose()
      }
      let chartDom = this.$refs['main']
      this.myChart = echarts.init(chartDom)
      this.myChart.showLoading()
      this.searchParam.type = this.statisticType
      this.searchParam.target = 'dataType'
      let requestData = JSON.parse(JSON.stringify(this.searchParam))
      this.postRequest('/server/data-issue/statistic', requestData)
        .then(async (res) => {
          if (res && res.obj) {
            this.option.series[0].data = []

            let result = await LocalConfig.loadDataTypesFromConfig(true)
            result.forEach((dataFormat) => {
              this.option.series[0].data.push({ name: dataFormat.name, value: 0 })
            })

            let dataSize = 0,
              dataMulti = 1,
              dataUnit = 'KB'
            if (this.statisticType == 'size') {
              res.obj.forEach((row) => {
                if (row.value && dataSize < Number(row.value)) {
                  dataSize = Number(row.value)
                  let result = LocalConfig.parseDataMulti(dataSize)
                  dataMulti = result[0]
                  dataUnit = result[1]
                }
              })
              this.option.title.text = '数据类型(' + dataUnit + ')'
            }
            res.obj.forEach((row) => {
              this.option.series[0].data.some((value, index) => {
                if (value.name === row.name) {
                  if (this.statisticType == 'size') {
                    this.option.series[0].data[index].value = (row.value / dataMulti).toFixed(2)
                  } else {
                    this.option.series[0].data[index].value = row.value
                  }
                  return true
                }
              })
              //   if (this.statisticType == 'size') {
              //     this.option.series[0].data.push({
              //       value: (row.value / dataMulti).toFixed(2),
              //       name: row.name,
              //     })
              //   } else {
              //     this.option.series[0].data.push({
              //       value: row.value,
              //       name: row.name,
              //     })
              //   }
            })
            this.myChart.hideLoading()
            this.option && this.myChart.setOption(this.option)
          }
        })
        .catch((err) => {
          this.myChart.hideLoading()
        })
    },
  },
  created() {
    echarts.use([TitleComponent, TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout])
  },
  mounted() {
    this.genChart()
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.myChart.resize()
      }, 150)
    })
  },
}
</script>

<style></style>
