/*
加载菜单组件
* */

import { getRequest } from '@/utils/api'

export const initMenu = (router, store) => {
  if (store.state.routers.length > 0) {
    return
  }

  getRequest('/system/basic/menu/').then((data) => {
    if (data) {
      //格式化routers
      let fmtRouters = formatRoutes(data)
      //添加到router
      if (store.state.routers.length == 0) {
        fmtRouters.forEach((rt) => {
          router.addRoute('主页面', rt)
          //router.options.routes.push(rt)
        })
      }
      //在store，vuex中保持
      if (store.state.routers.length == 0) {
        store.commit('initRoutes', fmtRouters)
      }
    }
  })
}

//格式化从数据库返回的路由数据
export const formatRoutes = (routes) => {
  let fmtRouters = []
  routes.forEach((router) => {
    let { path, component, name, icon, children, hidden } = router
    if (children && children instanceof Array) {
      //递归调用
      children = formatRoutes(children)
    }
    let fmtRouter = {
      path: path,
      name: name,
      icon: icon,
      children: children,
      hidden: hidden,
      component(resolve) {
        if (component.startsWith('Sys')) {
          require(['@/views/sys/' + component + '.vue'], resolve)
        } else {
          require(['@/views/default/' + component + '.vue'], resolve)
        }
      },
    }
    fmtRouters.push(fmtRouter)
  })
  return fmtRouters
}
