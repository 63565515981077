import Vue from 'vue'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './assets/theme/index.css'
import App from './App.vue'
import router from './router'
import { postRequest } from '@/utils/api'
import { getRequest } from '@/utils/api'
import { putRequest } from '@/utils/api'
import { deleteRequest } from '@/utils/api'
import store from '@/store'
import { initMenu } from '@/utils/menus'
import 'font-awesome/css/font-awesome.css'
Vue.use(ElementUI, {
  size: 'mini', // set element-ui default size
})
//以插件的形式使用自定义对象
Vue.prototype.postRequest = postRequest
Vue.prototype.getRequest = getRequest
Vue.prototype.putRequest = putRequest
Vue.prototype.deleteRequest = deleteRequest

Date.prototype.Format = function (fmt) {
  let o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  return fmt
}

//路由导航守卫
router.beforeEach((to, from, next) => {
  if (window.sessionStorage.getItem('tokenStr')) {
    initMenu(router, store) //初始化菜单
    // if(!window.sessionStorage.getItem("user")){
    //     return getRequest('/adminInfo').then(resp=>{
    //         if(resp){
    //             window.sessionStorage.setItem('user',JSON.stringify(resp));
    //         }
    //     })
    // }
    next()
  } else {
    if (to.path == '/') {
      next()
    } else {
      next('/?redirect=' + to.path)
    }
  }
})
//关闭控制台关于环境的提示
Vue.config.productionTip = false

export const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app') //手动挂载
