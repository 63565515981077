import { getRequest, postRequest } from '@/utils/api'
import { vm } from '@/main'

//运行时vm尚未初始化完成，所有仍需导入api中请求方法，而无法用vm调用

let config = {}
export const organization = [
  {
    label: '自然资源部内部数据服务备案',
    value: 'ZYZYB',
    documents: [
      { value: 'RWS', label: '任务书', vue: 'TaskForm' },
      { value: 'XYS', label: '协议书', fileAt: 'RWS' },
      { value: 'SJSCD', label: '数据生产单', vue: 'DataForm' },
      { value: 'FFD', label: '数据分发单', fileAt: 'FKB' },
      { value: 'FKB', label: '用户使用反馈表', vue: 'FeedbackForm' },
    ],
  },
  {
    label: '其他部委数据服务备案',
    value: 'QTBW',
    documents: [
      { value: 'RWS', label: '任务书', vue: 'TaskForm' },
      { value: 'XYS', label: '协议书', fileAt: 'RWS' },
      { value: 'BH', label: '便函', fileAt: 'RWS' },
      { value: 'SJSCD', label: '数据生产单', vue: 'DataForm' },
      { value: 'FFD', label: '数据分发单', fileAt: 'FKB' },
      { value: 'FKB', label: '用户使用反馈表', vue: 'FeedbackForm' },
    ],
  },
  {
    label: '省级单位数据服务备案',
    value: 'DFSY',
    documents: [
      { value: 'RWS', label: '任务书', vue: 'TaskForm' },
      { value: 'XYS', label: '协议书', fileAt: 'RWS' },
      { value: 'BH', label: '便函', fileAt: 'RWS' },
      { value: 'SJSCD', label: '数据生产单', vue: 'DataForm' },
      { value: 'FFD', label: '数据分发单', fileAt: 'FKB' },
      { value: 'FKB', label: '用户使用反馈表', vue: 'FeedbackForm' },
    ],
  },
  {
    label: '规划院内部数据服务备案',
    value: 'GHY',
    documents: [
      { value: 'SQB', label: '申请书', vue: 'AgreementForm' },
      { value: 'CNS', label: '承诺书', vue: 'TaskForm' },
      { value: 'SJSCD', label: '数据生产单', vue: 'DataForm' },
      { value: 'FFD', label: '数据分发单', fileAt: 'FKB' },
    ],
  },
  {
    label: '社会公众数据服务备案',
    value: 'SHGZ',
    documents: [
      { value: 'SJSCD', label: '数据生产单', vue: 'DataForm' },
      { value: 'FFD', label: '数据分发单', fileAt: 'FKB' },
      { value: 'FKB', label: '用户使用反馈表', vue: 'FeedbackForm' },
    ],
  },
  {
    label: '科研机构数据服务备案',
    value: 'KYJG',
    documents: [
      { value: 'SCRWD', label: '生产任务单', fileAt: 'DataForm' },
      { value: 'SJSCD', label: '数据生产单', vue: 'DataForm' },
      { value: 'FFD', label: '数据分发单', fileAt: 'FKB' },
      { value: 'FKB', label: '用户使用反馈表', vue: 'FeedbackForm' },
    ],
  },
]

export const defaultTabForm = [
  {
    value: 'RWS',
    label: '任务信息',
    vue: 'TaskForm',
  },
  {
    value: 'SJSCD',
    label: '数据信息',
    vue: 'DataForm',
  },
  {
    value: 'FKB',
    label: '使用单位信息',
    vue: 'FeedbackForm',
  },
]

export const loadExistOrgClassesFromConfig = async (force = false) => {
  if (force || sessionStorage.getItem('orgClasses') === '' || sessionStorage.getItem('orgClasses') === null) {
    let result = []
    await getRequest('/server/institution/orgClasses')
      .then((res) => {
        if (res) {
          sessionStorage.setItem('orgClasses', JSON.stringify(res))
          result = res
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return result
  } else {
    return JSON.parse(sessionStorage.getItem('orgClasses'))
  }
}

export const loadProjectFromConfig = async (force = false) => {
  if (force || sessionStorage.getItem('projects') === '' || sessionStorage.getItem('projects') === null) {
    let result = []
    await getRequest('/server/produce/projects')
      .then((res) => {
        if (res) {
          sessionStorage.setItem('projects', JSON.stringify(res))
          result = res
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return result
  } else {
    return JSON.parse(sessionStorage.getItem('projects'))
  }
}

export const loadOrganizationsFromConfig = async (force = false) => {
  if (force || sessionStorage.getItem('organizations') === '' || sessionStorage.getItem('organizations') === null) {
    let result = []
    await getRequest('/server/institution/idAndNames', { currentPage: 1, pageSize: 10000 })
      .then((res) => {
        if (res) {
          //res = res.data //for /server/institution/byPage
          let orgs = res.map((item) => {
            let org = item
            org.value = item.name
            return org
          })
          sessionStorage.setItem('organizations', JSON.stringify(orgs))
          result = orgs
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return result
  } else {
    return JSON.parse(sessionStorage.getItem('organizations'))
  }
}

export const loadTaskSourceUnitsFromConfig = async (force = false) => {
  if (force || sessionStorage.getItem('taskSourceUnits') === '' || sessionStorage.getItem('taskSourceUnits') === null) {
    let result = []
    await getRequest('/server/institution/taskSourceUnits', { currentPage: 1, pageSize: 10000 })
      .then((res) => {
        if (res) {
          //res = res.data //for /server/institution/byPage
          let orgs = res.map((item) => {
            let org = item
            org.value = item.name
            return org
          })
          sessionStorage.setItem('taskSourceUnits', JSON.stringify(orgs))
          result = orgs
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return result
  } else {
    return JSON.parse(sessionStorage.getItem('taskSourceUnits'))
  }
}

export const getTreeData = (data) => {
  // 循环遍历返回的数据
  for (var i = 0; i < data.length; i++) {
    if (data[i].children.length < 1) {
      // districts若为空数组，则将districts设为undefined
      data[i].children = undefined
      if (data[i].code) {
        data[i].fullname = data[i].code + data[i].name
      }
    } else {
      if (data[i].code) {
        data[i].fullname = data[i].code + data[i].name
        //data[i].fullname = data[i].name
      }
      // districts若不为空数组，则继续 递归调用 本方法
      getTreeData(data[i].children)
    }
  }
  return data
}

export const loadDistrictsFromConfig = async (force = false) => {
  if (force || sessionStorage.getItem('districts') === '' || sessionStorage.getItem('districts') === null) {
    let result = []
    await getRequest('/server/region/')
      .then((res) => {
        if (res) {
          let districts = getTreeData(res.children)
          sessionStorage.setItem('districts', JSON.stringify(districts))
          result = districts
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return result
  } else {
    return JSON.parse(sessionStorage.getItem('districts'))
  }
}

export const loadRecordStatusFromConfig = async (force = false) => {
  if (force || sessionStorage.getItem('recordStatus') === '' || sessionStorage.getItem('recordStatus') === null) {
    let result = []
    await getRequest('/server/dictionary/', { currentPage: 1, pageSize: 10000, group: '备案状态' })
      .then((res) => {
        if (res) {
          let recordStatus = res.data
          recordStatus.unshift({ id: null, name: '全部状态' })
          sessionStorage.setItem('recordStatus', JSON.stringify(recordStatus))
          result = recordStatus
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return result
  } else {
    return JSON.parse(sessionStorage.getItem('recordStatus'))
  }
}

export const loadProduceDepartmentsFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('生产单位', force).then((res) => (result = res))
  return result
}

export const loadJobIdsByServiceIdFromConfig = async (serviceId) => {
  let result = []
  if (serviceId) {
    await getRequest('/server/task/' + serviceId)
      .then((res) => {
        if (res) {
          result = res
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return result
}

export const loadDataIdsForUnitByServiceIdFromConfig = async (serviceId, unitId) => {
  if (!serviceId || !unitId) {
    return
  }
  let result = []
  if (serviceId) {
    await getRequest('/server/produce/record-institution-produce/' + serviceId + '/' + unitId)
      .then((res) => {
        if (res) {
          result = res.obj
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return result
}

export const loadFeedbackUnitByServiceIdFromConfig = async (serviceId) => {
  let result = []
  if (serviceId) {
    await getRequest('/server/institution/useUnits/' + serviceId)
      .then((res) => {
        if (res) {
          result = res.obj
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return result
}

export const loadDictionaryGroupsFromConfig = async () => {
  let result = []
  await getRequest('/server/dictionary/groups/')
    .then((res) => {
      if (res) {
        result = res.obj
      }
    })
    .catch((err) => {
      console.log(err)
    })
  return result
}

export const loadServiceClassesFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('服务分类', force).then((res) => (result = res))
  return result
}

export const loadServiceTypeFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('服务类型', force).then((res) => (result = res))
  return result
}

export const loadDictionaryFromConfig = async (name, force = false) => {
  if (force || sessionStorage.getItem(name) === '' || sessionStorage.getItem(name) === null) {
    let result = []
    await getRequest('/server/dictionary/', { currentPage: 1, pageSize: 10000, group: name })
      .then((res) => {
        if (res) {
          let recordStatus = res.data
          sessionStorage.setItem(name, JSON.stringify(recordStatus))
          result = recordStatus
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return result
  } else {
    return JSON.parse(sessionStorage.getItem(name))
  }
}

export const loadTaskTypeFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('任务分类', force).then((res) => (result = res))
  return result
}

export const loadOriginalDataListFromConfig = async () => {
  let result = []
  await getRequest('/server/data-directory/', { page: 1, size: 10000 })
    .then((res) => {
      if (res) {
        let recordStatus = res.data
        result = recordStatus
      }
    })
    .catch((err) => {
      console.log(err)
    })
  return result
}

export const loadDataTypesFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('数据类型', force).then((res) => (result = res))
  return result
}
export const loadDataFormatsFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('数据格式', force).then((res) => (result = res))
  return result
}
export const loadDataClassesFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('数据分类', force).then((res) => (result = res))
  return result
}

export const loadDiskUseUnitsFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('介质使用单位', force).then((res) => (result = res))
  return result
}

// export const loadMediaTypesFromConfig = async (force = false) => {
//   let result = []
//   await loadDictionaryFromConfig('介质类型', force).then((res) => (result = res))
//   return result
// }

export const loadProjectsFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('项目', force).then((res) => (result = res))
  return result
}
export const loadApplyJobRpsFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('联系人', force).then((res) => (result = res))
  return result
}
export const loadServiceManagersFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('服务负责人', force).then((res) => (result = res))
  return result
}
export const loadOrgClassesFromConfig = async (force = false) => {
  let result = []
  await loadDictionaryFromConfig('服务对象性质', force).then((res) => (result = res))
  return result
}
export const changeStatus = async (recordId, stage) => {
  let result = false
  await postRequest('/server/record/status/' + recordId + '/' + stage)
    .then((res) => {
      if (res) {
        result = res.obj
      }
    })
    .catch((err) => {
      console.log(err)
    })
  return result
}
export const prompt = async (message, success) => {
  const result = await vm
    .$confirm(message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
    .catch((val) => {
      vm.$message({
        type: 'info',
        message: '已取消',
      })
    })
  if (result == 'confirm') {
    if (success) {
      success()
    }
    return true
  } else {
    return false
  }
}

export const parseDataSize = (dataSize, fixed = 2) => {
  let dataSizes = null
  if (dataSize) {
    if (dataSize > 1e24) {
      dataSizes = dataSize / 1e24 + 'NB'
    } else if (dataSize >= 1e21) {
      dataSizes = (dataSize / 1e21).toFixed(fixed) + 'YB'
    } else if (dataSize >= 1e18) {
      dataSizes = (dataSize / 1e18).toFixed(fixed) + 'ZB'
    } else if (dataSize >= 1e15) {
      dataSizes = (dataSize / 1e15).toFixed(fixed) + 'EB'
    } else if (dataSize >= 1e12) {
      dataSizes = (dataSize / 1e12).toFixed(fixed) + 'PB'
    } else if (dataSize >= 1e9) {
      dataSizes = (dataSize / 1e9).toFixed(fixed) + 'TB'
    } else if (dataSize >= 1e6) {
      dataSizes = (dataSize / 1e6).toFixed(fixed) + 'GB'
    } else if (dataSize >= 1e3) {
      dataSizes = (dataSize / 1e3).toFixed(fixed) + 'MB'
    } else if (dataSize >= 0) {
      dataSizes = dataSize.toFixed(fixed) + 'KB'
    }
  }
  return dataSizes
}

export const parseDataMulti = (dataSize) => {
  let dataMulti, dataUnit
  if (dataSize > 1e24) {
    dataMulti = 1e24
    dataUnit = 'NB'
  } else if (dataSize >= 1e21) {
    dataMulti = 1e21
    dataUnit = 'YB'
  } else if (dataSize >= 1e18) {
    dataMulti = 1e18
    dataUnit = 'ZB'
  } else if (dataSize >= 1e15) {
    dataMulti = 1e15
    dataUnit = 'EB'
  } else if (dataSize >= 1e12) {
    dataMulti = 1e12
    dataUnit = 'PB'
  } else if (dataSize >= 1e9) {
    dataMulti = 1e9
    dataUnit = 'TB'
  } else if (dataSize >= 1e6) {
    dataMulti = 1e6
    dataUnit = 'GB'
  } else if (dataSize >= 1e3) {
    dataMulti = 1e3
    dataUnit = 'MB'
  } else {
    dataMulti = 1
    dataUnit = 'KB'
  }
  return [dataMulti, dataUnit]
}
