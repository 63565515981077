<template>
  <div style="width: 100%" id="region">
    <div :style="{ height: height, 'overflow-y': 'auto' }">
      <el-tree
        :data="treeData"
        node-key="code"
        :expand-on-click-node="true"
        :props="defaultProps"
        :load="loadDataLazy"
        :default-expanded-keys="expandFirstNods"
        lazy
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button
              type="text"
              size="mini"
              icon="el-icon-plus"
              @click.stop="() => append(node, data)"
              v-if="node.level != 4 && data.code != '100000' && node.expanded"
            >
              添加
            </el-button>
            <el-button type="text" size="mini" icon="el-icon-edit" @click="() => edit(data)"> 编辑 </el-button>
            <el-button type="text" size="mini" icon="el-icon-delete" @click="() => remove(node, data)">
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form :model="node" ref="regionForm" :rules="rules">
        <el-form-item label="行政区编码" :label-width="formLabelWidth" prop="code">
          <el-input v-model="node.code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="行政区名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="node.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="node.memo" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer span-center">
        <el-button type="primary" @click="saveNode">确定</el-button>
        <el-button @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Region',
  data() {
    return {
      formLabelWidth: '100px',
      dialogFormVisible: false,
      height: window.innerHeight - 75 + 'px',
      title: '添加',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      node: {},
      model: {
        id: '',
        code: '',
        name: '',
        superName: '',
        level: '',
        memo: '',
      },
      expandFirstNods: ['000000'],
      rules: {
        code: [
          {
            validator: (rule, value, callback) => {
              let reg = /^[0-9]{6,6}$/
              if (value === '') {
                callback(new Error('请输入行政编码'))
              } else if (!reg.test(value)) {
                callback(new Error('行政编码必须是6位数字组合'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        name: [
          { required: true, message: '请输入行政区名称', trigger: 'blur' },
          { min: 2, max: 30, message: '长度在2到30个字符', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    append(node, data) {
      if (!node || !data || !data.code) {
        return
      }
      let lastChildCode = null
      let code = null
      let level = null
      if (node.childNodes && node.childNodes.length > 0) {
        lastChildCode = node.childNodes[node.childNodes.length - 1].data.code
      }
      if (data.code == '000000') {
        //country add province
        level = 'province'
        if (lastChildCode) {
          let n = Number(lastChildCode.substring(0, 2)) + 1
          code = (n > 9 ? n : '0' + n) + '0000'
        } else {
          code = '010000'
        }
      } else if (data.code.substring(2) == '0000') {
        //province add city 110000
        level = 'city'
        if (lastChildCode) {
          let n = Number(lastChildCode.substring(2, 4)) + 1
          code = lastChildCode.substring(0, 2) + (n > 9 ? n : '0' + n) + '00'
        } else {
          code = data.code.substring(0, 2) + '0100'
        }
      } else if (data.code.substring(4) == '00') {
        //city add district 110100
        level = '县级'
        if (lastChildCode) {
          let n = Number(lastChildCode.substring(4, 6)) + 1
          code = lastChildCode.substring(0, 4) + (n > 9 ? n : '0' + n)
        } else {
          code = data.code.substring(0, 4) + '01'
        }
      }
      this.node = JSON.parse(JSON.stringify(this.model))
      this.node.superName = data.name
      this.node.code = code
      this.node.level = level
      this.title = '添加'
      this.dialogFormVisible = true
    },
    edit(data) {
      this.title = '编辑'
      this.node = data
      this.dialogFormVisible = true
    },
    saveNode() {
      this.$refs.regionForm.validate((valid) => {
        if (valid) {
          if (this.title == '添加') {
            this.postRequest('/server/region/', this.node).then((resp) => {
              if (resp) {
                this.dialogFormVisible = false
                this.loadTree()
                LocalConfig.loadDistrictsFromConfig(true)
              }
            })
          } else {
            this.putRequest('/server/region/', this.node).then((resp) => {
              if (resp) {
                this.dialogFormVisible = false
                this.loadTree()
                LocalConfig.loadDistrictsFromConfig(true)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    remove(node, data) {
      this.$confirm('此操作将删除行政区<' + data.name + '>, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/region/' + data.id).then((resp) => {
            if (resp) {
              this.loadTree()
              LocalConfig.loadDistrictsFromConfig(true)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    loadTree() {
      this.getRequest('/server/region/').then((resp) => {
        this.treeData = [resp]
      })
    },
    loadDataLazy(node, resolve) {
      //懒加载数据
      let requestUrl = ''
      if (node.level === 0) {
        return resolve([{ name: '中华人民共和国', code: '000000' }])
      }
      if (node.level < 4) {
        requestUrl = '/server/region/code/' + node.data.code
        this.getRequest(requestUrl).then((resp) => {
          if (resp) {
            return resolve(resp)
            //this.rootId = resp[0].id
          }
        })
      }
      return resolve([])
    },
  },
  mounted() {
    //this.loadTree();
  },
}
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
</style>
<style>
#region .el-tree-node__content {
  line-height: 24px;
  height: 24px;
}
</style>
