<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex">
        <el-button
          aria-setsize="mini"
          icon="el-icon-plus"
          type="primary"
          @click="showAddMenuView"
          :v-if="treeData && treeData.length > 0"
          >添加菜单
        </el-button>
        <el-button
          aria-setsize="mini"
          icon="el-icon-plus"
          type="success"
          @click="showAddAPIView"
          :v-if="treeData && treeData.length > 0"
          >添加API
        </el-button>
      </div>
    </div>
    <div class="block">
      <p></p>
      <el-tree :data="treeData" :expand-on-click-node="false" :props="defaultProps" default-expand-all node-key="id">
        <span slot-scope="{ node, data }" class="custom-tree-node">
          <!--表示当前节点和节点的数据-->
          <span
            >{{ node.label }}
            <el-tag v-if="!data.url">菜单</el-tag>
            <el-tag type="success" v-if="data.url">API</el-tag>
          </span>
          <span>
            <!-- <el-button icon="el-icon-plus" size="mini" type="text" @click="() => showAddMenuView(data)">
              添加
            </el-button> -->
            <el-button
              icon="el-icon-edit"
              size="mini"
              type="text"
              v-if="data.url == null"
              @click="() => showEditMenuView(data)"
            >
              编辑
            </el-button>
            <el-button
              icon="el-icon-edit"
              size="mini"
              type="text"
              v-if="data.url != null"
              @click="() => showEditAPIView(data)"
            >
              编辑
            </el-button>

            <el-button
              color="red"
              icon="el-icon-delete"
              size="mini"
              type="text"
              @click="() => remove(node, data)"
              :v-if="node.parentid != null && node.parentid != ''"
            >
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <div>
      <!--Menu编辑对话框-->
      <el-dialog :visible.sync="dialogVisible" title="添加菜单" width="600px" @close="handleClose">
        <el-form ref="menu" :model="menu" label-width="80px" :rules="menuRules" size="mini">
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="menu.name"></el-input>
          </el-form-item>
          <el-form-item label="路由路径" prop="path">
            <el-input v-model="menu.path"></el-input>
          </el-form-item>
          <el-form-item label="组件" prop="component">
            <el-input v-model="menu.component"></el-input>
          </el-form-item>
          <el-form-item label="图标" prop="icon">
            <el-input v-model="menu.icon"></el-input>
          </el-form-item>
          <el-form-item label="顺序" prop="seq">
            <el-input v-model.number="menu.seq"></el-input>
          </el-form-item>
          <el-form-item label="隐藏" prop="hidden">
            <el-switch v-model="menu.hidden" style="display: block"> </el-switch>
          </el-form-item>
          <el-form-item label="启用" prop="enabled">
            <el-switch v-model="menu.enabled" style="display: block"> </el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer span-center">
          <el-button icon="el-icon-check" size="mini" type="primary" @click="handleSubmit('menu')">确定</el-button>
          <el-button icon="el-icon-close" size="mini" @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
      <!--API编辑对话框-->
      <el-dialog :visible.sync="APIDialogVisible" title="添加API" width="600px" @close="handleClose">
        <el-form ref="apiModel" :model="apiModel" label-width="80px" :rules="APIRules" size="mini">
          <el-form-item label="API名称" prop="name">
            <el-input v-model="apiModel.name"></el-input>
          </el-form-item>
          <el-form-item label="API地址" prop="url">
            <el-input v-model="apiModel.url"></el-input>
          </el-form-item>
          <el-form-item label="启用" prop="enabled">
            <el-switch v-model="apiModel.enabled" style="display: block"> </el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer span-center">
          <el-button icon="el-icon-check" size="mini" type="primary" @click="handleSubmit('apiModel')">确定</el-button>
          <el-button icon="el-icon-close" size="mini" @click="APIDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      treeData: [],
      dialogVisible: false,
      APIDialogVisible: false,
      menu: {},
      apiModel: {},
      menuTemplate: {
        id: null,
        url: null,
        path: null,
        component: null,
        name: null,
        icon: null,
        seq: null,
        enabled: true,
        hidden: false,
        parentid: null,
      },
      APIRules: {
        name: [
          { required: true, message: '请输入API名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在2到20个字符', trigger: 'blur' },
        ],
        url: [
          { required: true, message: '请输入API地址', trigger: 'blur' },
          { max: 64, message: '最大长度64个字符', trigger: 'blur' },
        ],
      },
      menuRules: {
        name: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
          { min: 2, max: 64, message: '长度在2到64个字符', trigger: 'blur' },
        ],
        path: [
          { required: true, message: '路由路径', trigger: 'blur' },
          { min: 1, max: 64, message: '长度在1到64个字符', trigger: 'blur' },
        ],
        component: [
          { required: true, message: '组件名称', trigger: 'blur' },
          { min: 1, max: 64, message: '长度在1到64个字符', trigger: 'blur' },
        ],
        icon: [{ max: 64, message: '最大长度64个字符', trigger: 'blur' }],
        seq: [
          { max: 64, message: '最大长度64个字符', trigger: 'blur' },
          { type: 'number', min: 0, max: 65535, message: '顺序必须是一个整数' },
        ],
      },
      defaultProps: {
        label: 'name',
        children: 'children',
      },
    }
  },
  mounted() {
    this.loadMenu()
  },
  methods: {
    handleClose() {},
    handleSubmit(target) {
      this.$refs[target].validate((valid) => {
        if (valid) {
          let request = this.putRequest
          if (this.menu.id == null) {
            request = this.postRequest
          }
          let requestData = this[target]
          request('/system/basic/menu/', requestData).then((resp) => {
            if (resp) {
              this.loadMenu()
            }
          })
          this.dialogVisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    showEditMenuView(data) {
      this.menu = data
      this.dialogVisible = true
    },
    showAddMenuView(nodeData) {
      this.menu = JSON.parse(JSON.stringify(this.menuTemplate))
      this.menu.parentid = this.treeData[0].id
      //取消了二级菜单
      //this.menu.parentid = nodeData.id
      this.dialogVisible = true
    },
    showAddAPIView() {
      this.apiModel = JSON.parse(JSON.stringify(this.menuTemplate))
      this.apiModel.hidden = true
      this.apiModel.parentid = this.treeData[0].id
      this.APIDialogVisible = true
    },
    showEditAPIView(data) {
      this.apiModel = data
      this.APIDialogVisible = true
    },

    remove(node, data) {
      this.$confirm('此操作将删除' + data.name + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (data.id) {
            this.deleteRequest('/system/basic/menu/' + data.id).then((resp) => {
              if (resp) {
                this.loadMenu()
              }
            })
          } else {
            const parent = node.parent
            const children = parent.data.children || parent.data
            const index = children.findIndex((d) => d.id === data.id)
            children.splice(index, 1)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    loadMenu() {
      this.getRequest('/system/basic/menu/all').then((resp) => {
        this.treeData = resp
      })
    },
  },
}
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
</style>
