<template>
  <div>
    <el-input type="textarea" v-model="old"></el-input>
    <el-button @click="encrypt">加密</el-button>
    <el-button @click="decrypt">解密</el-button>
    <el-button @click="reset">重置</el-button>
    <el-input type="textarea" v-model="newone"></el-input>
  </div>
</template>

<script>
import rsa from '../../utils/RSA'
export default {
  name: 'Watermarking',
  data() {
    return {
      old: 'good time',
      newone: '',
      tableFields: {
        服务类型: '',
        服务对象性质: '部系统单位',
        备案编号: '',
        版权单位: '中国国土勘测规划院',
        使用单位: '',
        申请部门: '',
        数据用途: '用于某某项目研究试验',
        '数据名称/内容': '',
        '任务书/函': '',
        版本信息: '',
        数据生产时间: '',
        备注: '',
        表单时间: '',
        水印嵌入时间: '',
        水印信息单号: '',
      },
    }
  },
  mounted() {},
  methods: {
    encrypt() {
      //加密
      this.newone = rsa.encryptDataIn(this.old)
    },
    decrypt() {
      //解密
      this.old = rsa.decryptData(this.newone)
    },
    reset() {
      this.old = JSON.stringify(this.tableFields)
      this.newone = ''
    },
  },
  created() {
    this.old = JSON.stringify(this.tableFields)
  },
}
</script>

<style scoped></style>
