<template>
  <div ref="main" id="main"></div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts/core'
import * as LocalConfig from '@/utils/base'
import { GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

export default {
  name: 'ServiceTimesStatistic',
  components: {},
  props: {
    searchParam: {
      type: Object,
      default: () => ({
        type: 'times',
        target: 'month',
      }),
    },
  },
  data() {
    return {
      myChart: null,
      option: {
        grid: {
          left: '25px',
          right: '25px',
          top: '40px',
          bottom: '10px',
          containLabel: true,
        },
        legend: {
          //   left: 15,
          //   right: 15,
          //   padding: [5, 0],
          //   itemGap: 5,
          data: ['服务次数'], //
        },
        title: {
          text: '{black|}{white|}服务时间',
          // subtext: 'Data from Wikipedia',
          textStyle: {
            fontSize: 14,
            rich: {
              black: {
                width: 3,
                borderRadius: 5,
                backgroundColor: '#6D6D6D',
              },
              white: {
                width: 6,
                backgroundColor: '#FFF',
              },
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        label: {
          show: false,
          color: '#2a2a2a',
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            minInterval: 1,
            axisLabel: {
              formatter: '{value} ',
            },
          },
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            name: '服务次数',
            data: [],
            type: 'bar',
            itemStyle: {
              borderRadius: 10,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: '#45C395' },
                { offset: 0.5, color: '#95DDC3' },
                { offset: 0, color: '#C4ECDE' },
              ]),
            },
          },
          {
            name: '数据量',
            type: 'line',
            yAxisIndex: 1,
            // tooltip: {
            //   valueFormatter: function (value) {
            //     return value
            //   },
            // },
            data: [],
            itemStyle: {
              color: '#FF8C2D',
            },
          },
        ],
      },
    }
  },
  methods: {
    genChart() {
      if (this.myChart) {
        this.myChart.dispose()
      }
      let chartDom = this.$refs['main']
      this.myChart = echarts.init(chartDom)
      this.myChart.showLoading()

      this.option.xAxis.data = []
      this.option.series[0].data = []
      this.option.series[1].data = []
      this.option.series[0].barWidth = 15

      if (!this.searchParam.dateRange || this.searchParam.dateRange.length == 0) {
        for (let i = 5; i >= 0; i--) {
          this.option.xAxis.data.push(moment(new Date()).subtract(i, 'months').format('YY年M月'))
          this.option.series[0].data.push(0)
          this.option.series[1].data.push(0)
        }
      } else {
        let start = moment(this.searchParam.dateRange[0]).startOf('month')
        let end = moment(this.searchParam.dateRange[1]).startOf('month')
        //'2022-10-12' '2023-01-12'
        while (start.isSameOrBefore(end)) {
          this.option.xAxis.data.push(start.format('YY年M月'))
          this.option.series[0].data.push(0)
          this.option.series[1].data.push(0)
          start.add(1, 'months').startOf('month')
        }
      }

      this.searchParam.type = 'times'
      this.searchParam.target = 'month'
      let requestData = JSON.parse(JSON.stringify(this.searchParam))
      this.postRequest('/server/data-issue/statistic', requestData)
        .then((res) => {
          this.prepareChart(res, 0)
        })
        .catch((err) => {
          console.log(err)
        })
      this.searchParam.type = 'size'
      requestData = JSON.parse(JSON.stringify(this.searchParam))
      this.postRequest('/server/data-issue/statistic', requestData)
        .then((res) => {
          this.prepareChart(res, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    prepareChart(res, stype) {
      if (res && res.obj) {
        let dataSize = 0,
          dataMulti = 1,
          dataUnit = 'KB'
        if (stype == 1) {
          res.obj.forEach((row) => {
            if (row.value && dataSize < Number(row.value)) {
              dataSize = Number(row.value)
              let result = LocalConfig.parseDataMulti(dataSize)
              dataMulti = result[0]
              dataUnit = result[1]
            }
          })
          this.option.series[1].name = '数据量(' + dataUnit + ')'
          this.option.legend.data.push(this.option.series[1].name)
          //this.option.title.text = '{black|}{white|}服务时间(' + dataUnit + ')'
        }

        res.obj.forEach((row) => {
          //   that.option.xAxis.data.push(
          //     row.name.charAt(2) != '0' ? row.name.substring(2) + '月' : row.name.substring(3) + '月'
          //   )
          this.option.xAxis.data.some((value, index) => {
            if (value === moment(row.name, 'YYMM').format('YY年M月')) {
              if (stype == 1) {
                this.option.series[stype].data[index] = (row.value / dataMulti).toFixed(2)
              } else {
                this.option.series[stype].data[index] = row.value
              }
              return true
            }
          })
        })
        this.myChart.hideLoading()
        this.option && this.myChart.setOption(this.option)
      }
    },
  },
  created() {
    echarts.use([GridComponent, BarChart, CanvasRenderer])
  },
  mounted() {
    this.genChart()
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.myChart.resize()
      }, 150)
    })
  },
}
</script>

<style></style>
