<template>
  <el-select
    allow-create
    v-model="model"
    @change="updateModel"
    filterable
    clearable
    placeholder="请选择或输入"
    style="width: 100%"
  >
    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
  </el-select>
</template>

<script>
import * as LocalConfig from '@/utils/base'
export default {
  name: 'AllowCreateDictionarySelect',
  components: {},
  props: {
    value: {
      type: String,
      default: undefined,
    },
    group: {
      type: String,
      default: '服务负责人',
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      options: [],
      model: '',
    }
  },
  methods: {
    updateModel() {
      this.$emit('change', this.model)
    },
    loadOptions() {
      LocalConfig.loadDictionaryFromConfig(this.group, true).then((res) => (this.options = res))
    },
    async saveOrUpdate() {
      if (this.model == '' || this.model == null) {
        //clear
        return this.model
      }
      let request = this.postRequest
      let that = this
      let dictionaryItem = this.options.filter((item) => {
        return item.id === that.model
      })[0]
      if (dictionaryItem) {
        //model is id
        return this.model
      } else {
        //model is value
        dictionaryItem = {
          name: this.model,
          group: '服务负责人',
        }
      }
      let result = null
      await request('/server/dictionary/', dictionaryItem)
        .then((res) => {
          if (res.code == 200) {
            that.model = res.obj
            that.updateModel()
            result = that.model
          }
        })
        .catch((err) => {
          console.log(err)
        })
      if (result) {
        this.loadOptions()
      }
      return result
    },
  },

  created() {
    this.model = this.value
    this.loadOptions()
  },
  mounted() {},
  watch: {
    value: function (val, oldVal) {
      this.model = this.value
    },
  },
}
</script>

<style scoped></style>
