import { JSEncrypt } from 'jsencrypt'

let rsa = {
  publicKey: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC6FEtqXqBpBFYyUM/3zN3pxUqO
EzLMqEiK8h7oSxtJJhGI/ep9pv1r51WwWyb+PWPHb9fG7ahVrjuMs39eOg8Eqmch
Y4Vh/q0bAyTQm38LRkwbXrvUCH4eLtvymCn1QVZFIfkIcBWPsbP4q1vz3N1/vooT
HhkblQBEYBf3ANL+YQIDAQAB`,
  privateKey: `MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAMQJWZkX6k64SP8aw1RBPSFJTVboVN31pLtFLhyTiLFzO+07IQ4DScqzLtdYOFmqA/Bi/uE5ZzoZ6he7lt/eWaUw+bNJiAOY9ib1/bI388DYnVNhy3kzUZi5lOdSq1rLz9B8oCZRLNVYCl7BpUBO5OZq129Hx6uSybfVFneqLnvxAgMBAAECgYEAkAu+xnuIM+e925WyyYpWfMr8BUwacC47qU+gHmdaHenqstqr4zwPUN5xINM2NMx0sIJNfvlLHKpGFMJGjqE3hJ0viilpCdaSte8T/tKruqAJ2boZQpIr1FjOFjhDViGLmxKgKQOD7PfMFC/AfEuyYN+yW5MNyTIEXprrRm90OAECQQDl11crV3SPV8LpFr3LMHlLwOg1mm86ex8P1Deymjorp+uBxyDykNPrj5EAA/16fS/9MtmJdd22ntpvc5tct5xRAkEA2lkTftxxA7nBU7kZWL1XArRuuVlPUVOk9CSs9GnvdUG0uDA/rYQUq1o6HqhHKqjtTNuJGuAf0DraKyu7LgAdoQJAF4PFjlCfQz8WWOSTvGMUNdThrQJE2PXZwrPSkvpGc0r1xExMKaT5mYMRsa+20j5Wl5AJQ5AEg/eSn45zWbQLUQJBALXJQZz2JTVn2tg8nCiwgpei5yhzfcEE9am6OzDGXfHuwWoz6WAqPhcnaIoLqiyPNfouMOjqPjJcPTWCFDXJLOECQCtE9jynBVfQ/uoZMKSNk8Pnce2QfPC1vpXqr0fWxHqLXUPDntkxlN3EFAt8qO7OJB0rT9Ox+IL+uUTLCgqLhtI=`,
  encryptData(plainData) {
    let secretArray = []
    let encryptor = new JSEncrypt()
    encryptor.setPublicKey(this.publicKey)
    let startIndex = 0
    while (startIndex < plainData.length) {
      let part = plainData.substr(startIndex, 35)
      startIndex += 35
      secretArray.push(encryptor.encrypt(part))
    }
    return JSON.stringify(secretArray)
  },
  decryptData(secretData) {
    let plainData = ''
    let encryptor = new JSEncrypt()
    encryptor.setPrivateKey(this.privateKey)
    let secretArray = JSON.parse(secretData)
    secretArray.forEach((part) => {
      console.log(encryptor.decrypt(part))
      plainData += encryptor.decrypt(part)
    })
    return plainData
  },
  /** 将字符串转化为utf-8字节 */
  ToUTF8(str) {
    var result = new Array()
    var k = 0
    for (var i = 0; i < str.length; i++) {
      var j = encodeURI(str[i])
      if (j.length == 1) {
        // 未转换的字符
        result[k++] = j.charCodeAt(0)
      } else {
        // 转换成%XX形式的字符
        var bytes = j.split('%')
        for (var l = 1; l < bytes.length; l++) {
          result[k++] = parseInt('0x' + bytes[l])
        }
      }
    }
    return result
  },
  Bytes2Str(arrBytes) {
    var str = ''
    for (var i = 0; i < arrBytes.length; i++) {
      var tmp
      var num = arrBytes[i]
      if (num < 0) {
        //此处填坑，当byte因为符合位导致数值为负时候，需要对数据进行处理
        tmp = (255 + num + 1).toString(16)
      } else {
        tmp = num.toString(16)
      }
      if (tmp.length == 1) {
        tmp = '0' + tmp
      }
      if (i > 0) {
        str += ' ' + tmp
      } else {
        str += tmp
      }
    }
    return str
  },
  Str2Bytes(name) {
    var hexA = new Array()
    name.forEach((item) => {
      if (item === 'ff') {
        return hexA
      } else {
        hexA.push(parseInt(item, 16))
      }
    })
    return hexA
  },
  UTF8ToStr(arr) {
    let val = ''
    arr.forEach((item) => {
      if (item < 127) {
        val += String.fromCharCode(item)
      } else {
        val += '%' + item.toString(16).toUpperCase()
      }
    })
    console.log(val)
    try {
      return decodeURI(val)
    } catch (err) {
      return val
    }
  },
}
export default rsa
