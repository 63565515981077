<template>
  <div id="DistributeDiv" :class="dividerTextBgClass">
    <el-divider content-position="left">
      <span style="margin-right: 10px">分发信息</span>
      <el-button type="success" @click="create()" icon="el-icon-circle-plus" circle> </el-button
    ></el-divider>
    <div class="recordDiv" v-for="(editForm, index) in tableData" :key="editForm.id">
      <Distribute
        :ref="'editForm' + editForm.id"
        :disabled="disabled"
        :showUpdateButtons="showUpdateButtons"
        :produceInstitutions="jobIds"
        :serveTypes="serveTypes"
        :model.sync="tableData[index]"
        @notifyCreated="proceedCreated"
        @notifyDeleted="proceedDeleted"
        @notifyUpdateProduceData="updateProduceData"
      ></Distribute>
    </div>
    <div id="editDiv" ref="editDiv" v-if="editFormVisible">
      <Distribute
        ref="editForm"
        :disabled="disabled"
        :showUpdateButtons="showUpdateButtons"
        :produceInstitutions="jobIds"
        :serveTypes="serveTypes"
        :model.sync="editForm"
        @notifyCreated="proceedCreated"
        @notifyDeleted="proceedDeleted"
        @notifyUpdateProduceData="updateProduceData"
      ></Distribute>
    </div>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import Distribute from '@/components/business/Distribute.vue'
export default {
  name: 'DistributeForm',
  components: { Distribute },
  props: {
    dividerTextBgClass: {
      type: String,
      default: 'f1f2f6',
    },
    serviceId: String,
    recordNum: {
      type: String,
      default: '',
    },
    //面板没有状态更改，stage可以注释
    stage: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    affiliatedUnit: {
      type: String,
      default: '',
    },
    feedbackId: {
      type: String,
      default: '',
    },
    showUpdateButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      serveTypes: [],
      tableData: [],
      editForm: {},
      jobIds: [],
      editFormVisible: false,
    }
  },
  methods: {
    updateProduceData(callback) {
      //   if (callback) {
      LocalConfig.loadDataIdsForUnitByServiceIdFromConfig(this.serviceId, this.affiliatedUnit).then((result) => {
        this.jobIds = result
      })
      //   }
    },
    getNewProduceNum(callback) {
      this.getRequest('/server/data-issue/new/' + this.serviceId)
        .then((res) => {
          if (res) {
            callback(res.obj)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadAllServeTypes() {
      let requestData = {}
      requestData.group = '服务方式'
      requestData.pageSize = 1000
      this.getRequest('/server/dictionary/', requestData)
        .then((res) => {
          if (res) {
            this.serveTypes = res.data
          }
        })
        .catch((err) => {})
    },
    loadTable() {
      if (!this.feedbackId) {
        return
      }
      this.loading = true
      this.getRequest('/server/data-issue/feedback/' + this.feedbackId)
        .then((res) => {
          if (res) {
            this.tableData = res
            this.loading = false
            this.page = this.nxtPage
            this.total = res.total
          }
        })
        .catch((err) => {})
    },
    create() {
      this.editForm = {}
      this.editForm.usingUint = this.affiliatedUnit
      this.editForm.recordId = this.serviceId
      this.editForm.feedbackId = this.feedbackId
      this.getNewProduceNum((num) => {
        this.$set(this.editForm, 'issueNum', num)
      })
      this.editFormVisible = true
      setTimeout(() => {
        this.$refs['editDiv'].scrollIntoView({ behavior: 'auto', block: 'center' })
      }, 100)
    },
    proceedDeleted(id) {
      if (!id) {
        this.editFormVisible = false
      } else {
        this.tableData.some((row, index) => {
          if (row.id === id) {
            this.tableData.splice(index, 1)
            return true
          }
        })
      }
    },
    proceedCreated(editForm) {
      this.tableData.push(editForm)
      this.editFormVisible = false
    },
    traceDistributes(method) {
      this.tableData.forEach((editForm) => {
        this.$refs['editForm' + editForm.id][0][method]()
      })
      if (this.editFormVisible) {
        this.$refs['editForm'][method]()
      }
    },
    resetFields() {
      this.traceDistributes('resetFields')
    },
    validate() {
      let result = true
      this.tableData.forEach((editForm) => {
        let valid = this.$refs['editForm' + editForm.id][0].validate()
        result = result && valid
      })
      if (this.editFormVisible) {
        let valid = this.$refs['editForm'].validate()
        result = result && valid
      }
      return result
    },
    save() {
      this.traceDistributes('submitForm')
    },
    refresh() {
      this.loadTable()
      this.loadAllServeTypes()
      this.updateProduceData(true)
    },
  },
  created() {
    this.loadAllServeTypes()
    LocalConfig.loadDataIdsForUnitByServiceIdFromConfig(this.serviceId, this.affiliatedUnit).then((result) => {
      this.jobIds = result
    })
    this.loadTable()
  },
  mounted() {},
  updated() {},
  watch: {
    feedbackId: function (val, oldVal) {
      if (val && val != '') {
        this.loadTable()
        LocalConfig.loadDataIdsForUnitByServiceIdFromConfig(this.serviceId, this.affiliatedUnit).then((result) => {
          this.jobIds = result
        })
      } else {
        this.tableData = []
      }
      this.editForm = {}
      this.editFormVisible = false
    },
    affiliatedUnit: function (val, oldVal) {
      LocalConfig.loadDataIdsForUnitByServiceIdFromConfig(this.serviceId, this.affiliatedUnit).then((result) => {
        this.jobIds = result
      })
      this.resetFields()
    },
  },
}
</script>

<style scoped>
/* .demo-table-expand {
  font-size: 0;
  margin-left: 155px;
} */
.span-center {
  width: 100%;
  text-align: center;
  display: block;
}
/* #editDiv .width100 .el-form-item {
  width: 100%;
}
.width100 .el-button {
  margin-left: 10px;
  margin-top: 10px;
} */
#editDiv,
.recordDiv {
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding: 10px 10px 0px 10px;
  margin-left: 5px;
  background-color: #e5e5e5;
}
#editDiv {
  /* background-color: #fcbdb0; */
  padding-bottom: 10px;
}
/* .recordDiv {
  background-color: #e5e5e5;
} */
</style>
<style>
/* .demo-table-expand label {
  width: 100px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.demo-table-expand .width100 .el-form-item {
  width: 25%;
} */
.f1f2f6 .el-divider__text {
  background-color: #f4f4f4;
}
.fadfc7 .el-divider__text {
  background-color: #fadfc7;
}
</style>
