import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import Index from '@/views/default/Index'
import Service from '@/views/default/Service.vue'
import Publish from '@/views/default/Publish.vue'
import Statistics from '@/views/default/Statistics.vue'
import SingleService from '@/views/default/SingleService'
import Disk from '@/views/default/Disk.vue'
import SysIndex from '@/views/sys/SysIndex'
import Data from '@/views/default/Data.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    hidden: true,
  },
  {
    path: '/home',
    name: '主页面',
    component: Home,
    redirect: '/home/default',
    children: [
      //   {
      //     path: '/home/default',
      //     name: '首页',
      //     component: Index,
      //   },
      //   {
      //     path: '/home/service',
      //     name: '备案台账',
      //     component: Service,
      //   },
      //   {
      //     path: '/home/data',
      //     name: '数据台账',
      //     component: Data,
      //   },
      //   {
      //     path: '/home/disk',
      //     name: '介质台账',
      //     component: Disk,
      //   },
      //   {
      //     path: '/home/single',
      //     name: '编辑备案',
      //     component: SingleService,
      //     hidden: true,
      //   },
      //   {
      //     path: '/home/statistics',
      //     name: '统计分析',
      //     component: Statistics,
      //   },
      //   {
      //     path: '/home/publish',
      //     name: '发布信息',
      //     component: Publish,
      //   },
      //   {
      //     path: '/home/sys',
      //     name: '系统管理',
      //     component: SysIndex,
      //   },
    ],
  },
]

const router = new VueRouter({
  routes,
})

export default router
