<template>
  <div id="sysIndex" :style="containerStyle">
    <el-tabs v-model="activeName" style="background-color: white; padding: 0px 10px">
      <el-tab-pane label="用户" name="user" :lazy="true">
        <Users></Users>
      </el-tab-pane>
      <el-tab-pane label="角色" name="role" :lazy="true">
        <Roles></Roles>
      </el-tab-pane>
      <el-tab-pane label="权限" name="role_functions" :lazy="true">
        <RoleMenus></RoleMenus>
      </el-tab-pane>
      <el-tab-pane label="菜单与API" name="menu" :lazy="true">
        <Menu></Menu>
      </el-tab-pane>
      <!-- <el-tab-pane label="部门管理" name="department">
        <Department></Department>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="申请/来源单位" name="department" :lazy="true">
        <InstitutionTree></InstitutionTree>
      </el-tab-pane> -->
      <el-tab-pane label="单位" name="institution" :lazy="true">
        <Institution></Institution>
      </el-tab-pane>
      <el-tab-pane label="行政区域" name="area" :lazy="true">
        <Region></Region>
      </el-tab-pane>
      <el-tab-pane label="数据字典" name="dictionary" :lazy="true">
        <Dictionary></Dictionary>
      </el-tab-pane>
      <!-- <el-tab-pane label="数字水印" name="dataWater">
        <watermarking></watermarking>
      </el-tab-pane> -->
      <el-tab-pane label="导入台账" name="importService" :lazy="true">
        <ImportService ref="importServiceTab"></ImportService>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Users from '@/components/settings/Users'
import Roles from '@/components/basic/Roles'
import RoleMenus from '@/components/settings/RoleMenu'
import Dictionary from '@/components/basic/Dictionary'
import Institution from '@/components/basic/Institution'
import InstitutionTree from '@/components/basic/InstitutionTree'
import Menu from '@/components/basic/Menu'
import Region from '@/components/basic/Region'
import Watermarking from '@/components/basic/Watermarking'
import ImportService from '@/components/basic/ImportService'

export default {
  name: 'SysIndex',
  data() {
    return {
      activeName: 'user',
      height: window.innerHeight - 75 + 'px',
    }
  },
  computed: {
    containerStyle() {
      return { height: document.body.clientHeight + 'px' }
    },
  },
  watch: {
    activeName: function (val, oldVal) {
      //   if (this.val == 'importService') {
      //     this.$refs.importServiceTab.prepareMaps()
      //   }
    },
  },
  components: {
    Users,
    Roles,
    RoleMenus,
    Dictionary,
    Menu,
    InstitutionTree,
    Institution,
    Region,
    Watermarking,
    ImportService,
  },
}
</script>

<style scoped></style>
<style>
/* #sysIndex .el-form-item__label {
  font-size: 12px;
} */
.el-form-item__label {
  font-size: 12px;
}
.el-radio__label {
  font-size: 12px;
}
#sysIndex .el-tabs__header {
  margin-left: 5px;
}
</style>
