<template>
  <div v-if="recordId">
    <span style="width: 40px; display: inline-block"> </span>
    <el-checkbox
      v-model="isProduceCompleted"
      @change="changeStatusProduceCompleted"
      style="font-size: 12px"
      :disabled="disabled || stage < 0 || stage > 3"
      >生产已完成
    </el-checkbox>
    <span style="width: 10px; display: inline-block"> </span>
    <el-checkbox
      v-model="isDistributeCompleted"
      @change="changeStatusDistributeCompleted"
      style="font-size: 12px"
      :disabled="disabled || stage < 0 || stage > 3"
      >成果已分发
    </el-checkbox>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
export default {
  name: 'StatusPane',
  components: {},
  props: {
    stage: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    recordId: String,
  },
  data() {
    return {
      isProduceCompleted: false,
      isDistributeCompleted: false,
    }
  },
  methods: {
    changeStatusDistributeCompleted(val) {
      let that = this
      if (val) {
        LocalConfig.changeStatus(this.recordId, 3).then((response) => {
          if (response) {
            that.notifyStageUpdate(3)
          } else {
            that.isDistributeCompleted = false
          }
        })
      } else {
        LocalConfig.changeStatus(this.recordId, 2).then((response) => {
          if (response) {
            that.notifyStageUpdate(2)
          } else {
            that.isDistributeCompleted = true
          }
        })
      }
    },
    changeStatusProduceCompleted(val) {
      let that = this
      if (val) {
        LocalConfig.changeStatus(this.recordId, 2).then((result) => {
          if (result) {
            that.notifyStageUpdate(2)
          } else {
            that.isProduceCompleted = false
          }
        })
      } else {
        LocalConfig.changeStatus(this.recordId, 1).then((result) => {
          if (result) {
            that.notifyStageUpdate(1)
          } else {
            that.isProduceCompleted = true
          }
        })
      }
    },
    notifyStageUpdate(val) {
      this.$emit('notifyStageUpdate', val)
    },
  },
  created() {
    this.isProduceCompleted = this.stage == 2
    this.isDistributeCompleted = this.stage == 3
  },
  mounted() {},
  computed: {},
  watch: {
    stage: function (val, oldVal) {
      this.isProduceCompleted = this.stage == 2
      this.isDistributeCompleted = this.stage == 3
    },
  },
}
</script>

<style scoped></style>
