<template>
  <div ref="main" id="main"></div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import * as echarts from 'echarts/core'
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
} from 'echarts/components'
import { MapChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

export default {
  name: 'DataRangeStatistic',
  components: {},
  props: {
    statisticType: {
      type: String,
      default: 'times',
    },
    searchParam: {
      type: Object,
      default: () => ({
        type: 'times',
        target: 'dataType',
      }),
    },
  },
  data() {
    return {
      max: 0,
      myChart: null,
      option: {
        title: {
          text: '{black|}{white|}数据范围',
          // subtext: 'Data from Wikipedia',
          textStyle: {
            fontSize: 14,
            rich: {
              black: {
                width: 3,
                borderRadius: 5,
                backgroundColor: '#6D6D6D',
              },
              white: {
                width: 6,
                backgroundColor: '#FFF',
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          //   formatter: '{b}<br/>{c}',
          formatter: function (params) {
            return params.name + '：' + (params.value ? params.value : 0)
          },
        },
        toolbox: {
          show: false,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        visualMap: {
          min: 0,
          max: 50000,
          // text: ['High', 'Low'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['#D3E2FA', '#456FC2', '#0238a8'],
            // color: ['lightblue', 'blue', 'darkblue'],
          },
          itemWidth: 15,
          left: 40,
        },
        series: [
          {
            name: '数据范围',
            type: 'map',
            map: 'CN',
            label: {
              show: false,
            },
            zoom: 1.22,
            data: [],
          },
        ],
      },
    }
  },
  methods: {
    genChart() {
      if (this.myChart) {
        this.myChart.dispose()
      }
      let chartDom = this.$refs['main']
      this.myChart = echarts.init(chartDom)
      this.myChart.showLoading()
      this.searchParam.type = this.statisticType
      this.searchParam.target = 'dataRange'
      let requestData = JSON.parse(JSON.stringify(this.searchParam))
      this.postRequest('/server/data-issue/statistic', requestData)
        .then((res) => {
          if (res && res.obj) {
            this.option.series[0].data = []
            let that = this
            that.max = 0

            let dataSize = 0,
              dataMulti = 1,
              dataUnit = 'KB'
            if (this.statisticType == 'size') {
              res.obj.forEach((row) => {
                if (row.value && dataSize < Number(row.value)) {
                  dataSize = Number(row.value)
                  let result = LocalConfig.parseDataMulti(dataSize)
                  dataMulti = result[0]
                  dataUnit = result[1]
                }
              })
              this.option.title.text = '数据范围(' + dataUnit + ')'
            }

            res.obj.forEach((row) => {
              if (that.statisticType == 'size') {
                that.max = that.max > row.value / dataMulti ? that.max : (row.value / dataMulti + 1).toFixed(0)
                that.option.series[0].data.push({ value: (row.value / dataMulti).toFixed(1), name: row.name })
              } else {
                that.max = that.max > row.value ? that.max : row.value
                that.option.series[0].data.push({ value: row.value, name: row.name })
              }
            })
            this.option.visualMap.max = this.max > 0 ? this.max : 1
            this.myChart.hideLoading()
            this.option && this.myChart.setOption(this.option)
          }
        })
        .catch((err) => {
          console.log(err)
          this.myChart.hideLoading()
        })
    },
  },
  created() {
    echarts.use([
      TitleComponent,
      ToolboxComponent,
      TooltipComponent,
      VisualMapComponent,
      GeoComponent,
      MapChart,
      CanvasRenderer,
    ])
    echarts.registerMap('CN', require('@/assets/CN.json'))
  },
  mounted() {
    this.genChart()
    let that = this
    window.addEventListener('resize', () => {
      setTimeout(() => {
        that.myChart.resize()
      }, 150)
    })
  },
}
</script>

<style></style>
