<template>
  <div v-loading="loading" id="recordDetail">
    <p style="margin-left: 40px">
      当前任务状态为<span class="red">{{ form.currentNode }}</span
      >，共服务单位<span class="red">{{ form.useUnits ? form.useUnits.length : 0 }}</span
      >个。
    </p>
    <el-form size="mini" :model="form" label-width="140px">
      <el-divider content-position="left">任务信息 </el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="任务来源单位：">
            {{ form.taskUnit ? form.taskUnit : '无' }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否有函件：">
            {{ form.taskHas ? '是' : '否' }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="任务来源时间：">
            {{ form.taskFrom ? form.taskFrom : '无' }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收文编号："> {{ form.fileNum ? form.fileNum : '无' }} </el-form-item>
        </el-col>
      </el-row>
      <div
        v-for="(useUnit, index) in form.useUnits"
        :key="index"
        style="/* padding: 10px 10px 10px 0px; margin-bottom: 10px */"
      >
        <el-divider content-position="left"
          >使用单位：{{ useUnit.name }}
          <span style="font-weight: normal; font-size: 12px; margin-left: 15px" v-if="useUnit.agreementNum"
            >协议书编号：{{ useUnit.agreementNum }}</span
          >
        </el-divider>
        <el-row v-for="(dataIssue, index) in useUnit.dataIssues" :key="index">
          <el-col :span="8">
            <el-form-item :label="(useUnit.dataIssues.length > 1 ? index + 1 + '. ' : '') + '分发编号：'">{{
              dataIssue.issueNum
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="分发时间：">{{ dataIssue.issueDate }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="领取人：">{{ dataIssue.usingReceiver }}</el-form-item>
          </el-col>

          <div
            v-for="(produce, index) in dataIssue.produces"
            :key="index"
            style="border: 1px solid #dcdfe6; margin: 20px 0px 5px 40px"
          >
            <!-- /* padding: 10px 10px 10px 10px; margin-bottom: 10px; margin-left: 20px */ -->
            <el-row>
              <el-col :span="8">
                <el-form-item label="生产编号：" label-width="100px">{{ produce.dataNum }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="服务类型：" label-width="113px">{{ produce.produceType }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="数据名称：" label-width="127px">{{ produce.dataName }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="数据量：" label-width="100px"
                  >{{ produce.dataSize }}{{ produce.dataSizeUnit }}</el-form-item
                >
              </el-col>
              <el-col :span="8">
                <el-form-item label="生产单位：" label-width="113px">{{ produce.produceUnit }}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="数据范围：" label-width="100px">{{ produce.dataRangeText }}</el-form-item>
            </el-row>
          </div>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
export default {
  name: 'RecordDetail',
  components: {},
  props: {
    recordNum: {
      type: String,
      default: '',
    },
    serviceId: String,
  },
  data() {
    return {
      form: {},
    }
  },
  methods: {
    loadData() {
      if (!this.serviceId) return
      this.getRequest('/server/record/detail/' + this.serviceId).then((res) => {
        if (res) {
          this.form = res.obj
        }
      })
    },
    clearForms() {
      this.form = {}
    },
  },
  created() {
    this.loadData()
  },
  mounted() {},
  watch: {
    serviceId: {
      handler(val, old) {
        if (val === undefined) {
          //('1::clear')
          this.clearForms()
        } else if (val && val != '') {
          //log('2::loadData')
          this.loadData()
        }
      },
      immediate: true,
    },
  },
  computed: {
    loading() {
      if (this.form.id === undefined) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>
.red {
  color: red;
}
</style>
<style>
#recordDetail .el-form-item__content,
#recordDetail .el-form-item__label {
  line-height: 18px;
}
#recordDetail .el-divider--horizontal {
  margin: 22px 0;
}
</style>
