<template>
  <el-card class="box-card">
    <div class="orgItem" v-for="org in orgs" :key="org.value">
      <i class="el-icon-arrow-right title">{{ org.label }}</i>
      <hr />
      <div class="document-list">
        <ImageItem
          @click="toRecord(org.documents, doc.value, doc.fileAt)"
          v-for="doc in org.documents"
          :key="doc.value"
          :label="doc.label"
          :icon="'/img/document/' + org.value + '-' + doc.value + '.png'"
          :iconHover="'/img/document/' + org.value + '-' + doc.value + '-H.png'"
          v-if="!doc.hidden"
        />
      </div>
    </div>
  </el-card>
</template>

<script>
import { organization } from '@/utils/base'
//import ImageItem from '@/views/default/components/ImageItem.vue'
import ImageItem from './ImageItem'
export default {
  name: 'DocWall',
  components: {
    ImageItem,
  },
  data() {
    return { orgs: organization }
  },
  created() {},
  mounted() {},
  methods: {
    toRecord(documents, docId, fileAt) {
      if (this.$store.state.readonly) {
        this.$message({
          type: 'warning',
          message: '权限不足',
        })
        return
      }
      sessionStorage.removeItem('documents')
      sessionStorage.removeItem('docId')
      //docId对应的如果没有vue则docId=fileAt
      let docs = []
      documents.forEach((doc) => {
        if (doc.vue) {
          docs.push(doc)
        } else {
          if (docId === doc.value) {
            docId = doc.fileAt
          }
        }
      })
      this.$store.commit('setSingle', { documents: docs, current: docId })
      //   sessionStorage.setItem('documents', JSON.stringify(docs))
      //   sessionStorage.setItem('docId', docId)
      this.$router.replace('/home/single')
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 14px;
  font-weight: 700;
}
hr {
  border: 0;
  border-bottom: 1px solid #cccccc;
  padding: 0px 10px;
}
.document-list {
  justify-content: center;
}
.orgItem {
  margin-bottom: 7px;
}
</style>
