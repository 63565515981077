<template>
  <div class="template" v-loading="loading" id="feedbackDiv">
    <span style="display: flex">
      <el-divider content-position="left">
        <span style="margin-right: 10px">使用单位信息</span
        ><el-button type="success" @click="create()" icon="el-icon-circle-plus" circle :disabled="disabled"> </el-button
      ></el-divider>
      <div class="el-divider__text is-right" style="margin-top: 24px; padding-right: 0px">
        <el-button @click="refresh()" icon="el-icon-refresh-right" circle :disabled="disabled" v-loading="loading">
        </el-button>
      </div>
    </span>

    <el-collapse v-model="activeNames" @change="handleCollapseChange">
      <el-collapse-item :name="editForm.id" v-for="(editForm, index) in tableData" :key="editForm.id">
        <template slot="title">
          <span style="margin-left: 40px; font-weight: 400; width: 100%" v-if="activeNames.indexOf(editForm.id) == -1">
            <el-col :span="12" class="fixedWidth">使用单位：{{ editForm.unitName }}</el-col>
            <!-- <el-col :span="6" class="fixedWidth"
              >分发次数{{ editForm.distributeTimes }}{{ editForm.dataSizeUnit }}</el-col
            > -->
            <el-col :span="6" class="fixedWidth"
              >数据总量：{{ editForm.dataSizeKb ? parseDataSize(editForm.dataSizeKb) : '' }}</el-col
            >
            <el-col :span="6" class="fixedWidth">分发状态：{{ editForm.distributed ? '已分发' : '未完成' }}</el-col>
          </span>
        </template>
        <div class="recordDiv">
          <feedback
            :ref="'editForm' + editForm.id"
            :model.sync="tableData[index]"
            :serviceId="serviceId"
            :recordNum="recordNum"
            :disabled="disabled"
            :stage="stage"
            :companies="companies"
            :projects="projects"
            @notifyCreated="proceedCreated"
            @notifyDeleted="proceedDeleted"
            @notifyUpdateCompanies="updateCompanies"
            @notifyUpdateProjects="updateProjects"
            @notifyCloseDialog="notifyCloseDialog"
          ></feedback>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div id="editDiv" ref="editDiv" v-if="editFormVisible">
      <feedback
        ref="editForm"
        :model.sync="editForm"
        :serviceId="serviceId"
        :recordNum="recordNum"
        :disabled="disabled"
        :stage="stage"
        :companies="companies"
        :projects="projects"
        dividerTextBgClass="fadfc7"
        @notifyCreated="proceedCreated"
        @notifyDeleted="proceedDeleted"
        @notifyUpdateCompanies="updateCompanies"
        @notifyUpdateProjects="updateProjects"
        @notifyCloseDialog="notifyCloseDialog"
      ></feedback>
    </div>
    <el-divider content-position="left" v-if="serviceId"> 任务状态 </el-divider>
    <status-pane :disabled="disabled" :stage="stage" :recordId="serviceId" @notifyStageUpdate="notifyStageUpdate">
    </status-pane>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import Feedback from '@/components/business/Feedback.vue'
import StatusPane from '@/components/business/StatusPane.vue'
export default {
  name: 'FeedbackForm',
  components: { Feedback, StatusPane },
  props: {
    serviceId: String,
    recordNum: {
      type: String,
      default: '',
    },
    stage: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    dividerTextBgClass: {
      type: String,
      default: 'f1f2f6',
    },
  },
  data() {
    return {
      loading: false,
      formLabelWidth: '100px',
      tableData: [],
      companies: [],
      editForm: {},
      editFormVisible: false,
      projects: [],
      activeNames: [],
    }
  },
  methods: {
    handleCollapseChange(activeNames) {
      /*
       */
      //   activeNames.forEach((id) => {
      //     setTimeout(() => {
      //       this.$refs['editForm' + id][0].updateDataUnitItem()
      //     }, 200)
      //   })
    },
    parseDataSize(dataSizeKb) {
      return LocalConfig.parseDataSize(Number(dataSizeKb))
    },
    loadTable(refreshDistributes = false) {
      if (!this.serviceId || this.serviceId === '') {
        return
      }
      this.loading = true
      let requestData = {}
      requestData.pageSize = 1000
      requestData.recordId = this.serviceId
      this.getRequest('/server/feedback/page/', requestData)
        .then((res) => {
          if (res) {
            res.data.forEach((row) => {
              row.saving = false
              this.initUseUnit(row)
              this.initProject(row)
            })
            this.tableData = res.data
            if (refreshDistributes) {
              this.tableData.forEach((feedback) => {
                this.$refs['editForm' + feedback.id][0].refreshDistributes()
                this.$refs['editForm' + feedback.id][0].handleUseUnitChange(
                  this.$refs['editForm' + feedback.id][0].editForm.unitId
                )
                this.$refs['editForm' + feedback.id][0].handleProjectChange(
                  this.$refs['editForm' + feedback.id][0].editForm.projectId
                )
              })
            }
            this.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    refresh() {
      LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId).then((result) => {
        this.companies = result
        LocalConfig.loadProjectsFromConfig(true).then((result) => {
          this.projects = result
          this.loadTable(true)
        })
      })
    },
    create() {
      LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId).then((result) => {
        if (result && result.length < 1) {
          this.$message({
            type: 'info',
            message: '请先在任务信息添加使用单位',
          })
          return
        } else {
          this.companies = result
        }
        this.editForm = {}
        this.editForm.recordId = this.serviceId
        this.editFormVisible = true
        setTimeout(() => {
          this.$refs['editDiv'].scrollIntoView({ behavior: 'auto', block: 'center' })
        }, 200)
      })
    },
    proceedDeleted(id) {
      if (!id) {
        this.editFormVisible = false
      } else {
        this.tableData.some((row, index) => {
          if (row.id === id) {
            this.tableData.splice(index, 1)
            return true
          }
        })
      }
    },
    proceedCreated(editForm) {
      this.tableData.push(editForm)
      this.editFormVisible = false
    },
    notifyStageUpdate(stage) {
      this.$emit('notifyStageUpdate', stage)
    },
    updateCompanies(callback) {
      if (callback) {
        LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId).then((result) => {
          this.companies = result
        })
      }
    },
    updateProjects() {
      LocalConfig.loadProjectsFromConfig(true).then((result) => {
        this.projects = result
      })
    },
    notifyCloseDialog() {
      this.$emit('notifyCloseDialog')
    },
    initUseUnit(editForm) {
      let id = editForm.unitId
      if (id && id != '') {
        let useUnit = this.companies.filter((item) => {
          return item.id === id
        })[0]
        if (useUnit) {
          this.$set(editForm, 'address', useUnit.address)
        //   this.$set(editForm, 'head', useUnit.head)
        //   this.$set(editForm, 'telephone', useUnit.telephone)
        //   this.$set(editForm, 'email', useUnit.email)
          editForm.useUnitChanged = false
        }
      } else {
        this.$set(editForm, 'address', '')
        // this.$set(editForm, 'head', '')
        // this.$set(editForm, 'telephone', '')
        // this.$set(editForm, 'email', '')
        editForm.useUnitChanged = false
      }
    },
    initProject(editForm) {
      let id = editForm.projectId
      if (id === null || id === undefined || id === '') {
        //clear
        // this.$set(editForm, 'usingProjectRp', '')
        // this.$set(editForm, 'usingProjectRpTel', '')
        // this.$set(editForm, 'usingProjectRpMail', '')
        editForm.projectChanged = false
        editForm.projectCreated = false
      } else {
        let project = this.projects.filter((item) => {
          return item.id === id
        })[0]
        if (project) {
        //   let arr = project.value.split('/')
        //   this.$set(editForm, 'usingProjectRp', arr[0] ? arr[0] : '')
        //   this.$set(editForm, 'usingProjectRpTel', arr[1] ? arr[1] : '')
        //   this.$set(editForm, 'usingProjectRpMail', arr[2] ? arr[2] : '')

          editForm.projectChanged = false
          editForm.projectCreated = false
        }
      }
    },
  },
  created() {
    LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId).then((result) => {
      this.companies = result
      LocalConfig.loadProjectsFromConfig(true).then((result) => {
        this.projects = result
        this.loadTable()
      })
    })
  },
  mounted() {},
  watch: {
    serviceId: function (val, oldVal) {
      if (val && val != '') {
        this.loadTable()
        LocalConfig.loadFeedbackUnitByServiceIdFromConfig(this.serviceId).then((result) => (this.companies = result))
      } else {
        this.tableData = []
      }
      this.activeNames = []
      this.editForm = {}
      this.editFormVisible = false
    },
    activeNames: function (val, oldVal) {
      let closed = oldVal.filter((oid) => val.indexOf(oid) == -1)
      closed.forEach((id) => {
        if (this.$refs['editForm' + id][0].updateDataSize) {
          this.$refs['editForm' + id][0].updateDataSize()
        }
      })
    },
  },
  updated() {},
}
</script>
<style scoped>
.span-center {
  width: 100%;
  text-align: center;
  display: block;
}
#tableArea td .cell {
  display: block; /* 需要 */
  white-space: nowrap; /* 过长部分不换行 */
  text-overflow: ellipsis; /* 过长部分展示省略号 */
  overflow: hidden; /* 隐藏过长部分 */
}
#editDiv,
.recordDiv {
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding: 10px 10px 10px 10px;
  margin-left: 5px;
}
#editDiv {
  background-color: #fadfc7;
  /* #EFCB68 #f4f4f4 #decccc */
}
#editDiv .el-divider__text {
  background-color: #fadfc7;
}
.recordDiv {
  background-color: #f4f4f4;
}
.recordDiv .el-divider__text {
  background-color: #f4f4f4;
}
</style>
<style>
#feedbackDiv .el-collapse-item__header.is-active {
  height: 25px;
}
</style>
