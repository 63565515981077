<template>
  <div id="statistics">
    <el-dialog :visible.sync="saveFormVisible" append-to-body width="400px" title="保存查询条件">
      <el-form :model="form" size="mini" :label-width="formLabelWidth">
        <el-form-item label="专题名称">
          <el-input v-model="form.label" />
        </el-form-item>
        <el-form-item label="专题描述">
          <el-input v-model="form.desc" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="span-center">
          <el-button type="primary" @click="saveTopic()">保存</el-button>
          <el-button @click="saveFormVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="publishFormVisible" append-to-body width="400px" title="生成服务报告">
      <el-form :model="form2" size="mini" :label-width="formLabelWidth">
        <el-form-item label="报告模版">
          <el-select v-model="form2.template" placeholder="请选择">
            <el-option label="模版A" value="0" />
            <el-option label="模版B" value="1" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="span-center">
          <el-button type="primary" @click="publish()">生成报告</el-button>
          <el-button @click="publishFormVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :visible.sync="readonlyFormVisible"
      :title="'查看备案' + recordNum"
      append-to-body
      width="90%"
      @close="resetServiceId"
    >
      <RecordDetail :serviceId="serviceId" :recordNum="recordNum" @close="onClose" />
      <template #footer>
        <span class="dialog-footer span-center">
          <el-button type="primary" @click="readonlyFormVisible = false">返回</el-button>
        </span>
      </template>
    </el-dialog>
    <div id="summary" v-if="false">
      <el-row :gutter="20">
        <el-col :span="6">
          <div>
            <el-statistic title="截止时间">
              <template slot="suffix">{{ todayString }}<i class="el-icon-date" style="color: blue"> </i> </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic group-separator="," :value="serviceTimes" title="提供服务次数">
              <template slot="suffix">
                <i class="el-icon-s-flag" style="color: red"> </i>
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic
              group-separator=","
              :precision="2"
              decimal-separator="."
              :value="dataSize"
              title="分发数据总量"
            >
              <template slot="suffix">
                {{ dataSizeUnit }}<i class="el-icon-success" style="color: green"> </i>
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-statistic :value="useUnits" title="服务单位数量">
              <template slot="suffix">
                <i class="el-icon-star-on" style="color: red"> </i>
              </template>
            </el-statistic>
          </div>
        </el-col>
      </el-row>
    </div>
    <div id="summary">
      <el-row :gutter="0" type="flex" justify="space-between">
        <el-col :span="8">
          <summary-pane period="year"></summary-pane>
        </el-col>
        <el-col :span="8">
          <summary-pane period="season" color="blue"></summary-pane>
        </el-col>
        <el-col :span="8">
          <summary-pane period="month" color="green"></summary-pane>
        </el-col>
      </el-row>
    </div>
    <div id="searchPane">
      <el-form :model="searchForm" size="mini" :label-width="formLabelWidth">
        <el-row>
          <el-col :span="6">
            <el-form-item label="分发时间" label-width="100px">
              <el-date-picker
                v-model="searchForm.dateRange"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                format="yyyy年M月d日"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="数据分类">
              <el-select
                v-model="searchForm.dataClass"
                multiple
                placeholder="请选择"
                clearable
                :collapse-tags="true"
                style="width: 100%"
              >
                <el-option v-for="item in dataClasses" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="任务分类">
              <el-select
                v-model="searchForm.taskType"
                multiple
                placeholder="请选择"
                clearable
                :collapse-tags="true"
                style="width: 100%"
              >
                <el-option v-for="task in taskTypes" :key="task.id" :label="task.name" :value="task.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label=""></el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="专题" label-width="100px">
              <el-select
                v-model="topic"
                value-key="value"
                placeholder="请选择"
                @change="topicSelect"
                style="width: 100%"
              >
                <el-option v-for="(item, index) in topics" :key="item.value" :label="item.label" :value="item.content">
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 12px">
                    <el-button type="text" @click="deleteTopic(index, item.id)">删除</el-button>
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="单位名称" label-width="100px">
              <el-select
                v-model="searchForm.useUnit"
                multiple
                clearable
                allow-create
                filterable
                :collapse-tags="true"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option v-for="org in organizations" :key="org.id" :label="org.value" :value="org.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="单位分类">
              <el-select
                v-model="searchForm.orgClass"
                multiple
                clearable
                filterable
                :collapse-tags="true"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option v-for="item in orgClasses" :key="item.id" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="挂靠项目">
              <el-select
                v-model="searchForm.project"
                multiple
                clearable
                allow-create
                filterable
                :collapse-tags="true"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option v-for="item in projects" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label="" label-width="40px">
              <el-button type="success" icon="fa fa-save" @click="editTopic()"
                ><span style="width: 5px; display: inline-block"></span>保存为专题
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="" label-width="100px">
              <el-button type="primary" icon="el-icon-search" @click="search()">统计</el-button>
              <el-button type="success" icon="el-icon-document" @click="editPublish" style="float: right"
                >生成报告</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div id="chartArea">
      <!-- <el-tabs type="border-card" v-model="tabItem" tab-position="left">
        <el-tab-pane :lazy="true" name="times"> -->
          <!-- <div slot="label"><div class="tabItemLabel">服务次数</div></div> -->
          <el-row style="padding: 10px">
            <el-col :span="8">
              <el-row>
                <div class="chartDiv">
                  <service-times-statistic
                    ref="chart0"
                    :searchParam="searchForm"
                    :style="chartDiv2"
                  >
                  </service-times-statistic>
                </div>
              </el-row>
              <el-row>
                <div class="chartDiv">
                  <ServiceTypeStatistic
                    ref="chart6"
                    :style="chartDiv2"
                    :searchParam="searchForm"
                  >
                  </ServiceTypeStatistic>
                </div>
              </el-row>
            </el-col>
            <el-col :span="8">
              <!-- <div class="chartDiv">
                <data-range-statistic
                  v-if="'times' === tabItem"
                  ref="chart2"
                  :style="chartDiv1"
                  :searchParam="searchForm"
                >
                </data-range-statistic>
              </div> -->
              <el-row>
              <div class="chartDiv">
                  <use-unit-statistic
                    ref="chart7"
                    :style="chartDiv2"
                    :searchParam="searchForm"
                  >
                  </use-unit-statistic>
                </div>
                </el-row>
              <el-row>
                <div class="chartDiv">
                  <use-unit-statistic
                    ref="chart1"
                    :style="chartDiv2"
                    statisticType="size"
                    :searchParam="searchForm"
                  >
                  </use-unit-statistic>
                </div>
                </el-row>
            </el-col>
            <el-col :span="8">
              <el-row>
                <div class="chartDiv">
                    <data-type-statistic
                      v-if="'times' === tabItem"
                      ref="chart3"
                      :style="chartDiv2"
                      :searchParam="searchForm"
                    >
                    </data-type-statistic>
                  </div>
                </el-row>
                <el-row>
                  <div class="chartDiv">
                    <data-content-statistic
                      v-if="'times' === tabItem"
                      ref="chart4"
                      :style="chartDiv2"
                      :searchParam="searchForm"
                    >
                    </data-content-statistic>
                  </div>
                </el-row>              
            </el-col>
          </el-row>
        <!-- </el-tab-pane> -->
        <!-- <el-tab-pane :lazy="true" name="size"> -->
          <!-- <div slot="label"><div class="tabItemLabel">数据量</div></div> -->
          <!-- <el-row style="padding: 10px">
            <el-col :span="6">
              <el-row>
                <div class="chartDiv">
                  <service-times-statistic
                    v-if="'size' === tabItem"
                    ref="chart6"
                    :style="chartDiv2"
                    statisticType="size"
                    :searchParam="searchForm"
                  >
                  </service-times-statistic>
                </div>
              </el-row>
              <el-row>
                <div class="chartDiv">
                  <use-unit-statistic
                    v-if="'size' === tabItem"
                    ref="chart7"
                    :style="chartDiv2"
                    statisticType="size"
                    :searchParam="searchForm"
                  >
                  </use-unit-statistic>
                </div>
              </el-row>
            </el-col>
            <el-col :span="8">
              <div class="chartDiv">
                <data-range-statistic
                  v-if="'size' === tabItem"
                  ref="chart8"
                  :style="chartDiv1"
                  statisticType="size"
                  :searchParam="searchForm"
                >
                </data-range-statistic>
              </div>
            </el-col>
            <el-col :span="10">
              <el-row>
                <el-col :span="12">
                  <div class="chartDiv">
                    <data-type-statistic
                      v-if="'size' === tabItem"
                      ref="chart9"
                      :style="chartDiv2"
                      statisticType="size"
                      :searchParam="searchForm"
                    >
                    </data-type-statistic>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="chartDiv">
                    <data-content-statistic
                      v-if="'size' === tabItem"
                      ref="chart10"
                      :style="chartDiv2"
                      statisticType="size"
                      :searchParam="searchForm"
                    >
                    </data-content-statistic>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <div class="chartDiv">
                  <service-type-statistic
                    v-if="'size' === tabItem"
                    ref="chart11"
                    :style="chartDiv2"
                    statisticType="size"
                    :searchParam="searchForm"
                  >
                  </service-type-statistic>
                </div>
              </el-row>
            </el-col>
          </el-row> -->
        <!-- </el-tab-pane> -->
      </el-tabs>
    </div>

    <div id="tableArea">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="cellStyle"
        @cell-click="cellClick"
      >
        <el-table-column prop="xh" type="index" label="序号" width="45" align="center">
          <template slot-scope="scope">
            <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="recordNum" label="备案编号" width="95" align="center" />
        <el-table-column prop="serveYear" label="服务年份" width="80" align="center" />
        <el-table-column prop="fileNum" label="收文编号" width="80" align="center" />
        <el-table-column prop="useUnit" label="使用单位" header-align="center" show-overflow-tooltip />
        <el-table-column prop="dataUse" label="申请用途" header-align="center" show-overflow-tooltip />
        <el-table-column prop="dataName" label="数据名称" header-align="center" show-overflow-tooltip />
        <el-table-column
          prop="dataSizes"
          label="数据量"
          width="95"
          header-align="center"
          align="right"
          show-overflow-tooltip
        />
        <el-table-column prop="dataRangeText" label="数据范围" header-align="center" show-overflow-tooltip />
        <el-table-column prop="issueDate" label="分发时间" width="95" align="center" show-overflow-tooltip />
        <el-table-column prop="taskType" label="任务分类" width="80" align="center" show-overflow-tooltip />
        <el-table-column prop="usingReceiver" label="领取人" width="80" align="center" show-overflow-tooltip />
      </el-table>
    </div>
    <div id="tableFooter">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import DataRangeStatistic from '@/components/business/DataRangeStatistic.vue'
import DataTypeStatistic from '@/components/business/DataTypeStatistic.vue'
import ServiceTimesStatistic from '@/components/business/ServiceTimesStatistic.vue'
import DataContentStatistic from '@/components/business/DataContentStatistic.vue'
import UseUnitStatistic from '@/components/business/UseUnitStatistic.vue'
import ServiceTypeStatistic from '@/components/business/ServiceTypeStatistic.vue'
import moment from 'moment'
import SummaryPane from '@/components/business/Summary.vue'
import RecordDetail from '@/components/business/RecordDetail.vue'

export default {
  name: 'Statistics',
  components: {
    ServiceTimesStatistic,
    DataRangeStatistic,
    DataTypeStatistic,
    DataContentStatistic,
    UseUnitStatistic,
    ServiceTypeStatistic,
    SummaryPane,
    RecordDetail,
  },
  data() {
    return {
      formLabelWidth: '90px',
      searchForm: {},
      form: {},
      form2: {},
      //   activeIndex: '4',
      publishFormVisible: false,
      saveFormVisible: false,
      serviceId: '',
      recordNum: '',
      topics: [],
      topic: '',

      dataClasses: [],
      taskTypes: [],
      organizations: [],
      orgClasses: [],
      projects: [],

      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },

      rowHeight: 210,
      tabItem: 'times',

      today: '',
      serviceTimes: 0,
      dataSize: 0,
      dataSizeUnit: 'GB',
      useUnits: 0,

      page: 1,
      pageSize: 10,
      nxtPage: 1,
      total: 1,

      loading: false,
      tableData: [],
      readonlyFormVisible: false,
    }
  },
  methods: {
    search() {
      this.nxtPage = 1
      this.loadTable()
      for (let i = 0; i < 12; i++) {
        if (this.$refs['chart' + i] && this.$refs['chart' + i].genChart) {
          this.$refs['chart' + i].genChart()
        }
      }
    },
    loadTopics() {
      this.getRequest('/server/bookmark/')
        .then((res) => {
          this.topics = res
        })
        .catch((err) => {})
    },
    handleCurrentChange(val) {
      this.nxtPage = val
      this.loadTable()
    },
    deleteTopic(index, id) {
      this.deleteRequest('/server/bookmark/' + id)
        .then((res) => {
          if (res && res.code === 200) {
            this.topics.splice(index, 1)
          }
        })
        .catch((err) => {})
    },
    saveTopic() {
      this.form.content = JSON.stringify(this.searchForm)
      this.postRequest('/server/bookmark/', this.form)
        .then((res) => {
          if (res && res.code === 200) {
            if (!this.form.id || this.form.id === '') {
              this.form.id = res.obj
            }
            this.topics.push(this.form)
          }
        })
        .catch((err) => {})
      this.saveFormVisible = false
    },
    editTopic() {
      this.saveFormVisible = true
      this.form = {}
    },
    topicSelect(val) {
      this.searchForm = JSON.parse(val)
    },
    editPublish() {
      this.publishFormVisible = true
      this.form2 = {}
    },
    publish() {
      this.publishFormVisible = false
    },
    loadTable() {
      this.loading = true
      let requestData = JSON.parse(JSON.stringify(this.searchForm))
      requestData.currentPage = this.nxtPage
      requestData.pageSize = this.pageSize
      this.postRequest('/server/data-issue/statisticsRecord', requestData)
        .then((res) => {
          if (res) {
            this.tableData = res.data
            this.loading = false
            this.page = this.nxtPage
            this.total = res.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cellStyle({ row, columnIndex }) {
      if (columnIndex === 1) {
        return 'color : #2659c4;cursor:pointer;'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '备案编号') {
        this.serviceId = row.id
        this.recordNum = row.recordNum
        this.readonlyFormVisible = true
        //this.readonly = true
      }
    },
    onClose() {
      //this.resetServiceId()
    },
    resetServiceId() {
      this.serviceId = undefined
      this.recordNum = undefined
    },
  },
  created() {
    this.loadTable()
    LocalConfig.loadDataClassesFromConfig(true).then((result) => (this.dataClasses = result))
    LocalConfig.loadTaskTypeFromConfig(true).then((result) => (this.taskTypes = result))
    LocalConfig.loadOrganizationsFromConfig(true).then((result) => (this.organizations = result))
    LocalConfig.loadOrgClassesFromConfig(true).then((result) => (this.orgClasses = result))
    LocalConfig.loadProjectFromConfig(true).then((result) => (this.projects = result))

    // this.getServiceTimes()
    // this.getServiceUseUnitsCount()
    // this.getServiceDataSize()
  },
  mounted() {
    this.loadTopics()
  },
  computed: {
    chartDiv: function () {
      return {
        width: '100%',
        height: this.rowHeight + 'px',
      }
    },
    chartDiv1: function () {
      return {
        width: '100%',
        height: (this.rowHeight * 2) / 1 + 'px',
      }
    },
    chartDiv2: function () {
      return {
        width: '95%',
        height: (this.rowHeight * 2) / 2 + 'px',
      }
    },
    chartDiv3: function () {
      return {
        width: '100%',
        height: (this.rowHeight * 2) / 3 + 'px',
      }
    },
    todayString: function () {
      return moment().format('YYYY年M月D日 ')
    },
  },
}
</script>

<style scoped>
#searchPane {
  padding: 12px 1em 0;
  background-color: white;
}
#tableArea,
#chartArea {
  margin: 0.5em 0em;
}
#tableFooter {
  text-align: center;
  background: white;
  margin: 0.5em 0em;
  padding: 0.5em;
}

#chartArea {
  background-color: white;
}
#summary {
  margin: 0 0em 0.5em 0em;
}
.tabItemLabel {
  padding: 0px 10px;
  line-height: 20px;
  white-space: pre-line;
  word-wrap: break-word;
  width: 16px;
  height: 220px;
  display: flex;
  align-items: center;
}
</style>
<style>
#statistics .el-tabs--border-card {
  box-shadow: 0 0px 0px #fff;
}
#statistics .el-tabs__item {
  height: 220px;
  padding: 0px;
}
#statistics .el-tabs__nav-next,
#statistics .el-tabs__nav-prev {
  display: none;
}
#statistics div.el-tabs__nav-wrap,
#statistics .el-tabs__content {
  padding: 0px;
}
#statistics .el-tabs__header.is-left {
  margin-right: 0px;
}
#statistics .el-card {
  border: 0px;
}
#statistics .el-card__body {
  padding: 0px;
}
.el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: none;
}
</style>
