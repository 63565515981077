<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex">
        <el-input class="addInstitutionInput" size="mini" placeholder="机构名称" v-model="org.name"> > </el-input>
        <el-button size="mini" icon="el-icon-search" type="primary" @click="search">查询</el-button>
        <el-button aria-setsize="mini" icon="el-icon-plus" type="success" @click="append">添加</el-button>
      </div>
      <div>
        <el-button size="mini" type="success" @click="downloadTemplate"
          ><i class="fa fa-angle-double-down" aria-hidden="true"></i>导出模板
        </el-button>
        <el-button size="mini" type="success" @click="dialogReadFileVisible = true">
          <i class="fa fa-angle-double-up" aria-hidden="true"></i>导入数据
        </el-button>
      </div>
    </div>
    <div id="tableArea">
      <el-table
        :data="tableData"
        stripe
        @selection-change="handleSelectionChange"
        style="width: 100%; margin-top: 10px"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="class2ObjName" label="隶属机构" width="250"></el-table-column>
        <el-table-column prop="name" label="单位名称" show-overflow-tooltip width="300"></el-table-column>
        <el-table-column prop="alias" label="单位简称" width="120"></el-table-column>
        <el-table-column prop="orgProperty" label="单位性质" width="120"></el-table-column>
        <el-table-column prop="orgClass" label="单位分类" width="100"></el-table-column>
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            <el-tag type="info" size="mini" v-if="scope.row.obsolete">废止</el-tag>
            <el-tag type="success" size="mini" v-else>在用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="head" label="负责人"></el-table-column>
        <el-table-column prop="telephone" label="电话"></el-table-column>
        <el-table-column prop="email" label="邮件"></el-table-column> -->
        <!-- <el-table-column label="历史名称" prop="historyName" show-overflow-tooltip></el-table-column> -->
        <el-table-column label="顺序" prop="seq" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)"> 编辑</el-button>
            <el-button type="text" @click="remove(scope.row)"> 删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="justify-content: space-between; width: 100%">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--添加单位对话框-->
    <el-dialog :title="addOrEdit" :visible.sync="dialogFormVisible">
      <el-form :model="institution" size="mini" :rules="rules" ref="addOrEditForm">
        <el-form-item label="隶属机构" :label-width="formLabelWidth">
          <el-select
            v-model="institution.orgId"
            placeholder="请选择"
            clearable
            filterable
            @visible-change="getOrgIds"
            style="width: 100%"
            prop="orgId"
          >
            <el-option
              v-for="(option, index) in orgIds"
              :label="option.name"
              :value="option.id"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="institution.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单位简称" :label-width="formLabelWidth">
          <el-input v-model="institution.alias" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否废止" :label-width="formLabelWidth">
          <el-switch v-model="institution.obsolete"></el-switch>
        </el-form-item>
        <el-form-item label="曾用名" :label-width="formLabelWidth">
          <el-select v-model="institution.historyId" placeholder="无" style="width: 100%">
            <el-option
              v-for="(option, index) in historyOptions"
              :label="option.name"
              :value="option.id"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位性质" :label-width="formLabelWidth">
          <el-select
            v-model="institution.orgProperty"
            placeholder="请选择单位性质"
            @visible-change="getOrgProperties"
            allow-create
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="(option, index) in orgProperties"
              :label="option"
              :value="option"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位分类" :label-width="formLabelWidth">
          <el-select v-model="institution.orgClass" placeholder="请选择单位分类" style="width: 100%">
            <el-option v-for="item in orgClasses" :key="item.id" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="单位地址" :label-width="formLabelWidth" prop="address">
          <el-input v-model="institution.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="institution.remark" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="顺序" :label-width="formLabelWidth" prop="seq">
          <el-input v-model.number="institution.seq" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer span-center">
        <el-button type="primary" @click="addUnit()">确定</el-button>
        <el-button @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <!--数据导入对话框-->
    <el-dialog title="导入数据" :visible.sync="dialogReadFileVisible" width="45%">
      <input type="file" @change="readExcleFile" />
      <div style="width: 100%; height: 380px; overflow-y: auto">
        <el-table :data="readFileData" style="width: 100%">
          <el-table-column prop="org" label="机构"></el-table-column>
          <el-table-column prop="org2" label="二级机构"></el-table-column>
          <el-table-column prop="name" label="单位名称"></el-table-column>
          <el-table-column prop="alias" label="简称"></el-table-column>
          <el-table-column prop="unitClass" label="单位性质"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer span-center">
        <el-button @click="dialogReadFileVisible = false">取消</el-button>
        <el-button type="primary" @click="saveDataBatch">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import * as LocalConfig from '@/utils/base'
export default {
  name: 'Institution',
  data() {
    return {
      org: {},
      addOrEdit: '添加',
      dialogFormVisible: false,
      formLabelWidth: '90px',
      rules: {
        orgId: [{ required: true, message: '请选择隶属机构', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              if (this.addOrEdit === '添加') {
                await this.getRequest('/server/institution/check', { name: value }).then((resp) => {
                  if (resp && resp.obj) {
                    callback(new Error('名称已经存在'))
                  }
                })
                callback()
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        address: [{ max: 100, message: '长度在为100字以内', trigger: 'blur' }],
        seq: [
          {
            transform(value) {
              return Number(value)
            },
            validator: (rule, value, callback) => {
              if (value && /^(0|[1-9]\d?|1000000)$/.test(value) == false) {
                callback(new Error('顺序范围在0-1000000'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
      ids: [], //用户选中的行
      dialogVisible: false,
      dialogReadFileVisible: false,
      searchKey: '',
      readFileData: [],
      tableData: [],
      page: 1,
      pageSize: 10,
      total: 100,
      institution: {
        id: '',
        name: '',
        alias: '',
        orgId: '',
        orgProperty: '',
        address: '',
        orgClass: '',
        serviceClass: '',
        remark: '',
        obsolete: false,
        historyId: '',
      },
      orgClasses: [],
      orgIds: [],
      orgProperties: [],
      historyOptions: [], //是否是历史项目选择项
    }
  },
  methods: {
    search() {
      this.page = 1
      this.loadTable()
    },
    loadHistoryOptions() {
      this.getRequest('/server/institution/historyOption').then((resp) => {
        if (resp) {
          this.historyOptions = resp
          this.historyOptions.push({ id: '', name: '无' })
        }
      })
    },
    handleSelectionChange(val) {
      this.ids = []
      val.forEach((item) => {
        this.ids.push(item.id)
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.loadTable()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.loadTable()
    },
    saveDataBatch() {
      //保存机构数据
      let org = {}
      let org2 = {}
      let rows = []
      this.readFileData.forEach((row) => {
        if (row.org != null && row.org != '') {
          org[row.org] = row.org
        }
        if (row.org2 != null && row.org2 != '') {
          org2[row.org2] = { name: row.org2, org: row.org }
        }
      })
      //格式化一级机构
      for (var key in org) {
        let o = JSON.parse(JSON.stringify(this.institution))
        o.name = key
        rows.push(o) //一级机构
      }
      //格式化二级机构
      for (var key in org2) {
        let o = JSON.parse(JSON.stringify(this.institution))
        o.name = org2[key].name
        o.orgId = org2[key].org
        rows.push(o)
      }
      //格式化单位
      this.readFileData.forEach((row) => {
        let o = JSON.parse(JSON.stringify(this.institution))
        o.name = row.name
        o.alias = row.alias
        o.orgProperty = row.orgProperty
        o.orgClass = row.unitClass
        if (row.org2 != null && row.org2 != '') {
          o.orgId = row.org2
        } else {
          o.orgId = row.org
        }
        rows.push(o)
      })
      //向数据库推送数据
      this.postRequest('/server/institution/inputBatch', rows).then((resp) => {
        if (resp) {
          this.loadTable()
          this.dialogReadFileVisible = false
          LocalConfig.loadOrganizationsFromConfig(true)
          LocalConfig.loadTaskSourceUnitsFromConfig(true)
        }
      })
    }, //批量保存数据
    downloadTemplate() {
      window.location.href = '/template/department.xlsx'
    },
    readExcleFile(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null
      readXlsxFile(xlsxfile).then((rows) => {
        this.readFileData = [] //重置数据
        let rowHead = rows[0] //第一行是头
        try {
          for (let i = 1; i < rows.length; i++) {
            let row = {}
            rowHead.forEach((head) => {
              let colIndex = rowHead.findIndex((colName) => {
                return head === colName
              })
              switch (head) {
                case '机构':
                  row.org = rows[i][colIndex]
                  break
                case '二级机构':
                  row.org2 = rows[i][colIndex]
                  break
                case '单位名称':
                  row.name = rows[i][colIndex]
                  break
                case '单位简称':
                  row.alias = rows[i][colIndex]
                  break
                case '单位性质':
                  row.orgProperty = rows[i][colIndex]
                  break
                case '单位分类':
                  row.unitClass = rows[i][colIndex]
                  break
              }
            })
            this.readFileData.push(row)
          }
        } catch (err) {
          this.$message({
            type: 'error',
            message: '加载的数据模板不正确，模板应该包含：<' + rowHead.toString() + '>字段',
          })
        }
      })
    },
    append(data) {
      //新增节点
      //开启添加对话框
      //重置对象
      this.addOrEdit = '添加'
      //Object.assign(this.$data.institution, this.$options.data().institution)
      this.institution = {}
      this.dialogFormVisible = true
    },
    edit(data) {
      //编辑节点
      this.addOrEdit = '编辑'
      //Object.assign(this.$data.institution, this.$options.data().institution)
      this.institution = data
      this.dialogFormVisible = true
    },
    addUnit() {
      this.$refs['addOrEditForm'].validate((valid) => {
        if (valid) {
          //添加或者更新数据
          let request = null
          if (this.addOrEdit === '添加') {
            request = this.postRequest
          } else {
            request = this.putRequest
          }
          request('/server/institution/', this.institution).then((resp) => {
            if (resp) {
              this.dialogFormVisible = false
              this.loadTable()
              LocalConfig.loadOrganizationsFromConfig(true)
              LocalConfig.loadTaskSourceUnitsFromConfig(true)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    remove(data) {
      //删除节点
      //删除单位
      this.$confirm('此操作将删除申请、来源单位<' + data.name + '>, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/institution/' + data.id).then((resp) => {
            if (resp) {
            }
            this.loadTable()
            LocalConfig.loadOrganizationsFromConfig(true)
            LocalConfig.loadTaskSourceUnitsFromConfig(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    loadTable() {
      this.loading = true
      let requestData = {}
      requestData.currentPage = this.page
      requestData.pageSize = this.pageSize
      requestData.name = this.org ? this.org.name : null
      this.getRequest('/server/institution/byPage', requestData).then((resp) => {
        if (resp) {
          this.tableData = resp.data
          this.total = resp.total
          this.loading = false
        }
      })
    },
    showDialog() {
      this.dialogVisible = true
    },
    getOrgIds() {
      this.getRequest('/server/institution/orgIds').then((resp) => {
        if (resp) {
          this.orgIds = resp
        }
      })
    },
    getOrgProperties() {
      this.getRequest('/server/institution/allOrgProperty').then((resp) => {
        if (resp) {
          this.orgProperties = resp
        }
      })
    },
  },
  created() {
    LocalConfig.loadOrgClassesFromConfig(true).then((result) => {
      this.orgClasses = result
    })
    this.getOrgIds()
    this.loadTable()
    this.loadHistoryOptions()
  },
}
</script>

<style scoped>
.addInstitutionInput {
  width: 300px;
  margin-right: 10px;
}
.queryCommon {
  width: 300px;
  margin-right: 10px;
}
#tableArea td .cell {
  display: block; /* 需要 */
  white-space: nowrap; /* 过长部分不换行 */
  text-overflow: ellipsis; /* 过长部分展示省略号 */
  overflow: hidden; /* 隐藏过长部分 */
}
</style>
<style>
.dialog-footer {
  text-align: center;
}
</style>
