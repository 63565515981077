<template>
  <div ref="main" id="main"></div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import * as echarts from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

export default {
  name: 'UseUnitStatistic',
  components: {},
  props: {
    statisticType: {
      type: String,
      default: 'times',
    },
    searchParam: {
      type: Object,
      default: () => ({
        type: 'times',
        target: 'useUnit',
      }),
    },
  },
  data() {
    return {
      myChart: null,
      option: {
        title: {
          text: '{black|}{white|}使用单位（次）',
          // subtext: 'Data from Wikipedia',
          textStyle: {
            fontSize: 14,
            rich: {
              black: {
                width: 3,
                borderRadius: 5,
                backgroundColor: '#6D6D6D',
              },
              white: {
                width: 6,
                backgroundColor: '#FFF',
              },
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        label: {
          show: false,
        },
        // legend: {},
        grid: {
          left: '5px',
          right: '10px',
          top: '30px',
          bottom: '5px',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          minInterval: 1,
        },
        yAxis: {
          type: 'category',
          data: [],
          minInterval: 1,
        },
        series: [
          {
            // name: '2012',
            type: 'bar',
            data: [],
            itemStyle: {
              borderRadius: 5,
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: '#B6CDFD' },
                { offset: 0.4, color: '#8EAEF2' },
                { offset: 1, color: '#4C7ADF' },
              ]),
            },
          },
        ],
      },
    }
  },
  methods: {
    genChart() {
      if (this.myChart) {
        this.myChart.dispose()
      }
      let chartDom = this.$refs['main']
      this.myChart = echarts.init(chartDom)
      this.myChart.showLoading()
      this.searchParam.type = this.statisticType
      this.searchParam.target = 'useUnit'
      let requestData = JSON.parse(JSON.stringify(this.searchParam))
      this.postRequest('/server/data-issue/statistic', requestData)
        .then(async (res) => {
          if (res && res.obj) {
            this.option.series[0].data = []
            let that = this
            let labels = []

            if (this.searchParam.orgClass.length == 0) {
              let result = await LocalConfig.loadOrgClassesFromConfig(true)
              result.forEach((orgClass) => {
                labels.push(orgClass.name)
                this.option.series[0].data.push(0)
              })
              labels.reverse()
            } else {
              this.searchParam.orgClass.forEach((orgClass) => {
                labels.push(orgClass)
                this.option.series[0].data.push(0)
              })
            }

            let dataSize = 0,
              dataMulti = 1,
              dataUnit = 'KB'
            if (this.statisticType == 'size') {
              res.obj.forEach((row) => {
                if (row.value && dataSize < Number(row.value)) {
                  dataSize = Number(row.value)
                  let result = LocalConfig.parseDataMulti(dataSize)
                  dataMulti = result[0]
                  dataUnit = result[1]
                }
              })
              this.option.title.text = '{black|}{white|}使用单位(' + dataUnit + ')'
            }

            res.obj.forEach((row) => {
              //改为所有orgclass
              //labels.push(row.name)
              labels.some((value, index) => {
                if (value === row.name) {
                  if (that.statisticType == 'size') {
                    that.option.series[0].data[index] = (row.value / dataMulti).toFixed(2)
                  } else {
                    that.option.series[0].data[index] = row.value
                  }
                  return true
                }
              })
            })
            if (labels.length < 7) {
              that.option.series[0].barWidth = 15
            } else {
              that.option.series[0].barWidth = 'auto'
            }
            that.option.yAxis.data = labels
            this.myChart.hideLoading()
            this.option && this.myChart.setOption(this.option)
          }
        })
        .catch((err) => {
          console.log(err)
          this.myChart.hideLoading()
        })
    },
  },
  created() {
    echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout])
  },
  mounted() {
    this.genChart()
    let that = this
    window.addEventListener('resize', () => {
      setTimeout(() => {
        that.myChart.resize()
      }, 150)
    })
  },
}
</script>

<style></style>
