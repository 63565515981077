<template>
  <el-form
    :model="editForm"
    size="mini"
    :label-width="formLabelWidth"
    :disabled="disabled"
    :rules="rules"
    ref="editForm"
  >
    <!-- <el-row v-if="editForm.id">
      <div style="float: right; margin: -10px 0 5px">
        <el-button type="danger" @click="deleteIt()" icon="el-icon-delete" circle></el-button>
      </div>
    </el-row> -->
    <el-row>
      <el-col :span="8">
        <el-form-item label="生产编号">
          {{ editForm.dataNum }}
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="服务类型" prop="serverType">
          <el-select v-model="editForm.serverType" placeholder="请选择" clearable style="width: 100%">
            <el-option v-for="item in serviceTypes" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="任务分类" prop="taskType">
          <el-select v-model="editForm.taskType" placeholder="请选择" style="width: 100%">
            <el-option v-for="task in taskTypes" :key="task.id" :label="task.name" :value="task.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1">
        <div style="float: right">
          <el-button type="danger" @click="deleteIt()" :disabled="disabled" icon="el-icon-delete" circle></el-button>
        </div>
      </el-col>
      <el-col :span="8">
        <el-form-item label="数据分类" id="dataform-dataclass" prop="dataClass">
          <el-select
            v-model="editForm.dataClass"
            multiple
            placeholder="请选择"
            @change="handleDataClassesChange"
            class="do-not-adjust"
            style="width: 100%"
          >
            <el-option v-for="item in dataClasses" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="数据年份">
          <el-input v-model="editForm.dataYear"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="数据名称" prop="dataName" ref="dataName">
          <el-input v-model="editForm.dataName" autocomplete="off" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="数据内容" prop="dataContent">
          <el-input v-model="editForm.dataContent" type="textarea" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="editForm.id">
      <el-col :span="24" class="dataRangeCol">
        <el-form-item label="数据范围" prop="dataRangeWithFiles">
          <el-input type="text" :v-show="false">
            <el-cascader
              slot="prepend"
              id="dataform-datarange"
              placeholder="请选择"
              :props="cityProps"
              :options="cityOptions"
              clearable
              filterable
              v-model="editForm.dataRangeWithFiles"
              @change="
                (event) => {
                  handleDataRangeChange(event, editForm)
                }
              "
              @remove-tag="
                (id) => {
                  removeDataRangeText(id)
                }
              "
              class="do-not-adjust"
              style="width: 100%"
              :show-all-levels="false"
              ref="dataRange"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.name }}</span>
              </template>
            </el-cascader>
            <upload-item
              slot="append"
              ref="importDataRangeBtn"
              :referenceId="editForm.id"
              tab="DataForm"
              type="范围文件"
              :disabled="disabled"
              :showButton="true"
              text="导入数据范围文件"
              :reloadCallBack="
                (fileList) => {
                  updateRangeText(fileList)
                }
              "
              :showFileList="false"
              class="importedDataRage"
            />
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="!editForm.id">
      <el-form-item label="数据范围" prop="dataRangeWithFiles">
        <el-cascader
          id="dataform-datarange"
          placeholder="请选择"
          :props="cityProps"
          :options="cityOptions"
          clearable
          filterable
          v-model="editForm.dataRangeWithFiles"
          @change="
            (event) => {
              handleDataRangeChange(event, editForm)
            }
          "
          class="do-not-adjust"
          style="width: 100%"
          :show-all-levels="false"
          ref="dataRange"
        >
          <template slot-scope="{ node, data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label="矢量数据范围文件">
          <el-radio v-model="editForm.vectorDataRangeFileHas" :label="true">有</el-radio>
          <el-radio v-model="editForm.vectorDataRangeFileHas" :label="false">无</el-radio>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="矢量文件名" prop="vectorDataRangeFileName">
          <el-input v-model="editForm.vectorDataRangeFileName" :disabled="!editForm.vectorDataRangeFileHas"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="成果存放位置" prop="vectorDataRangePath">
          <el-input v-model="editForm.vectorDataRangePath" :disabled="!editForm.vectorDataRangeFileHas"> </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label="数据类型">
          <el-select v-model="editForm.dataType" placeholder="请选择" style="width: 100%" multiple>
            <el-option v-for="item in dataTypes" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="数据格式">
          <el-select v-model="editForm.dataFormat" placeholder="请选择" style="width: 100%" multiple>
            <el-option v-for="item in dataFormats" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8" style="display: flex">
        <el-form-item label="数据量" class="datasizeitem" ref="datasizeitem" prop="dataSize">
          <el-input v-model="editForm.dataSize" class="datasize" />
        </el-form-item>
        <el-form-item
          label=""
          label-width="0"
          class="datasizeunit"
          ref="datasizeunit"
          prop="dataSizeUnit"
          :rules="[{ required: true, message: '请选择单位', trigger: ['blur', 'change'] }]"
        >
          <el-select v-model="editForm.dataSizeUnit" placeholder="请选择" class="do-not-adjust">
            <el-option label="EB" value="EB" />
            <el-option label="PB" value="PB" />
            <el-option label="TB" value="TB" />
            <el-option label="GB" value="GB" />
            <el-option label="MB" value="MB" />
            <el-option label="KB" value="KB" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label="使用的原数据">
          <el-select v-model="editForm.originalData" placeholder="请选择" style="width: 100%">
            <el-option v-for="data in originalDataList" :key="data.id" :label="data.dataName" :value="data.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8"
        ><el-form-item label="版本信息" prop="version">
          <el-input v-model="editForm.version"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="挂靠项目" prop="project">
          <el-input v-model="editForm.project"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8"
        ><el-form-item label="生产单位">
          <el-select
            v-model="editForm.produceUnit"
            allow-create
            clearable
            filterable
            placeholder="请选择或输入"
            style="width: 100%"
          >
            <el-option v-for="org in organizations" :key="org.id" :label="org.name" :value="org.id" />
          </el-select> </el-form-item
      ></el-col>

      <el-col :span="8"
        ><el-form-item label="生产负责人" prop="produceManager">
          <el-input v-model="editForm.produceManager"> </el-input> </el-form-item
      ></el-col>
      <el-col :span="8">
        <el-form-item label="生产起止时间">
          <el-date-picker
            v-model="editForm.produceDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @focus="prepareDate"
            format="yyyy年M月d日"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row style="display: flex">
      <div style="flex: 1 1 auto">
        <el-col :span="24">
          <el-form-item label="使用方单位" prop="useUnits">
            <el-select
              v-model="editForm.useUnits"
              placeholder="请选择"
              clearable
              style="width: 100%"
              multiple
              @visible-change="updateCompanies"
            >
              <el-option v-for="item in companies" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div :style="editForm.id ? 'width: 290px' : 'width: 140px'" v-if="showWatermartButtons">
        <el-form-item label="" label-width="0">
          <span v-if="editForm.id" style="margin-left: 30px; float: right">
            <input
              v-show="false"
              ref="fileRef"
              type="file"
              :v-model="editForm.file"
              @change="
                (event) => {
                  handleUploadFile(event)
                }
              "
            />
            <el-button type="success" style="width: 129px" :disabled="disabled" @click="fireUpload()"
              >导入水印</el-button
            >
          </span>
          <el-button
            type="success"
            style="width: 129px; float: right"
            :disabled="disabled"
            @click="viewWatermark(editForm)"
            >生成水印</el-button
          >
        </el-form-item>
      </div>
    </el-row>
    <el-row v-if="showWatermartButtons">
      <el-col :span="8">
        <el-form-item label="水印信息单号">
          <el-input
            v-model="editForm.watermark"
            autocomplete="off"
            style="width: 100%"
            :readonly="true"
            :disabled="!editForm.watermark"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="水印嵌入时间">
          <el-date-picker
            v-model="editForm.watermarkTime"
            type="datetime"
            placeholder="请选择日期时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
            align="right"
            :picker-options="pickerOptions"
            :readonly="true"
            :disabled="!editForm.watermark"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="备注">
          <el-input
            v-model="editForm.memo"
            autocomplete="off"
            style="width: 100%"
            :readonly="true"
            :disabled="!editForm.watermark"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <span class="dialog-footer span-center" v-if="!disabled">
        <el-button type="primary" @click="submitForm()" :loading="saving">{{
          editForm.id ? '更新' : '保存'
        }}</el-button>
        <el-button @click="notifyCloseDialog"> 返回 </el-button>
      </span>
    </el-row>
    <div v-if="editForm.id">
      <el-divider content-position="left"> 文件材料 </el-divider>
      <el-row>
        <el-form-item label="">
          <el-table :data="documents" stripe style="width: 100%" border>
            <el-table-column prop="number" label="序号" align="center" width="60px">
              <template slot-scope="scope">
                <span>{{ '200' + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="分类" header-align="center" show-overflow-tooltip width="150px" />
            <el-table-column label="文件名称" header-align="center" show-overflow-tooltip>
              <template #default="scope">
                <upload-item
                  v-if="scope.row.type != '范围文件'"
                  :referenceId="editForm.id"
                  tab="DataForm"
                  :type="scope.row.type"
                  :disabled="disabled"
                  :ref="'upload' + scope.$index"
                />
                <upload-item
                  v-if="scope.row.type == '范围文件'"
                  :referenceId="editForm.id"
                  tab="DataForm"
                  :type="scope.row.type"
                  :disabled="disabled"
                  ref="upload"
                  :reloadCallBack="
                    (fileList) => {
                      updateRangeText(fileList)
                    }
                  "
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60px" v-if="!disabled" align="center">
              <template #default="scope">
                <el-button
                  type="text"
                  @click="$refs['upload' + scope.$index].save()"
                  v-if="scope.row.type != '范围文件'"
                >
                  上传
                </el-button>
                <el-button type="text" @click="$refs['upload'].save()" v-if="scope.row.type == '范围文件'">
                  上传
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import * as LocalConfig from '@/utils/base'
import { Loading } from 'element-ui'
import UploadItem from '@/components/business/UploadItem.vue'
import rsa from '@/utils/RSA'
import moment from 'moment'
import { saveAs } from 'file-saver'

export default {
  name: 'Produce',
  components: { UploadItem },
  props: {
    model: {},
    serviceId: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    dataTypes: [],
    taskTypes: [],
    dataFormats: [],
    dataClasses: [],
    originalDataList: [],
    serviceTypes: [],
    //生产单位
    organizations: [],
    //使用方单位
    companies: [],
    showWatermartButtons: {
      type: Boolean,
      default: true,
    },
    commonCityOptions: [],
  },
  data() {
    return {
      saving: false,
      cityProps: {
        value: 'id',
        label: 'fullname',
        children: 'children',
        checkStrictly: true,
        multiple: true,
      },
      cityOptions: [],
      formLabelWidth: '120px',
      editForm: {},
      taskFrom: null,
      rules: {
        taskType: [{ required: true, message: '请选择任务分类', trigger: 'blur' }],
        dataClass: [{ required: true, message: '请选择数据分类', trigger: 'blur' }],
        dataContent: [{ required: true, message: '请填写数据内容', trigger: 'blur' }],
        dataName: [
          { required: true, message: '请填写数据名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在1到100个字符', trigger: 'blur' },
        ],
        dataSize: [
          { required: true, message: '请填写数据量', trigger: 'blur' },
          {
            transform(value) {
              return Number(value)
            },
            validator: (rule, value, callback) => {
              if (Number.isFinite(value) && value >= 0) {
                callback()
              } else {
                callback(new Error('请输入大于0的数字'))
              }
            },
            trigger: 'blur',
          },
        ],
        serverType: [{ required: true, message: '请选择服务类型', trigger: 'blur' }],
        useUnits: [{ required: true, message: '请选择使用方单位', trigger: 'blur' }],
        dataRangeWithFiles: [{ required: true, message: '请选择数据范围', trigger: 'blur' }],
        vectorDataRangeFileName: [{ max: 30, message: '最大长度30个字符', trigger: 'blur' }],
        vectorDataRangePath: [{ max: 100, message: '最大长度100个字符', trigger: 'blur' }],
        version: [{ max: 20, message: '最大长度20个字符', trigger: 'blur' }],
        project: [{ max: 30, message: '最大长度30个字符', trigger: 'blur' }],
        produceManager: [{ max: 30, message: '最大长度30个字符', trigger: 'blur' }],
      },
      pickerOptions: {
        disabledDate: (time) => {
          //   let taskFrom = this.taskFrom
          //   if (taskFrom) {
          //     return (
          //       time.getTime() < new Date(taskFrom).getTime() ||
          //       time.getTime() > new Date(moment().format('YYYY-MM-DD')).getTime()
          //     )
          //   }else{
          return time.getTime() > new Date(moment().format('YYYY-MM-DD')).getTime()
          //   }
        },
      },
      documents: [{ type: '数字水印任务文件' }, { type: '数据生产材料' }, { type: '范围文件' }, { type: '其他材料' }],
    }
  },
  methods: {
    prepareDate() {
      setTimeout(() => {
        if (this.datePickerLoadingInstance) {
          this.datePickerLoadingInstance.close()
        }
        this.datePickerLoadingInstance = Loading.service({ target: '.el-picker-panel__body-wrapper' })
        this.getTaskFrom().then((result) => {
          this.datePickerLoadingInstance.close()
        })
      }, 200)
    },
    async getTaskFrom() {
      if (!this.serviceId) {
        return
      }
      let result = null
      await this.getRequest('/server/record/task/from/' + this.serviceId)
        .then((res) => {
          result = res.obj ? res.obj : null
          this.taskFrom = result
        })
        .catch((err) => {
          console.log(err)
        })
      return result
    },
    async updateCompanies() {
      this.$emit('notifyUpdateCompanies')
    },
    async viewWatermark(editForm) {
      this.$emit('viewWatermark', editForm)
    },
    fireUpload() {
      this.$refs['fileRef'].value = ''
      this.$refs['fileRef'].click()
    },
    handleUploadFile(event) {
      let editForm = this.editForm
      let that = this
      const selectedFile = event.target.files[0]
      if (selectedFile.type.includes('json') || selectedFile.type === '') {
        let reader = new FileReader() // !important
        reader.readAsText(selectedFile, 'UTF-8') // !important
        reader.onload = (evt) => {
          // !important
          let content = evt.target.result // !important

          content = rsa.decryptData(content)
          try {
            let json = JSON.parse(content)
            if (json) {
              if (json.备案编号 && json.备案编号 == editForm.dataNum) {
                editForm.watermark = json.水印信息单号
                editForm.memo = json.备注
                editForm.watermarkTime = json.水印嵌入时间
              } else {
                this.$message.error('水印文件不属于当前生产数据')
              }
            }
          } catch (e) {
            console.log(e)
            this.$message.error('解析json文件失败')
          }
        }
      } else {
        this.$message.error('文件格式不正确')
      }
    },
    prepareDataRange() {
      let editForm = this.editForm
      let dataRage = this.$refs['dataRange']
      if (dataRage && dataRage.$el) {
        let range = []
        dataRage.$el.querySelectorAll('div.el-cascader__tags span.el-tag span').forEach((item) => {
          range.push(item.innerHTML)
          editForm.dataRangeText = range.toString()
        })
      }
    },
    updateRangeText(fileList) {
      let editForm = this.editForm
      //选择文件
      let files = this.cityOptions.filter((city) => city.code && city.code.length != 6)
      let orderById = (a, b) => {
        if (a.id < b.id) {
          return -1
        } else if (a.id > b.id) {
          return 1
        } else if (a.id == b.id) {
          return 0
        }
      }
      files.sort(orderById)
      fileList.sort(orderById)
      if (files.length == fileList.length) {
        let i
        for (i = 0; i < files.length; i++) {
          if (files[i].id != fileList[i].id) {
            break
          }
        }
        //无更新需要
        if (i == files.length) {
          return
        }
      }
      let newOptions = this.cityOptions.filter((city) => city.code && city.code.length == 6)

      if (fileList && fileList.length > 0) {
        fileList.forEach((file) => {
          let range = {
            id: file.id,
            code: file.id,
            name: file.name,
            superName: '中华人民共和国行政区划',
            level: 'province',
            children: [],
            fullname: file.name,
          }
          newOptions.push(range)
          editForm.dataRangeWithFiles.push([range.id])
        })
      }
      this.cityOptions = newOptions
      //TODO::
      //this.prepareDataRange()
      //ajax update

      this.$refs['importDataRangeBtn'].loadFiles(fileList)
      this.$refs['upload'].loadFiles(fileList)
    },
    removeDataRangeText(id) {
      let editForm = this.editForm
      let deletedFile = this.cityOptions.filter((city) => {
        return city.code == id
      })

      if (deletedFile.length == 0) {
        return
      }

      this.$confirm('此操作将删除数据范围文件（' + deletedFile[0].name + '）, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$refs['upload'].handleRemove({ id: id, status: 'success' }, [])
          //TODO::
          //this.prepareDataRange()
          //ajax update
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    deleteIt() {
      let data = this.editForm
      this.$confirm('此操作将删除数据信息（' + data.dataNum + '）, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (data.id) {
            this.deleteRequest('/server/produce/' + data.id).then((res) => {
              if (res) {
                this.$emit('notifyDeleted', data.id)
              }
            })
          } else {
            this.$emit('notifyDeleted')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    submitForm() {
      let editForm = this.editForm
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          try {
            this.saving = true
            setTimeout(() => {
              if (this.saving == true) {
                this.saving = false
                this.$message.error('超时请重试')
              }
            }, 1000 * 60)
            this.save()
          } catch (err) {
            console.log(err)
            this.saving = false
          }
        } else {
          //console.log('error submit!!')
          return false
        }
      })
    },
    save() {
      let editForm = this.editForm
      this.prepareDataRange()

      let realDataRange = editForm.dataRangeWithFiles
      editForm.dataRangeWithFiles.forEach((id) => {
        if (this.cityOptions.filter((city) => city.id == id && city.code && city.code.length != 6).length > 0) {
          realDataRange = realDataRange.filter((rid) => {
            return id != rid
          })
        }
      })
      editForm.dataRange = realDataRange.length > 0 ? JSON.stringify(realDataRange) : JSON.stringify([])

      if (editForm.produceDate) {
        editForm.produceFromDate = editForm.produceDate[0]
        editForm.produceToDate = editForm.produceDate[1]
      }
      //   editForm.useUnits = editForm.useUnits ? JSON.stringify(editForm.useUnits) : null
      editForm.dataType = editForm.dataType ? JSON.stringify(editForm.dataType) : null
      editForm.dataFormat = editForm.dataFormat ? JSON.stringify(editForm.dataFormat) : null
      editForm.dataClass = editForm.dataClass ? JSON.stringify(editForm.dataClass) : null
      let requestData = JSON.parse(JSON.stringify(editForm))
      //   editForm.useUnits = editForm.useUnits ? JSON.parse(editForm.useUnits) : null
      editForm.dataType = editForm.dataType ? JSON.parse(editForm.dataType) : null
      editForm.dataFormat = editForm.dataFormat ? JSON.parse(editForm.dataFormat) : null
      editForm.dataClass = editForm.dataClass ? JSON.parse(editForm.dataClass) : null

      let request = editForm.id ? this.putRequest : this.postRequest
      request('/server/produce/', requestData)
        .then((res) => {
          this.saving = false
          if (res && res.code === 200) {
            let names = [],
              name = null
            editForm.useUnits.forEach((id) => {
              name = this.companies.filter((comp) => {
                return comp.id == id
              })
              if (name.length > 0 && name[0].name) {
                names.push(name[0].name)
              }
            })
            editForm.useUnitNames = names
            if (editForm.id == null) {
              editForm.id = res.obj
              this.$emit('notifyCreated', editForm)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.saving = false
        })
    },
    notifyCloseDialog() {
      this.$emit('notifyCloseDialog')
    },
    handleDataRangeChange(value, editForm) {},
    handleDataClassesChange(value) {},
    updateDataUnitItem() {
      let row = this.$refs['dataName']
      if (row && row.$el.offsetWidth > 0) {
        let width = row.$el.offsetWidth
        if (this.$refs['datasizeitem'].$el) {
          this.$refs['datasizeitem'].$el.style.width = width - 129 + 'px'
        }
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.updateDataUnitItem()
    })
    this.updateDataUnitItem()
  },
  created() {
    if (this.model) {
      this.editForm = this.model
      if (this.commonCityOptions) {
        this.cityOptions = JSON.parse(JSON.stringify(this.commonCityOptions))
      } else {
        LocalConfig.loadDistrictsFromConfig(true).then((result) => {
          this.cityOptions = result
        })
      }
    }
  },
  watch: {
    model: function (val, oldVal) {
      if (val) {
        this.editForm = this.model
      }
    },
  },
  computed: {},
}
</script>

<style scoped>
.span-center {
  width: 100%;
  text-align: center;
  display: block;
}
.importedDataRage {
  /* width: 94px;
  padding-left: 20px; */
}
.datasizeunit {
  width: 129px;
}
</style>
<style>
/* .datasizeitem .el-form-item__content {
  display: flex;
} */
.el-checkbox__label {
  font-size: 12px;
}
.dataRangeCol .el-input-group__prepend {
  width: 100%;
  padding: 0px;
}
.dataRangeCol .el-input__inner {
  /* display: none; */
  padding: 0px;
}
/* #watermarkDiv .el-form-item {
  margin-bottom: 0px;
} */
</style>
<style>
.el-form-item__content {
  font-size: 12px;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 12px;
}
/* #watermarkDiv .el-row .el-divider--horizontal {
  margin: 10px 0;
} */

/* update t_produce p 
set data_range=replace(data_range,r.id,r.code)
from t_region r 
where
p.data_range like '%' || r.id ||'%' */
</style>



