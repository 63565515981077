<template>
  <div style="width: 100%">
    <el-container class="el-container">
      <el-dialog :visible.sync="dialogVisible" title="修改密码" width="300px">
        <el-form ref="passwordForm" :model="passwordForm" :rules="rules" label-width="80px">
          <el-form-item label="当前密码" prop="currentPwd">
            <el-input show-password v-model="passwordForm.currentPwd"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd">
            <el-input show-password v-model="passwordForm.newPwd"></el-input>
          </el-form-item>
          <el-form-item label="重复新密码" prop="repeatPwd">
            <el-input show-password v-model="passwordForm.repeatPwd"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer span-center">
          <el-button type="primary" @click="submitForm">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
      <el-header id="homeHeader">
        <router-link to="/home/default"
          ><div class="title" @click="activeIndex = '/home/default'">数据服务备案管理系统</div></router-link
        >
        <div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#2659C4"
            text-color="#fff"
            router
            active-text-color="#fff"
          >
            <el-menu-item v-for="(item, index) in menus" :index="item.path" :key="index" v-if="!item.hidden">
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div>
          <span class="hello">
            <span>欢迎， {{ user.realname }}</span>
            <span style="width: 50px; display: inline-block; text-align: center">|</span>
            <span @click="showDialog" style="cursor: pointer">
              <i class="el-icon-lock el-icon--left"></i><span style="margin-left: 5px">修改密码</span>
            </span>
            <span @click="logout" style="cursor: pointer; margin-left: 20px">
              <i class="el-icon-switch-button el-icon--left"></i><span style="margin-left: 5px">注销</span>
            </span>
          </span>
        </div>
      </el-header>
      <el-container>
        <el-main style="padding: 0.5em 0.5em">
          <div>
            <!--设置在主视图中显示路由内容-->
            <router-view class="homeRouterView" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import * as LocalConfig from '@/utils/base'
export default {
  name: 'Home',
  data() {
    return {
      activeIndex: '/home/default',
      //当前登录的用户
      user: {
        //登录名
        username: '',
        //用户姓名
        realname: '',
      },
      dialogVisible: false,
      passwordForm: {
        currentPwd: '',
        newPwd: '',
        repeatPwd: '',
      },
      rules: {
        currentPwd: [
          { required: true, message: '请输入当前密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度应在6到20之间', trigger: 'blur' },
        ],
        newPwd: [
          { min: 6, max: 20, message: '密码长度应在6到20之间', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入新密码'))
              } else if (value === this.passwordForm.currentPwd) {
                callback(new Error('新密码不能与当前密码一样'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        repeatPwd: [
          { min: 6, max: 20, message: '密码长度应在6到20之间', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.passwordForm.newPwd) {
                callback(new Error('两次输入密码不一致'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true
      this.passwordForm = {
        currentPwd: '',
        newPwd: '',
        repeatPwd: '',
      }
    },
    submitForm() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.changPwd()
        } else {
          return false
        }
      })
    },
    changPwd() {
      this.putRequest('/system/settings/users/changePwd', this.passwordForm)
        .then((resp) => {
          if (resp && resp.obj) {
            this.dialogVisible = false
          }
        })
        .catch((err) => {
          this.$message.error('密码修改失败')
          console.log(err)
        })
    },
    logout() {
      this.$confirm('此操作将注销登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.postRequest('/logout')
          sessionStorage.clear()
          //清空个人属性
          this.$store.commit('initRoutes', [])
          this.$store.commit('setOperate', null)
          this.$router.replace({ path: '/' })
          location.reload()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消注销',
          })
        })
    },
    getCurrentUserInfo() {
      this.getRequest('/user/info').then((resp) => {
        if (resp) {
          this.$store.commit('setOperate', resp.readonly)
          this.user.username = resp.username
          this.user.realname = resp.realname
        }
      })
    },
  },
  created() {
    this.getCurrentUserInfo()
    this.activeIndex = this.$router.currentRoute.path
    LocalConfig.loadOrganizationsFromConfig(true)
    LocalConfig.loadDistrictsFromConfig(true)
    LocalConfig.loadRecordStatusFromConfig(true)
    LocalConfig.loadProduceDepartmentsFromConfig(true)
  },
  computed: {
    menus() {
      return this.$store.state.routers
    },
  },
  mounted() {},
}
</script>

<style>
#homeHeader {
  background: #2659c4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  color: #fff;
}
#homeHeader a {
  text-decoration: none;
}
#homeHeader .title {
  display: flex;
  font-size: 24px;
  color: white;
  font-weight: 700;
  padding-left: 10px;
}
#homeHeader .router-link-active .router-link-exact-active {
  text-decoration: none;
}
#homeHeader .el-menu.el-menu--horizontal {
  border: 0px;
}
#homeHeader .el-menu--horizontal > .el-menu-item {
  height: 50px;
}
#homeHeader .el-menu-item {
  padding: 0 15px;
  margin-bottom: 10px;
  border-bottom: 0px;
}
#homeHeader .el-menu-item.is-active {
  /* margin-bottom: 10px;
  border-bottom: 0px; */
  font-weight: bold;
}
#homeHeader .el-menu-item.is-active span {
  border-bottom: 2px solid white;
  padding-bottom: 7px;
}
#homeHeader .el-menu-item:not(.is-disabled):hover {
  background-color: #2659c4 !important;
}
#homeHeader .el-menu-item i {
  color: #fff;
}
#homeHeader .el-menu-item [class^='fa '] {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  /* font-size: 16px; */
  vertical-align: middle;
}
/* 防止回到页面时自动添加style生效 */
.el-menu-item.is-active {
  background-color: #2659c4 !important;
}
.hello {
  /* color: white; */
  font-size: 12px;
}
</style>
