<template>
  <el-form
    :class="dividerTextBgClass"
    :model="editForm"
    size="mini"
    :label-width="formLabelWidth"
    :disabled="disabled"
    :rules="rules"
    ref="editForm"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item label="使用单位名称" prop="unitId">
          <el-select
            v-model="editForm.unitId"
            filterable
            placeholder="请选择"
            style="width: 100%"
            @change="(unitId) => handleUseUnitChange(unitId)"
            @visible-change="updateCompanies"
          >
            <el-option v-for="item in companies" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="单位地址" prop="address">
          <el-input
            v-model="editForm.address"
            autocomplete="off"
            style="width: 100%"
            @change="editForm.useUnitChanged = true"
          />
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="使用项目名称">
          <el-select
            allow-create
            v-model="editForm.projectId"
            clearable
            filterable
            placeholder="请选择或输入"
            style="width: 100%"
            @change="(projectId) => handleProjectChange(projectId)"
            @visible-change="updateProjects"
          >
            <el-option v-for="item in projects" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1">
        <div style="float: right">
          <el-button type="danger" @click="deleteIt()" :disabled="disabled" icon="el-icon-delete" circle></el-button>
        </div>
      </el-col>
      <!--       
      <el-col :span="8">
        <el-form-item label="使用单位负责人" prop="head">
          <el-input
            v-model="editForm.head"
            autocomplete="off"
            style="width: 100%"
            @change="editForm.useUnitChanged = true"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="联系方式" prop="telephone">
          <el-input
            v-model="editForm.telephone"
            autocomplete="off"
            style="width: 100%"
            @change="editForm.useUnitChanged = true"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="editForm.email"
            autocomplete="off"
            style="width: 100%"
            @change="editForm.useUnitChanged = true"
          />
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="使用项目负责人" prop="usingProjectRp">
          <el-input
            v-model="editForm.usingProjectRp"
            autocomplete="off"
            style="width: 100%"
            @change="editForm.projectChanged = true"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="联系方式" @change="projectChanged = true" prop="usingProjectRpTel">
          <el-input
            v-model="editForm.usingProjectRpTel"
            autocomplete="off"
            style="width: 100%"
            @change="editForm.projectChanged = true"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="邮箱" prop="usingProjectRpMail">
          <el-input
            v-model="editForm.usingProjectRpMail"
            autocomplete="off"
            style="width: 100%"
            @change="editForm.projectChanged = true"
          />
        </el-form-item>
      </el-col> 
-->
    </el-row>
    <distribute-form
      :feedbackId="editForm.id"
      :serviceId="serviceId"
      :recordNum="recordNum"
      :stage="stage"
      :affiliatedUnit="editForm.unitId"
      :showUpdateButtons="false"
      :dividerTextBgClass="dividerTextBgClass"
      ref="distributeForm"
      v-if="editForm.id && editForm.unitId"
    ></distribute-form>
    <el-divider content-position="left">反馈信息</el-divider>
    <el-row>
      <el-col :span="8"
        ><el-form-item label="反馈编号" prop="num">
          <el-input v-model="editForm.num" autocomplete="off" />
        </el-form-item>
      </el-col>
      <el-col :span="8"
        ><el-form-item label="反馈时间">
          <el-date-picker
            v-model="editForm.date"
            type="date"
            placeholder="请选择时间"
            style="width: 100%"
            format="yyyy年M月d日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @focus="prepareDate"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8"
        ><el-form-item label="使用方式" prop="use">
          <el-input v-model="editForm.use" autocomplete="off" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24"
        ><el-form-item label="数据使用说明" prop="describes">
          <el-input v-model="editForm.describes" type="textarea" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <span class="dialog-footer span-center" v-if="!disabled">
        <div style="position: absolute">
          <el-form-item label="分发状态">
            <el-checkbox v-model="editForm.distributed" style="font-size: 12px" :disabled="disabled"
              >当前单位已完成分发
            </el-checkbox>
          </el-form-item>
        </div>
        <el-button type="primary" @click="submitForm()" :loading="this.saving">{{
          editForm.id ? '更新' : '保存'
        }}</el-button>
        <!-- <el-button type="danger" @click="deleteIt()">{{ editForm.id ? '删除' : '取消' }}</el-button> -->
        <el-button @click="notifyCloseDialog"> 返回 </el-button>
      </span>
    </el-row>
    <div v-if="editForm.id">
      <el-divider content-position="left"> 文件材料 </el-divider>
      <el-row>
        <el-form-item label="">
          <el-table :data="documents" stripe style="width: 100%" border>
            <el-table-column prop="number" label="序号" align="center" width="60px">
              <template slot-scope="scope">
                <span>{{ '300' + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="分类" header-align="center" show-overflow-tooltip width="150px" />
            <el-table-column label="文件名称" header-align="center" show-overflow-tooltip>
              <template #default="scope">
                <upload-item
                  :referenceId="editForm.id"
                  tab="DataForm"
                  :type="scope.row.type"
                  :disabled="disabled"
                  :ref="'upload' + scope.$index"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px" v-if="!disabled" align="center">
              <template #default="scope">
                <el-button type="text" @click="$refs['upload' + scope.$index].save()"> 上传 </el-button>
                <!-- <el-button type="text" @click="viewDocument(scope.$index)"> 预览 </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import { Loading } from 'element-ui'
import moment from 'moment'
import UploadItem from '@/components/business/UploadItem.vue'
import DistributeForm from '@/components/business/DistributeForm.vue'
import StatusPane from '@/components/business/StatusPane.vue'
export default {
  name: 'Feedback',
  components: { UploadItem, DistributeForm, StatusPane },
  props: {
    model: {},
    serviceId: String,
    recordNum: {
      type: String,
      default: '',
    },
    stage: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    companies: [],
    projects: [],
    dividerTextBgClass: {
      type: String,
      default: 'f1f2f6',
    },
  },
  data() {
    return {
      saving: false,
      editForm: {},
      formLabelWidth: '100px',
      pickerOptions: {
        disabledDate: (time) => {
          let produceEnd = this.produceEnd
          if (produceEnd) {
            return (
              time.getTime() < new Date(produceEnd).getTime() ||
              time.getTime() > new Date(moment().format('YYYY-MM-DD')).getTime()
            )
          }
        },
      },
      produceEnd: null,
      rules: {
        unitId: [{ required: true, message: '请选择使用单位', trigger: 'blur' }],
        address: [{ max: 100, message: '地址最大长度100个字符', trigger: 'blur' }],
        // head: [{ max: 30, message: '负责人最大长度30个字符', trigger: 'blur' }],
        // usingProjectRp: [{ max: 30, message: '项目负责人最大长度30个字符', trigger: 'blur' }],
        // telephone: [{ max: 40, message: '联系方式最大长度40个字符', trigger: 'blur' }],
        // usingProjectRpTel: [{ max: 40, message: '联系方式最大长度40个字符', trigger: 'blur' }],
        // usingProjectRpMail: [
        //   { max: 20, message: '邮箱最大长度20个字符', trigger: 'blur' },
        //   {
        //     validator: this.emailValidator,
        //     trigger: 'blur',
        //   },
        // ],
        // email: [
        //   { max: 20, message: '邮箱最大长度20个字符', trigger: 'blur' },
        //   {
        //     validator: this.emailValidator,
        //     trigger: 'blur',
        //   },
        // ],
        num: [{ max: 20, message: '分发编号最大长度20个字符', trigger: 'blur' }],
        use: [{ max: 40, message: '使用方式最大长度40个字符', trigger: 'blur' }],
        // telephone: [
        //   {
        //     validator: this.phoneOrTelValidator,
        //     trigger: 'blur',
        //   },
        // ],
      },
      documents: [
        { type: '数据分发单' },
        { type: '便函/介绍信' },
        { type: '身份证复印件' },
        { type: '反馈材料' },
        { type: '其他材料' },
      ],
    }
  },
  methods: {
    emailValidator(rule, value, callback) {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      setTimeout(() => {
        if (!value || mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    },
    phoneOrTelValidator(rule, value, callback) {
      const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/
      if (!value) {
        callback()
      } else {
        if (!reg.test(value) && value != '') {
          callback(new Error('请输入正确的手机或固话号码'))
        } else {
          callback()
        }
      }
    },
    submitForm() {
      let editForm = this.editForm
      let dValid = true
      if (editForm.id) {
        dValid = this.$refs['distributeForm'].validate()
      }
      this.$refs['editForm'].validate((valid) => {
        if (dValid && valid) {
          if (editForm.id) {
            dValid = this.$refs['distributeForm'].save()
            // setTimeout(() => {
            //   this.updateDataSize()
            // }, 1000)
          }
          this.save()
        } else {
          //console.log('error submit!!')
          return false
        }
      })
    },
    prepareDate() {
      setTimeout(() => {
        if (this.datePickerLoadingInstance) {
          this.datePickerLoadingInstance.close()
        }
        this.datePickerLoadingInstance = Loading.service({ target: '.el-date-picker[style*="position"]' })
        this.getProduceEndTime().then((result) => {
          this.datePickerLoadingInstance.close()
        })
      }, 200)
    },
    async getProduceEndTime() {
      if (!this.serviceId) {
        return
      }
      let result = null
      await this.getRequest('/server/produce/produceEnd/' + this.serviceId)
        .then((res) => {
          result = res.obj ? res.obj : null
        })
        .catch((err) => {
          console.log(err)
        })
      this.produceEnd = result
      return result
    },
    updateDataSize() {
      this.getRequest('/server/feedback/datasize/' + this.editForm.id)
        .then((res) => {
          this.editForm.dataSizeKb = res.obj
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateCompanies(callback) {
      this.$emit('notifyUpdateCompanies')
      //调用handle可以及时更新，但对于当前id已经填写但未保存的单位信息被覆盖
      //handleUseUnitChange(this.editForm.unitId)
    },
    updateProjects(callback) {
      this.$emit('notifyUpdateProjects')
      //handleProjectChange(this.editForm.projectId)
    },
    handleUseUnitChange(id) {
      let editForm = this.editForm
      if (id) {
        let useUnit = this.companies.filter((item) => {
          return item.id == id
        })[0]
        if (useUnit) {
          this.$set(editForm, 'address', useUnit.address)
        //   this.$set(editForm, 'head', useUnit.head)
        //   this.$set(editForm, 'telephone', useUnit.telephone)
        //   this.$set(editForm, 'email', useUnit.email)
          editForm.useUnitChanged = false
        }
      } else {
        this.$set(editForm, 'address', '')
        // this.$set(editForm, 'head', '')
        // this.$set(editForm, 'telephone', '')
        // this.$set(editForm, 'email', '')
        editForm.useUnitChanged = false
      }
    },
    handleProjectChange(id) {
      let editForm = this.editForm
      if (!id) {
        //clear
        // this.$set(editForm, 'usingProjectRp', '')
        // this.$set(editForm, 'usingProjectRpTel', '')
        // this.$set(editForm, 'usingProjectRpMail', '')
        editForm.projectChanged = false
        editForm.projectCreated = false
      } else {
        let project = this.projects.filter((item) => {
          return item.id === id
        })[0]
        if (!project) {
          //create
          editForm.projectCreated = true
          editForm.projectChanged = false
        } else {
          //select
        //   let arr = project.value.split('/')
        //   this.$set(editForm, 'usingProjectRp', arr[0] ? arr[0] : '')
        //   this.$set(editForm, 'usingProjectRpTel', arr[1] ? arr[1] : '')
        //   this.$set(editForm, 'usingProjectRpMail', arr[2] ? arr[2] : '')

          editForm.projectChanged = false
          editForm.projectCreated = false
        }
      }
    },
    deleteIt() {
      let editForm = this.editForm
      this.$confirm('此操作将删除用户反馈' + (editForm.num ? editForm.num : '') + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (editForm.id) {
            let requestData = JSON.parse(JSON.stringify(editForm))
            this.deleteRequest('/server/feedback/', requestData).then((res) => {
              if (res) {
                this.$emit('notifyDeleted', editForm.id)
              }
            })
          } else {
            this.$emit('notifyDeleted')
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    async save() {
      let editForm = this.editForm
      this.saving = true
      setTimeout(() => {
        if (this.saving == true) {
          this.saving = false
        }
      }, 1000 * 60)
      if (editForm.useUnitChanged) {
        this.updateUseUnitAddress()
      }
      if (editForm.projectChanged || editForm.projectCreated) {
        this.saveOrUpdateProject().then((result) => {
          this.saveItself()
        })
      } else {
        this.saveItself()
      }
    },
    saveItself() {
      let editForm = this.editForm
      let requestData = JSON.parse(JSON.stringify(editForm))
      let callback = () => {}
      let request = editForm.id ? this.putRequest : this.postRequest
      request('/server/feedback/', requestData)
        .then((res) => {
          this.saving = false
          if (res && res.code === 200) {
            callback()
            this.companies.some((unit, index) => {
              if (unit.id === editForm.unitId) {
                editForm.unitName = unit.name
                return true
              }
            })
            if (!editForm.id) {
              //应对不刷新的要求
              editForm.id = res.obj
              this.$emit('notifyCreated', editForm)
            }
          }
        })
        .catch((err) => {
          this.saving = false
          console.log(err)
        })
    },
    updateUseUnitAddress() {
      let editForm = this.editForm
      this.putRequest('/server/institution/', {
        id: editForm.unitId,
        address: editForm.address,
        // head: editForm.head,
        // telephone: editForm.telephone,
        // email: editForm.email,
      })
        .then((res) => {
          if (res.code == 200) {
            editForm.useUnitChanged = false
            this.$emit('updateCompanies', true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async saveOrUpdateProject() {
      let editForm = this.editForm
      let request = this.postRequest
      let project = this.projects.filter((item) => {
        return item.id === editForm.projectId
      })[0]
      if (project) {
        request = this.putRequest
        // project.value =
        //   (editForm.usingProjectRp ? editForm.usingProjectRp : '') +
        //   '/' +
        //   (editForm.usingProjectRpTel ? editForm.usingProjectRpTel : '') +
        //   '/' +
        //   (editForm.usingProjectRpMail ? editForm.usingProjectRpMail : '')
      } else {
        let name = editForm.projectId
        project = {
          name: name,
        //   value:
        //     (editForm.usingProjectRp ? editForm.usingProjectRp : '') +
        //     '/' +
        //     (editForm.usingProjectRpTel ? editForm.usingProjectRpTel : '') +
        //     '/' +
        //     (editForm.usingProjectRpMail ? editForm.usingProjectRpMail : ''),
          group: '项目',
        }
      }

      let result = null
      await request('/server/dictionary/', project)
        .then((res) => {
          if (res.code == 200) {
            if (!project.id) {
              editForm.projectId = res.obj
            }
            editForm.projectChanged = false
            editForm.projectCreated = false
            this.$emit('notifyUpdateProjects')
          }
        })
        .catch((err) => {
          console.log(err)
        })
      return result
    },
    notifyCloseDialog() {
      this.$emit('notifyCloseDialog')
    },
    refreshDistributes() {
      this.$refs['distributeForm'].refresh()
    },
  },
  created() {
    if (this.model) {
      this.editForm = this.model
    }
  },
  mounted() {},
  updated() {},
  watch: {
    model: function (val, oldVal) {
      if (val) {
        this.editForm = this.model
      }
    },
  },
}
</script>
<style scoped>
.span-center {
  width: 100%;
  text-align: center;
  display: block;
}
</style>
<style>
/* #editDiv .el-divider__text {
  background-color: #fadfc7;
}
.recordDiv .el-divider__text {
  background-color: #f4f4f4;
} */
.f1f2f6 .el-divider__text {
  background-color: #f4f4f4;
}
.fadfc7 .el-divider__text {
  background-color: #fadfc7;
}
</style>
