<template>
  <div id="institution">
    <div style="display: flex; justify-content: space-between">
      <div>
        <el-input class="addInstitutionInput" size="mini" placeholder="机构名称" v-model="org.name"> > </el-input>
        <el-button size="mini" icon="el-icon-search" type="primary" @click="query">查询</el-button>
      </div>
      <div>
        <el-button size="mini" type="success" @click="downloadTemplate"
          ><i class="fa fa-angle-double-down" aria-hidden="true"></i>导出模板
        </el-button>
        <el-button size="mini" type="success" @click="dialogReadFileVisible = true">
          <i class="fa fa-angle-double-up" aria-hidden="true"></i>导入数据
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-tree
        :data="treeData.children"
        node-key="id"
        :props="defaultProps"
        :expand-on-click-node="true"
        @node-click="handleNodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button type="text" size="mini" @click="() => append(data)"> 添加 </el-button>
            <el-button type="text" size="mini" @click="() => edit(data)"> 编辑 </el-button>
            <el-button type="text" size="mini" @click="() => remove(data)"> 删除 </el-button>
          </span>
        </span>
      </el-tree>
    </div>

    <!--查询结果对话框-->
    <el-dialog title="查询结果" :visible.sync="dialogSearchResultVisible">
      <div id="tableArea">
        <el-table :data="tableData" stripe style="width: 100%" v-loading="loading" border>
          <el-table-column prop="xh" type="index" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称" show-overflow-tooltip />
          <el-table-column label="操作" min-width="60px" align="center">
            <template #default="scope">
              <el-button type="text" @click="append(scope.row)"> 添加</el-button>
              <el-button type="text" @click="edit(scope.row)"> 编辑</el-button>
              <el-button type="text" @click="remove(scope.row)"> 删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 200, 500]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!--添加单位对话框-->
    <el-dialog :title="addOrEdit" :visible.sync="dialogFormVisible">
      <el-form :model="institution">
        <el-form-item label="单位名称" :label-width="formLabelWidth">
          <el-input v-model="institution.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单位简称" :label-width="formLabelWidth">
          <el-input v-model="institution.alias" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否废止" :label-width="formLabelWidth">
          <el-switch v-model="institution.obsolete"></el-switch>
        </el-form-item>
        <el-form-item label="曾用名" :label-width="formLabelWidth">
          <el-select v-model="institution.historyId" placeholder="无">
            <el-option
              v-for="(option, index) in historyOptions"
              :label="option.name"
              :value="option.id"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="单位性质" :label-width="formLabelWidth">
          <el-select v-model="institution.orgProperty" placeholder="请选择单位性质">
            <el-option label="司局" value="司局"></el-option>
            <el-option label="直属单位" value="直属单位"></el-option>
            <el-option label="派出机构" value="派出机构"></el-option>
            <el-option label="林草局下属单位" value="林草局下属单位"></el-option>
            <el-option label="地调局机关部门" value="地调局机关部门"></el-option>
            <el-option label="地调局下属单位" value="地调局下属单位"></el-option>
            <el-option label="部委" value="部委"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="单位分类" :label-width="formLabelWidth">
          <el-select v-model="institution.orgClass" placeholder="请选择单位分类">
            <el-option v-for="item in orgClasses" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="服务分类" :label-width="formLabelWidth">
          <el-select v-model="institution.serviceClass" placeholder="请选择服务分类">
            <el-option label="外部服务" value="外部服务"></el-option>
            <el-option label="内部服务" value="内部服务"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="单位地址" :label-width="formLabelWidth">
          <el-input v-model="institution.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="institution.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer span-center">
        <el-button type="primary" @click="addUnit()">确定</el-button>
        <el-button @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <!--    数据导入对话框-->
    <el-dialog title="导入数据" :visible.sync="dialogReadFileVisible" width="45%">
      <input type="file" @change="readExcleFile" />
      <div style="width: 100%; height: 380px; overflow-y: auto">
        <el-table :data="readFileData" style="width: 100%">
          <el-table-column prop="org" label="机构"></el-table-column>
          <el-table-column prop="org2" label="二级机构"></el-table-column>
          <el-table-column prop="name" label="单位名称"></el-table-column>
          <el-table-column prop="alias" label="简称"></el-table-column>
          <el-table-column prop="unitClass" label="单位性质"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer span-center">
        <el-button @click="dialogReadFileVisible = false">取消</el-button>
        <el-button type="primary" @click="saveDataBatch">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import * as LocalConfig from '@/utils/base'
export default {
  name: 'Institution',
  data() {
    return {
      treeClickCount: 0,
      tableData: [],
      loading: true,
      dialogSearchResultVisible: false,
      page: 1,
      pageSize: 10,
      nxtPage: 1,
      total: 1,

      dialogFormVisible: false,
      dialogReadFileVisible: false,
      formLabelWidth: '90px',
      activeNames: ['1'],
      readFileData: [],
      org: {
        id: '',
        name: '',
        obsolete: false,
        orgId: '',
      },
      institution: {
        id: '',
        name: '',
        alias: '',
        orgId: '',
        orgProperty: '',
        address: '',
        orgClass: '',
        serviceClass: '',
        remark: '',
        obsolete: false,
        historyId: '',
      },
      addOrEdit: '添加',
      treeData: {}, //申请或来源单位的树形结构数据
      rootId: '',
      historyOptions: [], //是否是历史项目选择项
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      orgClasses: [],
    }
  },
  methods: {
    handleNodeClick(data) {
      this.treeClickCount++
      if (this.treeClickCount > 2) return
      setTimeout(() => {
        if (this.treeClickCount == 1) {
        } else if (this.treeClickCount == 2) {
          //双击事件
          this.edit(data)
        }
        this.treeClickCount = 0
      }, 500)
    },
    handleCurrentChange(val) {
      this.page = val
      this.query()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.query()
    },
    saveDataBatch() {
      //保存机构数据
      let org = {}
      let org2 = {}
      let rows = []
      this.readFileData.forEach((row) => {
        if (row.org != null && row.org != '') {
          org[row.org] = row.org
        }
        if (row.org2 != null && row.org2 != '') {
          org2[row.org2] = { name: row.org2, org: row.org }
        }
      })
      //格式化一级机构
      for (var key in org) {
        let o = JSON.parse(JSON.stringify(this.institution))
        o.name = key
        rows.push(o) //一级机构
      }
      //格式化二级机构
      for (var key in org2) {
        let o = JSON.parse(JSON.stringify(this.institution))
        o.name = org2[key].name
        o.orgId = org2[key].org
        rows.push(o)
      }
      //格式化单位
      this.readFileData.forEach((row) => {
        let o = JSON.parse(JSON.stringify(this.institution))
        o.name = row.name
        o.alias = row.alias
        o.orgProperty = row.orgProperty
        o.orgClass = row.unitClass
        if (row.org2 != null && row.org2 != '') {
          o.orgId = row.org2
        } else {
          o.orgId = row.org
        }
        rows.push(o)
      })
      //向数据库推送数据
      this.postRequest('/server/institution/inputBatch', rows).then((resp) => {
        if (resp) {
          this.loadData()
          this.dialogReadFileVisible = false
        }
      })
    }, //批量保存数据
    downloadTemplate() {
      window.location.href = '/template/department.xlsx'
    },
    readExcleFile(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null
      readXlsxFile(xlsxfile).then((rows) => {
        this.readFileData = [] //重置数据
        let rowHead = rows[0] //第一行是头
        try {
          for (let i = 1; i < rows.length; i++) {
            let row = {}
            rowHead.forEach((head) => {
              let colIndex = rowHead.findIndex((colName) => {
                return head === colName
              })
              switch (head) {
                case '机构':
                  row.org = rows[i][colIndex]
                  break
                case '二级机构':
                  row.org2 = rows[i][colIndex]
                  break
                case '单位名称':
                  row.name = rows[i][colIndex]
                  break
                case '单位简称':
                  row.alias = rows[i][colIndex]
                  break
                case '单位性质':
                  row.orgProperty = rows[i][colIndex]
                  break
                case '单位分类':
                  row.unitClass = rows[i][colIndex]
                  break
              }
            })
            this.readFileData.push(row)
          }
        } catch (err) {
          this.$message({
            type: 'error',
            message: '加载的数据模板不正确，模板应该包含：<' + rowHead.toString() + '>字段',
          })
        }
      })
    },
    handleChange(val) {
      //console.log(val)
    },
    append(data) {
      //新增节点
      //开启添加对话框
      //重置对象
      this.addOrEdit = '添加'
      //Object.assign(this.$data.institution, this.$options.data().institution)
      this.institution = { orgId: data.id }
      this.dialogFormVisible = true
    },
    edit(data) {
      //编辑节点
      this.addOrEdit = '编辑'
      //Object.assign(this.$data.institution, this.$options.data().institution)
      this.institution = data
      this.dialogFormVisible = true
    },
    remove(data) {
      //删除节点
      //删除单位
      this.$confirm('此操作将删除申请、来源单位<' + data.name + '>, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteRequest('/server/institution/' + data.id).then((resp) => {
            if (resp) {
            }
            this.loadData()
            if (this.dialogSearchResultVisible) {
              this.query()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    /*    addInstitution() {
      if (this.org.name == '') {
        this.$message({
          type: 'info',
          message: '机构名称不能为空',
        })
        return
      }
      this.org.orgId = this.rootId
      this.postRequest('/server/institution/', this.org).then((resp) => {
        if (resp) {
          this.loadData()
          this.org.name = ''
        }
      })
    },*/
    query() {
      this.loading = true
      this.dialogSearchResultVisible = true
      let requestData = {}
      requestData.currentPage = this.page
      requestData.pageSize = this.pageSize
      requestData.name = this.org ? this.org.name : null
      this.getRequest('/server/institution/byPage', requestData)
        .then((resp) => {
          if (resp) {
            this.tableData = resp.data
            this.total = resp.total
            this.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadData() {
      this.getRequest('/server/institution/').then((resp) => {
        if (resp) {
          this.treeData = resp
          this.rootId = resp.id
        }
      })
    },
    loadDataLazy(node, resolve) {
      //懒加载数据
      let requestUrl = ''
      if (node.level === 0) {
        requestUrl = '/server/region/000000'
      }
      if (requestUrl != '') {
        this.getRequest('/server/institution/').then((resp) => {
          if (resp) {
            this.treeData = resp
            this.rootId = resp.id
          }
        })
      }
    },
    deleteOrg() {
      //删除机构
    },
    addUnit() {
      //添加或者更新数据
      if (this.addOrEdit === '添加') {
        this.postRequest('/server/institution/', this.institution).then((resp) => {
          if (resp) {
            //Object.assign(this.$data.institution, this.$options.data().institution)
            this.dialogFormVisible = false
            this.loadData()
            if (this.dialogSearchResultVisible) {
              this.query()
            }
          }
        })
      } else {
        this.putRequest('/server/institution/', this.institution).then((resp) => {
          if (resp) {
            //Object.assign(this.$data.institution, this.$options.data().institution)
            this.dialogFormVisible = false
            this.loadData()
            if (this.dialogSearchResultVisible) {
              this.query()
            }
          }
        })
      }
    }, //添加或更新单位
    showUpdate(data) {
      //编辑单位
      this.addOrEdit = '编辑'
      this.institution = data
      this.dialogFormVisible = true
    },
    loadHistoryOptions() {
      this.getRequest('/server/institution/historyOption').then((resp) => {
        if (resp) {
          this.historyOptions = resp
          this.historyOptions.push({ id: '', name: '无' })
        }
      })
    },
  },
  created() {
    LocalConfig.loadOrgClassesFromConfig().then((result) => {
      this.orgClasses = result
    })
    this.loadData()
    this.loadHistoryOptions()
  },
}
</script>

<style scoped>
.addInstitutionInput {
  width: 300px;
  margin-right: 10px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
</style>
<style>
#institution .el-tree-node__content {
  line-height: 34px;
  height: 34px;
}
</style>
