import { render, staticRenderFns } from "./Region.vue?vue&type=template&id=1ef2601d&scoped=true&"
import script from "./Region.vue?vue&type=script&lang=js&"
export * from "./Region.vue?vue&type=script&lang=js&"
import style0 from "./Region.vue?vue&type=style&index=0&id=1ef2601d&scoped=true&lang=css&"
import style1 from "./Region.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef2601d",
  null
  
)

export default component.exports